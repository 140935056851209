import styled from "styled-components";

export const ActionsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  background: #f8f8f8;
  border: 1px dashed ${({ theme }) => theme.palette.secondaryNeutralDark};
  border-radius: 4px;
`;

export const WhiteBox = styled.div`
  background: ${({ theme }) => theme.palette.primaryBasic};
  padding: 10px;
`;
