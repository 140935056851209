import styled, { css, keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 31, 32, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 110;
  ${css`
    body:has(&) {
      overflow: hidden;
    }
  `}
`;

const spinnerAnimation = keyframes`
  to {
    transform: rotate(1turn)
  }
`;

export const Spinner = styled.div`
  width: 24px;
  --border: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, ${({ theme }) => theme.palette.primaryBasic}) content-box;
  -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--border) - 1px),
      #000 calc(100% - var(--border))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: ${spinnerAnimation} 1s infinite steps(10);
`;
