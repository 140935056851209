import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  palette: {
    primaryMain: "#95268D",
    primaryNeutral: "#D1D3D4",
    primaryDefault: "#231F20",
    primaryBasic: "#FFFFFF",
    secondaryMainDark: "#670360",
    secondaryNeutralDark: "#77797B",
    secondaryActive: "#51014B",
    thirdMainLight: "#FDF7FF",
    thirdNeutralLight: "#F4F4F5",
    thirdBasicLight: "#F4F4F5",
    thirdErrorLight: "#FFF1F4",
    thirdSuccessLight: "#EEFCF7",
    thirdWarningLight: "#FFF7E3",
    thirdWarning2Light: "#F5F6FF",
    accentError: "#C21333",
    accentSuccess: "#26956D",
    accentWarning: "#FAC746",
    accentWarning2: "#1E2BA6",
    gradientError: "linear-gradient(283.13deg, #E52D27 5.47%, #C21333 90.83%)",
    gradientSuccess: `linear-gradient(121.23deg, #0BA360 2.96%, #3CBA92 97.05%)`,
    gradientWarning: `linear-gradient(322.92deg, #FFD759 1.23%, #FF8F6F 85.98%)`,
    gradientButton: `linear-gradient(180deg, #BE30B4 0%, #85217E 100%)`,
    gray: "rgba(35, 31, 32, 1)",
    black: "#000000"
  },
  textStyles: {
    body10: {
      "font-family": "SofiaPro Regular",
      "font-size": "10px",
      "line-height": "12px"
    },
    body12: {
      "font-family": "SofiaPro Regular",
      "font-size": "12px",
      "line-height": "16px"
    },
    body12Light: {
      "font-family": "SofiaPro Light",
      "font-size": "12px",
      "font-weight": "lighter",
      "line-height": "16px"
    },
    body14: {
      "font-family": "SofiaPro Regular",
      "font-size": "14px",
      "line-height": "21px"
    },
    body14Light: {
      "font-family": "SofiaPro Light",
      "font-size": "14px",
      "line-height": "18px"
    },
    body14Bold: {
      "font-family": "SofiaPro Bold",
      "font-size": "14px",
      "line-height": "18px"
    },
    body16: {
      "font-family": "SofiaPro Regular",
      "font-size": "16px",
      "line-height": "20px"
    },
    body16Light: {
      "font-family": "SofiaPro Light",
      "font-size": "16px",
      "line-height": "24px"
    },
    body16Bold: {
      "font-family": "SofiaPro Bold",
      "font-size": "16px",
      "line-height": "24px"
    },
    body20: {
      "font-family": "SofiaPro Regular",
      "font-size": "20px",
      "line-height": "24px"
    },
    body24: {
      "font-family": "SofiaPro Regular",
      "font-size": "24px",
      "line-height": "32px"
    },
    badge: {
      "font-family": "SofiaPro Regular",
      "font-size": "24px",
      "line-height": "24px"
    },
    Accent: {
      "font-family": "SofiaPro Bold",
      "font-size": "32px",
      "line-height": "40px"
    },
    modalHeader: {
      "font-family": "SofiaPro Regular",
      "font-size": "24px",
      "line-height": "40px"
    },
    H1: {
      "font-family": "SofiaPro Regular",
      "font-size": "28px",
      "line-height": "36px"
    },
    H2: {
      "font-family": "SofiaPro Regular",
      "font-size": "24px",
      "line-height": "32px"
    },
    H3: {
      "font-family": "SofiaPro Regular",
      "font-size": "20px",
      "line-height": "24px"
    },
    H4: {
      "font-family": "SofiaPro Regular",
      "font-size": "18px",
      "line-height": "20px"
    }
  },
  sizes: {
    icon: {
      default: "10px"
    },
    checkbox: {
      normal: "18px",
      emphasised: "24px"
    },
    button: {
      small: "257px",
      medium: "356px",
      large: "540px",
      fitContent: "fit-content",
      maxWidth: "100%"
    },
    badge: {
      default: "32px"
    }
  }
};
