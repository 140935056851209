import { FC } from "react";
import { StyledText } from "./Typography.styles";
import { TypographyProps, TYPOGRAPHY_COMPONENTS } from "./Typography.types";

export const Typography: FC<TypographyProps> = ({
  children,
  component = TYPOGRAPHY_COMPONENTS.span,
  name,
  isCapitalize = false,
  isUnderline = false,
  color,
  ...props
}) => (
  <StyledText
    as={component}
    typographyName={name}
    isCapitalize={isCapitalize}
    isUnderline={isUnderline}
    color={color}
    {...props}>
    {children}
  </StyledText>
);
