import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { Box } from "../Box";
import { ExclamatoryIcon } from "../../assets/icons";
import { EventColor } from "../../types/EventColor";
import { IHintProps } from "./Hint.types";

export const Hint = ({ type, text }: IHintProps) => {
  return (
    <Box display="flex" alignItems="center" gap={ 4 }>
      { type && <ExclamatoryIcon type={ type } /> }
      { text && (
        <Typography
          color={ type ? EventColor[type] : "secondaryNeutralDark" }
          name={ TYPOGRAPHY_NAMES.body12 }>
          { text }
        </Typography>
      ) }
    </Box>
  );
};
