import { Box } from "../Box";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { StyledBadge } from "./Badge.styles";
import { IBadgeProps } from "./Badge.types";

export const Badge = ({ size = "32px", text, children }: IBadgeProps) => {
  return (
    <Box display="flex" alignItems="center" gap={16}>
      <StyledBadge
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={size}
        height={size}>
        <Typography name={TYPOGRAPHY_NAMES.badge}>{text}</Typography>
      </StyledBadge>
      <Typography name={TYPOGRAPHY_NAMES.H2}>{children}</Typography>
    </Box>
  );
};
