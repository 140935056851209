import styled from "styled-components";
import { Box } from "../Box";
import { Typography } from "../Typography";

export const Subscription = styled(Box)`
  background-color: ${({ theme }) => theme.palette.thirdBasicLight};
  border-radius: 7px;
`;

export const Discount = styled(Typography)`
  color: ${({ theme }) => theme.palette.primaryMain};
`;
