import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  // сброс стилей
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  input,
  textarea,
  p,
  blockquote,
  th,
  td,
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'SofiaPro Regular', sans-serif;
  }

  @font-face {
    font-family: 'SofiaPro Regular';
    src: url("/SofiaPro-Regular.woff2") format("woff2");
    font-display: swap;
  }

  @font-face {
    font-family: 'SofiaPro Bold';
    src: url("/SofiaPro-Bold.woff2") format("woff2");
    font-display: swap
  }

  @font-face {
    font-family: 'SofiaPro Light';
    src: url("/SofiaPro-Light.woff2") format("woff2");
    font-display: swap
  }

  @font-face {
    font-family: 'SofiaPro Medium';
    src: url("/SofiaPro-Medium.woff2") format("woff2");
    font-display: swap
  }

`;
