export const CloseIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3337 2.54675L17.4537 0.666748L10.0003 8.12008L2.54699 0.666748L0.666992 2.54675L8.12033 10.0001L0.666992 17.4534L2.54699 19.3334L10.0003 11.8801L17.4537 19.3334L19.3337 17.4534L11.8803 10.0001L19.3337 2.54675Z"
      fill="#D1D3D4"
    />
  </svg>
);
