import { useTranslation } from "react-i18next";
import { MainLogoEn, MainLogoFr } from "../../assets/icons";
import { LANG_EN, LANG_FR } from "../../constants";

export const MainLogo = () => {
  const { i18n } = useTranslation();

  const lang = i18n.language === LANG_FR.toLowerCase() ? LANG_FR : LANG_EN;

  switch (lang) {
    case LANG_FR:
      return <MainLogoFr />;
    default:
      return <MainLogoEn />;
  }
};
