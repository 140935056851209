import { InstallationCardProps } from "./InstallationCard.types";
import { Card, Counter, FailureMessage, Icon } from "./InstallationCard.styles";
import { useTranslation } from "react-i18next";
import { Box, Hint, PlatformLogo, ProgressLine, Typography, TYPOGRAPHY_NAMES } from "../../components";
import { useMemo } from "react";

export const InstallationCard = (
  {
    name,
    width,
    total,
    complete,
    failure
  }: InstallationCardProps
) => {
  const { t } = useTranslation();

  const percentage = useMemo(() => {
    if (total) return complete / total * 100;
    return 0;
  }, [complete, total]);
  return (
    <Card width={ width ?? "100%" } failure={ Boolean(failure) }>
      <Icon width={ 70 } height={ 70 }><PlatformLogo name={ name } width="35" /></Icon>
      <Box display={ "flex" } flexDirection={ "column" } width={ "90%" } gap={ 4 }>
        <Typography name={ TYPOGRAPHY_NAMES.H2 }>{ name }</Typography>
        <Typography name={ TYPOGRAPHY_NAMES.body12 } color={ "secondaryNeutralDark" }>
          { Boolean(failure) && t("prod_installoverview_Incomplete_lbl") }
          { !failure && t("prod_installoverview_Progress_lbl") }
        </Typography>
        <ProgressLine value={ percentage } color={ failure ? "accentError" : "primaryMain" } />
        <FailureMessage failure={ Boolean(failure) }>
          <Hint type={ "error" } />
          <Typography name={ TYPOGRAPHY_NAMES.body12 }>
            { failure } { t("prod_installoverview_install_fail_error") }
          </Typography>
        </FailureMessage>
      </Box>
      <Counter>
        <Typography name={ TYPOGRAPHY_NAMES.H2 }>{ complete } / { total }</Typography>
        <Typography name={ TYPOGRAPHY_NAMES.body14 }>
          { t("prod_installoverview_devices_lbl") }
        </Typography>
      </Counter>
    </Card>
  );
};
