import { css } from "styled-components";
import okIcon from "../../assets/icons/ok.svg";
import { IRadioStyleProps } from "./RadioField.types";

const Circle = css`
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;
export const Radio = css<IRadioStyleProps>`
  & label {
    font-size: ${({ theme }) => theme.textStyles.body12Light};
    color: ${({ theme }) => theme.palette.secondaryNeutralDark};
  }

  & input[type="radio"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    ${Circle};
    border: 1px solid ${({ theme }) => theme.palette.secondaryNeutralDark};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: ${({ theme }) => theme.palette.primaryMain};
    }

    &::before {
      ${Circle};
      content: "";
      background: ${({ theme }) => theme.palette.primaryMain};
      transform: scale(0);
      transition: 0.3s transform;
    }

    &:disabled::before {
      background: ${({ theme }) => theme.palette.thirdNeutralLight};
      transform: scale(1);
    }

    &:disabled:hover {
      border-color: ${({ theme }) => theme.palette.secondaryNeutralDark};
    }

    &:active::before {
      transform: scale(1);
    }

    &:checked {
      border: none;
    }

    &:checked::before {
      transform: scale(1);
      background-image: url(${okIcon});
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;
