import { useTranslation } from "react-i18next";
import { CounterBox } from "./CounterCard.styles";
import { ICounterCardProps } from "./CounterCard.types";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";

export const CounterCard = ({ data: { stamp, value } }: ICounterCardProps) => {
  const { t } = useTranslation();
  return (
    <CounterBox>
      <Typography name={ TYPOGRAPHY_NAMES.Accent }>
        { value }
      </Typography>
      <Typography name={ TYPOGRAPHY_NAMES.body16 } color={ "secondaryNeutralDark" }>
        { t(stamp) }
      </Typography>
    </CounterBox>
  );
};
