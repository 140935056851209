import { useMutation } from "react-query";
import { addClientDetails } from "../requests/addClientDetails";

export const useAddClientDetails = () => {
  const { mutate, isLoading, isSuccess } = useMutation(addClientDetails);
  return {
    addClientDetails: mutate,
    isLoadingAddClientDetails: isLoading,
    isSuccessAddClientDetails: isSuccess
  };
};
