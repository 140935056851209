import { useQuery } from "react-query";
import { getSecurityInfo } from "../requests/getSecurityInfo";

export const useGetSecurityInfo = () => {
  const { data, refetch, isSuccess, isLoading } = useQuery("getSecurityInfo", getSecurityInfo, {
    refetchOnWindowFocus: true,
    retry: true,
  });
  return {
    securityInfo: {
      lastUpdated: data?.data.lastUpdated,
      globalSecurity: {
        title: 'Global',
        percentage: data?.data.GlobalSecureScore ?? 0,
        totalDevices: 0,
        installedDevices: 0,
      },
      devicesProtection: [
        {
          title: 'Windows',
          ...data?.data.scoreWindowsOS,
        },
        {
          title: 'MacOS',
          ...data?.data.scoreMacOS,
        },
        {
          title: 'Linux',
          ...data?.data.scoreLinuxOS,
        },
        {
          title: 'iOS',
          ...data?.data.scoreIOS,
        },
        {
          title: 'Android',
          ...data?.data.scoreAOS,
        },
      ],
      updateHistory: data?.data.updateHistory ?? [],
    },
    refetchSecurityInfo: refetch,
    isLoadingSecurityInfo: isLoading,
    isSuccessSecurityInfo: isSuccess
  };
};
