import { t } from "i18next";
import { ButtonArrowIcon, GladiatorIcon } from "../../assets";
import {
  Box,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Carousel,
  Loader,
  ProgressCard,
  Typography,
  TYPOGRAPHY_COMPONENTS,
  TYPOGRAPHY_NAMES,
  UpdatesRecord
} from "../../components";
import { Motivation, Page, Table, TableHeader, Updates } from "./HomePage.styles";
import { CARD, CARD_MESSAGE } from "../../components/ProgressCard/ProgressCard.types";
import { useGetInstallationDate, useGetPricingDetails, useGetSecurityInfo } from "../../api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const navigate = useNavigate();
  const { securityInfo, isLoadingSecurityInfo } = useGetSecurityInfo();
  const { price, updatePrice, isLoadingPrice } = useGetPricingDetails();
  const [activeRow, setActiveRow] = useState<number>();
  const {installationDate, isLoadingInstallationDate} = useGetInstallationDate();

  useEffect(() => {
    const distance = installationDate ? installationDate * 1000 - Date.now() : 0;
    if (distance > 1000) navigate("/countdown")
  }, [installationDate]);

  useEffect(() => {
    updatePrice({ PromoCode: "" });
  }, []);

  const unpaidOption = (name: string) => {
    return price?.Variant.map(({ Options }) => {
      return Options.filter(({ Name, Paid }) => Paid && Name === name).length > 0;
    }).some(option => !option)
  }

  const onBack = useCallback(() => navigate("/installation"), []);

  const onToppings = useCallback(() => navigate("/price"), []);

  const globalSecurity = useMemo(() => {
    const percentage = Math.floor(securityInfo.globalSecurity.percentage);
    if (percentage > 70)
      return { percentage, type: CARD.excellent, message: CARD_MESSAGE.excellent };
    else if (percentage >= 40)
      return { percentage, type: CARD.average, message: CARD_MESSAGE.average };
    else return { percentage, type: CARD.poor, message: CARD_MESSAGE.poor };
  }, [securityInfo.globalSecurity.percentage]);

  const dateShortener = (date?: number) => {
    if (date) {
      const fullDate = new Date(date * 1000);
      return fullDate.toISOString().slice(0, 10);
    }
    return "";
  };

  const globalDevicesCounter = useMemo(() => {
    const { devicesProtection } = securityInfo;
    if (devicesProtection.length > 0) {
      const total = devicesProtection.reduce((acc, device) => acc + (device.totalDevices ?? 0), 0);
      const installed = devicesProtection.reduce(
        (acc, device) => acc + (device.installedDevices ?? 0),
        0
      );
      return `${installed}/${total}`;
    }
  }, [securityInfo]);

  if (isLoadingSecurityInfo || isLoadingInstallationDate || isLoadingPrice) return <Loader />;

  return (
    <Page>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t("prod_installoverview_score_lbl")}</Typography>
        <Button buttonType={BUTTON_TYPE.secondary} size={BUTTON_SIZE.fitContent} onClick={onBack}>
          <Box mr={20} ml={20} display="flex" justifyContent="center" gap={13}>
            <ButtonArrowIcon />
            {t("prod_pricing_installation_lbl")}
          </Box>
        </Button>
      </Box>
      <Typography name={TYPOGRAPHY_NAMES.body16Light}>
        {t("prod_pricing_update_lbl", { updateDateTime: dateShortener(securityInfo.lastUpdated) })}
      </Typography>
      <Box mt={16} mb={16}>
        <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.body16Light}>
          {t("prod_pricing_underway_lbl")}
        </Typography>
        <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.body16Light}>
          {t("prod_pricing_phones_lbl")}
        </Typography>
      </Box>
      <Box display="flex" gap={20}>
        <ProgressCard
          isSecurity
          type={globalSecurity.type}
          title={t("prod_pricing_level_lbl")}
          text={t(globalSecurity.message)}
          percentage={globalSecurity.percentage}
          globalCounter={globalDevicesCounter}
        />
        <Box minWidth={320} mb={32}>
          <Carousel
            items={securityInfo.devicesProtection}
            renderItem={({ item }) => (
              <Box display="flex" gap={20}>
                <ProgressCard
                  key={item.title}
                  title={item.title}
                  text={`${item.installedDevices || 0}/${item.totalDevices || 0}`}
                  percentage={Math.floor(item.percentage || 0)}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
      {
        (unpaidOption("Updates") || unpaidOption("Vulnerabilities")) &&
        <Motivation>
          <GladiatorIcon />
          <Box display="flex" flexDirection="column">
            <Typography name={ TYPOGRAPHY_NAMES.H2 }>{ t("prod_pricing_Windows2_lbl") }</Typography>
            <Typography name={ TYPOGRAPHY_NAMES.body16 }>
              { t("prod_pricing_vulnerabilities_lbl") }
            </Typography>
          </Box>
          <Button onClick={ onToppings }>{ t("prod_pricing_it_lbl") }</Button>
        </Motivation>
      }
      {
        !unpaidOption("Updates") &&
        <Box display="flex" gap={ 40 }>
          <Updates>
            <Typography name={ TYPOGRAPHY_NAMES.body16Bold }>
              { t("prod_pricing_Windows_updates_lbl") }
            </Typography>
            <Table>
              <TableHeader>
                <Box>
                  <Typography name={ TYPOGRAPHY_NAMES.body12 }>{ t("prod_pricing_Date_lbl") }</Typography>
                </Box>
                <Box>
                  <Typography name={ TYPOGRAPHY_NAMES.body12 }>
                    { t("prod_pricing_Version_lbl") }
                  </Typography>
                </Box>
              </TableHeader>
              { securityInfo.updateHistory.map(({updated, description}, index) => (
                <UpdatesRecord
                  key={index}
                  id={index}
                  current={activeRow}
                  setCurrent={setActiveRow}
                  updated={dateShortener(updated)}
                  description={description}/>
              )) }
            </Table>
          </Updates>
          <Updates width="100%"> </Updates>
        </Box>
      }
    </Page>
  );
};
