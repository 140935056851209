import styled, { css } from "styled-components";
import { IStyledTextProps, TYPOGRAPHY_NAMES } from "./Typography.types";

export const renderTextStyles = (styles: { [key: string]: string }) => `
  font-family: ${styles["font-family"]};
  font-size: ${styles["font-size"]};
  line-height: ${styles["line-height"]};
  font-weight: ${styles["font-weight"] || "normal"};
`;

export const StyledText = styled.span`
  ${({ theme, typographyName, isCapitalize, isUnderline, isCrossed, color }: IStyledTextProps) => {
    const textStyles = (name: TYPOGRAPHY_NAMES) => `
      ${name === TYPOGRAPHY_NAMES.Accent ? renderTextStyles(theme.textStyles.Accent) : ""}
      ${name === TYPOGRAPHY_NAMES.H1 ? renderTextStyles(theme.textStyles.H1) : ""}
      ${name === TYPOGRAPHY_NAMES.H2 ? renderTextStyles(theme.textStyles.H2) : ""}
      ${name === TYPOGRAPHY_NAMES.H3 ? renderTextStyles(theme.textStyles.H3) : ""}
      ${name === TYPOGRAPHY_NAMES.H4 ? renderTextStyles(theme.textStyles.H4) : ""}
      ${name === TYPOGRAPHY_NAMES.body10 ? renderTextStyles(theme.textStyles.body10) : ""}
      ${name === TYPOGRAPHY_NAMES.body12 ? renderTextStyles(theme.textStyles.body12) : ""}
      ${name === TYPOGRAPHY_NAMES.body12Light ? renderTextStyles(theme.textStyles.body12Light) : ""}
      ${name === TYPOGRAPHY_NAMES.body14 ? renderTextStyles(theme.textStyles.body14) : ""}
      ${name === TYPOGRAPHY_NAMES.body14Light ? renderTextStyles(theme.textStyles.body14Light) : ""}
      ${name === TYPOGRAPHY_NAMES.body14Bold ? renderTextStyles(theme.textStyles.body14Bold) : ""}
      ${name === TYPOGRAPHY_NAMES.body16 ? renderTextStyles(theme.textStyles.body16) : ""}
      ${name === TYPOGRAPHY_NAMES.body16Light ? renderTextStyles(theme.textStyles.body16Light) : ""}
      ${name === TYPOGRAPHY_NAMES.body16Bold ? renderTextStyles(theme.textStyles.body16Bold) : ""}
      ${name === TYPOGRAPHY_NAMES.body20 ? renderTextStyles(theme.textStyles.body20) : ""}
      ${name === TYPOGRAPHY_NAMES.body24 ? renderTextStyles(theme.textStyles.body24) : ""}
      ${name === TYPOGRAPHY_NAMES.badge ? renderTextStyles(theme.textStyles.badge) : ""}
      ${name === TYPOGRAPHY_NAMES.modalHeader ? renderTextStyles(theme.textStyles.modalHeader) : ""}
      
    `;
    const textColor = color ? `color: ${theme.palette[color]};` : "";
    const firstLetterCapital = isCapitalize
      ? css`
          &::first-letter {
            text-transform: uppercase;
          }
        `
      : "";
    const underline = isUnderline
      ? `
          text-decoration: underline;
          text-underline-offset: 5px;
          text-decoration-color: ${theme.palette.primaryMain};
          text-decoration-thickness: 1px;
        `
      : "";
    const crossed = isCrossed
            ? `
          text-decoration: line-through;
          text-decoration-color: ${theme.palette.secondaryNeutralDark};
          text-decoration-thickness: 2px;
        `
            : "";

    return `
      ${textColor}
      ${firstLetterCapital}
      ${textStyles(typographyName)}
      ${underline}
      ${crossed}
    `;
  }}
`;
