import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Loader,
  Modal,
  TextInput,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import { VALIDATE_EMAIL } from "../../constants";
import { useSendOTP } from "../../api";

export const EmailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const { sendOTP, isSuccessSendOTP, isLoadingSendOTP } = useSendOTP();

  const handleAccept = useCallback(() => {
    sendOTP({
      Email: email,
      Language: localStorage.getItem("Language") ?? "En"
    });
  }, [email, sendOTP]);

  useEffect(() => {
    if (isSuccessSendOTP) {
      navigate("/check_otp", { state: { email } });
    }
  }, [isSuccessSendOTP, email]);

  const handleReject = useCallback(() => window.open(t("prod_url"), "_self"), []);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value);
    const valid = VALIDATE_EMAIL.test(target.value);
    setDisabled(!valid);
  };

  if (isLoadingSendOTP) return <Loader />;

  return (
    <Modal width={620} height={650} centered showCompanyInfo>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        textAlign="left">
        <Typography name={TYPOGRAPHY_NAMES.modalHeader}>{t("prod_email_title")}</Typography>
        <Typography name={TYPOGRAPHY_NAMES.body16Light}>{t("prod_email_description")}</Typography>

        <Box pt={40} width="100%">
          <TextInput
            label={t("prod_email_field_lbl")}
            placeholder={t("prod_email_field_hint")}
            hint={{ text: t("prod_email_field_rule") }}
            type="text"
            width="100%"
            value={email}
            onChange={handleChange}
          />
        </Box>

        <Box pt={40} width="100%">
          <Button size={BUTTON_SIZE.maxWidth} onClick={handleAccept} disabled={disabled}>
            {t("prod_btn_next")}
          </Button>
        </Box>
        <Box pt={20} width="100%">
          <Button
            size={BUTTON_SIZE.maxWidth}
            buttonType={BUTTON_TYPE.secondary}
            onClick={handleReject}>
            {t("prod_btn_cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
