import { useQuery } from "react-query";
import { getDevices } from "../requests/getDevices";

export const useGetDevices = () => {
  const { data, refetch, isSuccess, isLoading } = useQuery("getDevices", getDevices, {
    enabled: false,
  });
  return {
    devices: data?.data,
    refetchDevices: refetch,
    isSuccessDevices: isSuccess,
    isLoadingDevices: isLoading
  };
};
