import React from "react";

export interface INotificationProps {
  children: React.ReactNode | string;
  type: NOTIFICATION_TYPE;
  maxWidth?: number | string;
}

export enum NOTIFICATION_TYPE {
  error = "error",
  warning = "warning"
}
