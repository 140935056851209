import { axiosInstance } from "../../axiosInstance";

type AddClientDetailsRequest = {
  CompanyName: string;
  CompanyWebAddress: string;
  Country: string;
  State: string;
  Address: string;
  Address2: string;
  Zip: string;
  CompanyPhoneNumber: string;
  FirstName: string;
  LastName: string;
  Role: string;
  AccountPhoneNumber: string;
  AccountEmail: string;
}

export const addClientDetails = (request: AddClientDetailsRequest) => {
  return axiosInstance.post<string>("/AddClientDetails", request);
};
