import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGE, LANG_EN, LANG_FR } from "./constants";
import translationEn from "./assets/i18n/En.json";
import translationFr from "./assets/i18n/Fr.json";

const checkLanguage = () => {
  // Check language from query string
  if (typeof window !== "undefined") {
    const { search } = window.location;
    const params = search.substring(1).split("&");
    for (let i = 0; i < params.length; i++) {
      const param = params[i].split("=");
      if (param[0] === "lng" && (param[1] === LANG_EN || param[1] === LANG_FR)) {
        localStorage.setItem(LANGUAGE, param[1]);
        return param[1].toLowerCase();
      }
    }
  }

  const savedLanguage = localStorage.getItem(LANGUAGE);
  if (savedLanguage && (savedLanguage === LANG_EN || savedLanguage === LANG_FR)) {
    return savedLanguage.toLowerCase();
  }

  return LANG_EN.toLowerCase();
};

const resources = {
  fr: {
    translation: translationFr
  },
  en: {
    translation: translationEn
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: checkLanguage(),
  interpolation: {
    escapeValue: false
  },
  returnNull: false,
  react: {
    transEmptyNodeValue: "",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"]
  }
});

export default i18n;
