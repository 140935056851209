import {
  Box,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Hint,
  Line,
  Loader,
  PriceCalculator,
  PriceCard,
  TextInput,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CouponMessage, Page } from "./PricePage.styles";
import { ButtonArrowIcon } from "../../assets";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetPricingDetails, useUpdateSecurityOptions } from "../../api";
import { PaymentContext } from "../../context/PaymentContext";
import { PermissionsContext } from "../../context/PermissionsContext";
import { AccessLevels } from "../../routes/routes.types";

export const PricePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState<string>();
  const [couponApplied, setCouponApplied] = useState<boolean>();
  const { price, updatePrice, isLoadingPrice } = useGetPricingDetails();
  const { period, subtotal, updates, vulnerabilities } = useContext(PaymentContext);
  const { pageId } = useContext(PermissionsContext);
  const { updateSecurityOptions } = useUpdateSecurityOptions();

  useEffect(() => {
    updatePrice({ PromoCode: "" });
  }, []);

  const handleCouponChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setCoupon(value.trim());
    },
    [coupon]
  );

  const handleCouponCheck = useCallback(() => {
    setCouponApplied(true);
  }, [coupon]);

  const handleBack = useCallback(() => {
    if (pageId >= AccessLevels.PaymentNeeded) navigate("/home");
    else navigate("/");
  }, [pageId]);

  const paidOption = (name: string) => {
    return price?.Variant.map(({ Options }) => {
      return Options.filter(({ Name, Paid }) => Paid && Name === name).length > 0;
    }).some(option => option)
  }

  const handleCheckout = useCallback(() => {
    if (period && price) {
      updateSecurityOptions({
        PlanType: period,
        OptionUpdates: paidOption("Updates") || updates,
        OptionVulnerabilities: paidOption("Vulnerabilities") || vulnerabilities
      });
    }
    if (pageId >= AccessLevels.PaymentNeeded) navigate("/payment");
    else navigate("/details");
  }, [period, pageId]);

  const handleSave = useCallback(() => {
    navigate("/paused");
  }, []);

  if (isLoadingPrice) return <Loader />;

  return (
    <Page>
      <Box pt={60} pb={30}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t("prod_pricing_title")}</Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"} gap={32}>
        <Box display={"flex"} alignItems={"flex-end"} gap={32}>
          {price?.Variant.map(({ PricePeriod, PerDevicePrice, Discount, Options }) => (
            <PriceCard
              key={PricePeriod}
              NumberOfDevices={price.NumberOfDevices}
              Taxes={price.Taxes}
              PricePeriod={PricePeriod}
              PerDevicePrice={PerDevicePrice}
              Discount={Discount}
              Options={Options}
            />
          ))}
        </Box>
        <PriceCalculator />
      </Box>
      <Box mt={40} mb={63}>
        <Typography name={TYPOGRAPHY_NAMES.H2}>{t("prod_pricing_code_lbl")}</Typography>
        <Box display={"flex"} mt={8} gap={10} alignItems={"center"}>
          <TextInput
            type={"text"}
            width={"344px"}
            placeholder={`${t("prod_pricing_code_hint")}`}
            onChange={handleCouponChange}
            // disabled={ couponApplied }
            disabled={true}
          />
          <Button
            buttonType={BUTTON_TYPE.ghost}
            size={BUTTON_SIZE.fitContent}
            onClick={handleCouponCheck}
            // disabled={ !coupon || couponApplied }
            disabled={true}>
            {t("prod_btn_apply")}
          </Button>
        </Box>
        {couponApplied && (
          <CouponMessage>
            <Hint type={"success"} />
            <Typography name={TYPOGRAPHY_NAMES.body14}>
              {t("prod_pricing_codeapplied_lbl")}
            </Typography>
          </CouponMessage>
        )}
      </Box>
      <Box>
        <Line />
        <Box pt={32} pb={32} display="flex" gap={32}>
          <Button
            buttonType={BUTTON_TYPE.ghost}
            size={BUTTON_SIZE.fitContent}
            leftIcon={<ButtonArrowIcon />}
            onClick={handleBack}>
            {t("prod_btn_back")}
          </Button>
          <Button size={BUTTON_SIZE.small} disabled={!subtotal} onClick={handleCheckout}>
            {t("prod_btn_checkout")}
          </Button>
          <Button size={BUTTON_SIZE.small} buttonType={BUTTON_TYPE.secondary} onClick={handleSave}>
            {t("prod_btn_save_exit")}
          </Button>
        </Box>
      </Box>
    </Page>
  );
};
