import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmStripePayment } from "../../api";
import { Loader } from "../../components";
import { PAYMENT_STATUS } from "../../constants";
import { AccessLevels } from "../../routes/routes.types";
import { PermissionsContext } from "../../context/PermissionsContext";

export const PaymentCompletePage = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const {
    confirmStripePayment,
    refetchconfirmStripePayment,
    isSuccessConfirmStripePayment,
    isLoadingConfirmStripePayment
  } = useConfirmStripePayment();
  const { updateContext } = useContext(PermissionsContext);

  useEffect(() => {
    if (isLoadingConfirmStripePayment) return;

    if (confirmStripePayment?.PaymentStatus === PAYMENT_STATUS.SUCCEEDED) {
      updateContext({ pageId: AccessLevels.PaymentNeeded });
      navigate("/home");
    } else if (confirmStripePayment?.PaymentStatus === PAYMENT_STATUS.PROCESSING) {
      setMessage("Your payment is processing.");
    } else if (confirmStripePayment?.PaymentStatus) {
      setMessage("Your payment is processing.");
    } else if (!isSuccessConfirmStripePayment) {
      setMessage("Error happened");
    }
  }, [
    navigate,
    confirmStripePayment,
    refetchconfirmStripePayment,
    isLoadingConfirmStripePayment,
    isSuccessConfirmStripePayment,
    updateContext
  ]);

  useEffect(() => {
    const timerId = setInterval(() => {
      refetchconfirmStripePayment();
    }, 3000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return <Loader text={ message } />;
};
