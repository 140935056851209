import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  BUTTON_SIZE,
  Modal,
  RadioField,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import { LANG_EN, LANG_FR, LANGUAGE } from "../../constants";
import { PermissionsContext } from "../../context/PermissionsContext";

interface ILanguageSelectProps {
  path: "default" | "user";
}

export const LanguageSelect = ({ path }: ILanguageSelectProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState<string>(LANG_EN);
  const { updateContext } = useContext(PermissionsContext);
  const [searchParams] = useSearchParams();

  const setLanguage = useCallback(
    (language: string) => {
      return () => {
        setLang(language);
        i18n.changeLanguage(language.toLowerCase());
      };
    },
    [i18n]
  );

  useEffect(() => {
    const savedLanguage = localStorage.getItem(LANGUAGE);
    if (savedLanguage && (savedLanguage === LANG_EN || savedLanguage === LANG_FR))
      setLanguage(savedLanguage)();
  }, [setLanguage]);

  const handleAccept = useCallback(() => {
    localStorage.setItem(LANGUAGE, lang);
    updateContext({ language: lang });
    if (path === "default") {
      navigate("/license");
    } else {
      const mail = searchParams.get("mail");
      navigate("/user/license", { state: { mail } });
    }
  }, [navigate, path]);

  return (
    <Modal width={420} centered>
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
        <Typography name={TYPOGRAPHY_NAMES.modalHeader}>{t("prod_lingua")}</Typography>
        <Box pt={20} width="100%" textAlign="left">
          <RadioField
            type="button"
            name={LANG_EN}
            width="100%"
            checked={lang === LANG_EN}
            onChange={setLanguage(LANG_EN)}
            label={t("prod_lingua_en")}
          />
        </Box>
        <Box pt={20} width="100%" textAlign="left">
          <RadioField
            type="button"
            name={LANG_FR}
            width="100%"
            checked={lang === LANG_FR}
            onChange={setLanguage(LANG_FR)}
            label={t("prod_lingua_fr")}
          />
        </Box>
        <Box pt={20} width="100%">
          <Button size={BUTTON_SIZE.maxWidth} onClick={handleAccept}>
            {t("prod_btn_accept")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
