import { Box } from "../Box";
import styled from "styled-components";
import { IProgressLineStylesProps } from "./ProgressLine.types";

export const ProgressBar = styled(Box)`
  background: ${({ theme }) => theme.palette.primaryNeutral};
  border-radius: 10px;
`;

export const ProgressValue = styled(Box)<IProgressLineStylesProps>`
  height: 100%;
  background: ${({ color, theme }) => (color ? theme.palette[color] : theme.palette.primaryMain)};
  border-radius: 10px;
`;
