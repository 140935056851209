import styled from "styled-components";
import { Box } from "../../components";

export const Page = styled(Box)`
  margin: auto;
  max-width: 1000px;
`;

export const CouponMessage = styled(Box)`
  display: flex;
  gap: 1ch;
  align-items: center;

  & span {
    padding-top: 3px;
  }
`;
