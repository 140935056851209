import React from "react";
import { DefaultTheme, useTheme } from "styled-components";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { Box } from "../Box";
import { AlertIcon } from "../../assets/icons";
import { INotificationProps, NOTIFICATION_TYPE } from "./Notification.types";
import { ColorBlock, MainBlock } from "./Notification.styles";

const getColors = (type: NOTIFICATION_TYPE, theme: DefaultTheme) => {
  switch (type) {
    case NOTIFICATION_TYPE.error:
      return { accentColor: theme.palette.accentError, lightColor: theme.palette.thirdErrorLight };
    default:
      return {
        accentColor: theme.palette.accentWarning2,
        lightColor: theme.palette.thirdWarning2Light
      };
  }
};

export const Notification: React.FC<INotificationProps> = ({ type, children, maxWidth }) => {
  const theme = useTheme();
  const colors = getColors(type, theme);
  return (
    <Box display="flex" maxWidth={maxWidth}>
      <ColorBlock color={colors.accentColor} />
      <MainBlock display="flex" alignItems="center" width={"100%"} color={colors.lightColor}>
        <Box width={"24px"}>
          <AlertIcon color={colors.accentColor} />
        </Box>
        <Typography name={TYPOGRAPHY_NAMES.body16}>{children}</Typography>
      </MainBlock>
    </Box>
  );
};
