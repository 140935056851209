import React from "react";

export const CheckedIcon = ({ size = "12px", color }: { size?: string; color: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6673 1.6665L4.25065 8.33317L1.33398 5.30287"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
