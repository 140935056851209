export const Visa = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="14"
    overflow="visible"
    version="1.1"
    viewBox="0 0 1000 324.684"
    xmlSpace="preserve">
    <defs>
      <linearGradient>
        <stop offset="0" stopColor="#20225f" stopOpacity="1"></stop>
        <stop offset="0.2" stopColor="#1a1f61" stopOpacity="1"></stop>
        <stop offset="0.41" stopColor="#172272" stopOpacity="1"></stop>
        <stop offset="0.595" stopColor="#152682" stopOpacity="1"></stop>
        <stop offset="0.802" stopColor="#12288e" stopOpacity="1"></stop>
        <stop offset="1" stopColor="#0e2c9a" stopOpacity="1"></stop>
      </linearGradient>
    </defs>
    <path
      fill="#1434cb"
      fillOpacity="1"
      stroke="none"
      d="M651.185.5c-70.933 0-134.322 36.766-134.322 104.694 0 77.9 112.423 83.28 112.423 122.415 0 16.478-18.884 31.229-51.137 31.229-45.773 0-79.984-20.611-79.984-20.611l-14.638 68.547s39.41 17.41 91.734 17.41c77.552 0 138.576-38.572 138.576-107.66 0-82.316-112.89-87.537-112.89-123.86 0-12.91 15.501-27.053 47.662-27.053 36.286 0 65.892 14.99 65.892 14.99l14.326-66.204S696.614.5 651.185.5zM2.218 5.497L.5 15.49s29.842 5.461 56.719 16.356c34.606 12.492 37.072 19.765 42.9 42.353l63.51 244.832h85.138L379.927 5.497h-84.942L210.707 218.67l-34.39-180.696c-3.154-20.68-19.13-32.477-38.685-32.477H2.218zm411.865 0L347.449 319.03h80.999l66.4-313.534h-80.765zm451.759 0c-19.532 0-29.88 10.457-37.474 28.73L709.699 319.03h84.942l16.434-47.468h103.483l9.994 47.468H999.5L934.115 5.497h-68.273zm11.047 84.707l25.178 117.653h-67.454L876.89 90.204z"></path>
  </svg>
);
