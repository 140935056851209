import { useQuery } from "react-query";
import { getAdminConsentLink } from "../requests/getAdminConsentLink";

export const useGetAdminConsentLink = () => {
  const { data, isLoading, isSuccess, refetch } = useQuery("getAdminConsentLink", getAdminConsentLink);
  return {
    link: data?.data.AdminConsentLink,
    isLoadingLink: isLoading,
    refetchLink: refetch,
    isSuccessLink: isSuccess
  };
};
