import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Loader,
  Modal,
  Notification,
  NOTIFICATION_TYPE,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import { SiteLink } from "./LinkToMS.styles";
import { useCheckPrerequisites, useGetAdminConsentLink } from "../../api";
import { PermissionsContext } from "../../context/PermissionsContext";

export const LinkToMS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { link, isLoadingLink, isSuccessLink } = useGetAdminConsentLink();
  const { permissionsStatus } = useCheckPrerequisites();
  const { updateContext } = useContext(PermissionsContext);

  useEffect(() => {
    if (permissionsStatus === "Granted") {
      updateContext({ permissionsGranted: true });
      navigate("/");
    }
  }, [permissionsStatus, permissionsStatus]);

  const handleExit = useCallback(() => navigate("/paused"), []);

  if (isLoadingLink) return <Loader />;

  return (
    <Modal width={620} height={650} centered showCompanyInfo showCloseIcon onClose={handleExit}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        textAlign="left">
        <Typography name={TYPOGRAPHY_NAMES.modalHeader}>
          {t("prod_delegate_description")}
        </Typography>
        <Box pt={32}>
          {isSuccessLink && (
            <>
              <a href={link} target="_blank">
                <SiteLink name={TYPOGRAPHY_NAMES.body24}>{link?.slice(0, 40)}...</SiteLink>
              </a>
            </>
          )}
        </Box>
        <Box width="100%" pt={40}>
          <Notification type={NOTIFICATION_TYPE.warning}>{t("prod_delegate_warning")}</Notification>
        </Box>

        {permissionsStatus !== "Granted" && (
          <Box width="100%" pt={40}>
            <Notification type={NOTIFICATION_TYPE.error}>{t("prod_delegate_wait")}</Notification>
          </Box>
        )}

        <Box width="100%" pt={40}>
          <Button
            onClick={handleExit}
            size={BUTTON_SIZE.maxWidth}
            buttonType={BUTTON_TYPE.secondary}>
            {t("prod_btn_save_exit")}
          </Button>
        </Box>
      </Box>
      <Outlet />
    </Modal>
  );
};
