export const MainLogoEn = () => (
  <svg width="151" height="60" viewBox="0 0 151 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8712 49.883C30.8458 49.883 39.7424 40.9811 39.7424 30C39.7424 19.0189 30.8458 10.1169 19.8712 10.1169C8.89664 10.1169 0 19.0189 0 30C0 40.9811 8.89664 49.883 19.8712 49.883Z"
      fill="#95268D"
    />
    <path
      d="M22.8145 47.4282C32.4341 47.4282 40.2324 39.6253 40.2324 29.9999C40.2324 20.3745 32.4341 12.5715 22.8145 12.5715C13.1948 12.5715 5.39648 20.3745 5.39648 29.9999C5.39648 39.6253 13.1948 47.4282 22.8145 47.4282Z"
      fill="#95268D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8143 13.7513C13.8467 13.7513 6.5758 21.0255 6.5758 30.0002C6.5758 38.9748 13.8467 46.249 22.8143 46.249C31.7819 46.249 39.0527 38.9748 39.0527 30.0002C39.0527 21.0255 31.7819 13.7513 22.8143 13.7513ZM4.2168 30.0002C4.2168 19.724 12.5425 11.3923 22.8143 11.3923C33.086 11.3923 41.4117 19.724 41.4117 30.0002C41.4117 40.2763 33.086 48.608 22.8143 48.608C12.5425 48.608 4.2168 40.2763 4.2168 30.0002Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6136 45.8083C26.7246 44.959 24.9828 44.2029 23.263 43.3978C22.4044 42.9928 21.5679 42.5288 20.7509 42.0404C19.934 41.5519 19.9389 41.2696 20.3192 40.4325C20.6256 39.7947 20.8538 39.1222 20.9987 38.4295C21.0625 38.0883 20.903 37.4992 20.6528 37.3568C20.3609 37.1899 19.7868 37.266 19.4654 37.4574C16.7497 39.0456 13.7641 39.1757 10.5332 39.2469C11.3673 38.1767 12.0886 37.2488 12.8196 36.3258C13.3103 35.7146 13.7764 35.0985 14.3063 34.5192C14.6056 34.1951 14.6031 33.9325 14.4706 33.5373C13.585 30.9132 13.612 28.3407 15.0987 25.9277C16.9018 22.9821 19.831 22.0788 22.9637 22.3856C28.0223 22.8766 30.3725 27.5847 30.4191 31.2102C30.4191 32.2265 30.4191 33.3336 30.3578 34.3473C30.321 34.907 29.8524 35.0837 29.5065 34.6738C29.2016 34.3425 28.8474 34.0602 28.4565 33.8368C27.9888 34.0281 27.5389 34.2606 27.1122 34.5315C26.7197 34.8162 26.2854 35.0469 25.6991 35.4053C26.2584 35.6508 27.5783 36.0411 27.5783 36.0411C27.4985 36.1704 27.4328 36.3078 27.382 36.451C26.8914 38.8861 27.4752 41.2205 28.0101 43.5696C28.1695 44.2692 28.3805 44.959 28.6136 45.823"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00635 35.4004C5.70951 34.0356 5.19679 32.8868 5.02506 31.5097C4.75975 29.2997 4.9188 27.0593 5.49363 24.909C5.73896 23.9959 6.14374 23.581 6.88707 23.0606C7.86837 22.3782 9.96588 21.7621 11.9898 21.9217C11.2538 21.2761 9.76471 21.2368 6.838 21.6492C9.78189 14.7564 19.4967 11.1726 25.5243 12.1471C24.2486 13.6346 23.2943 15.5149 23.2207 17.5106C23.2943 17.3584 24.1505 15.0559 24.781 14.1084C25.1788 13.5126 25.7572 13.0604 26.4311 12.8182C27.1051 12.5761 27.8389 12.5568 28.5246 12.7632C29.3268 13.0087 30.053 13.4186 30.9975 13.7647C30.6884 13.8874 30.5436 13.9513 30.394 14.0102C27.5826 14.916 25.8359 16.8306 25.1023 19.656C24.9748 20.1469 24.8227 20.2083 24.3664 20.088C21.6899 19.4031 19.033 19.3516 16.5357 20.7189C13.6801 22.2825 12.0634 24.7298 11.6635 27.9651C11.6466 28.1725 11.6409 28.3806 11.6463 28.5886C11.6463 28.5886 11.2759 28.8955 11.0747 29.0354C9.11216 30.4174 7.77269 32.3247 6.72025 34.4504C6.58532 34.7229 6.47493 35.0003 6.33264 35.2727C6.31792 35.3071 6.27866 35.3317 6.24922 35.3587L6.0039 35.4004"
      fill="white"
    />
    <path
      d="M56.4085 34.7034C56.4085 35.1836 56.2782 35.5508 56.0174 35.8051C55.7567 36.0593 55.3873 36.1864 54.9092 36.1864H49.9114C49.4334 36.1864 49.064 36.0593 48.8032 35.8051C48.5425 35.5508 48.4121 35.1836 48.4121 34.7034V30.8475H50.8893V34.1525H53.9314V30.9746L48.9553 26.3771C48.7525 26.1935 48.6077 26.0099 48.5208 25.8263C48.4483 25.6427 48.4121 25.3955 48.4121 25.0847V21.4831C48.4121 21.0028 48.5425 20.6356 48.8032 20.3814C49.064 20.1271 49.4334 20 49.9114 20H54.9092C55.3873 20 55.7567 20.1271 56.0174 20.3814C56.2782 20.6356 56.4085 20.9958 56.4085 21.4619V25.0847H53.9314V22.0339H50.8893V24.9576L55.8653 29.5339C56.0681 29.7316 56.2057 29.9223 56.2782 30.1059C56.3651 30.2895 56.4085 30.5367 56.4085 30.8475V34.7034Z"
      fill="#95268D"
    />
    <path
      d="M65.4052 34.089C65.4052 34.7246 65.2096 35.233 64.8185 35.6144C64.4274 35.9958 63.8769 36.1864 63.1671 36.1864H60.6899V40H58.2562V25.7415H60.6899V26.1229L63.2975 25.7839C63.4278 25.7556 63.5582 25.7345 63.6886 25.7203C63.8334 25.7062 63.9638 25.6992 64.0797 25.6992C64.9634 25.6992 65.4052 26.1229 65.4052 26.9703V34.089ZM62.9715 27.7119L60.6899 27.7542V34.2161H62.1892C62.479 34.2161 62.6818 34.1525 62.7977 34.0254C62.9136 33.8983 62.9715 33.7006 62.9715 33.4322V27.7119Z"
      fill="#95268D"
    />
    <path
      d="M71.7112 36.1864V35.8263L69.1471 36.1441C68.9877 36.1723 68.8501 36.1935 68.7342 36.2076C68.6328 36.2218 68.5242 36.2288 68.4083 36.2288C67.5246 36.2288 67.0828 35.8051 67.0828 34.9576V31.1864C67.0828 30.3249 67.5391 29.8941 68.4517 29.8941H71.7329V27.5H69.4078V28.9831H67.2132V27.0339C67.2132 26.1723 67.6695 25.7415 68.5821 25.7415H72.689C73.5871 25.7415 74.0362 26.1723 74.0362 27.0339V36.1864H71.7112ZM71.7329 31.5042H69.4078V34.3856L71.7329 34.3008V31.5042Z"
      fill="#95268D"
    />
    <path
      d="M80.13 30.1059V27.7119L78.2178 27.7542V36.1864H75.7841V25.7415H78.2178V26.1229L80.4342 25.7839C80.5645 25.7556 80.6949 25.7345 80.8253 25.7203C80.9702 25.7062 81.1005 25.6992 81.2164 25.6992C82.1001 25.6992 82.5419 26.1229 82.5419 26.9703V30.1059H80.13Z"
      fill="#95268D"
    />
    <path
      d="M85.8453 36.1864C84.9472 36.1864 84.4981 35.7556 84.4981 34.8941V27.7542H83.3464V25.7415H84.4981V22.9025H86.91V25.7415H88.4746V27.7542H86.91V34.1737H88.5398V36.1864H85.8453Z"
      fill="#95268D"
    />
    <path
      d="M94.4804 36.1864V35.8263L91.9163 36.1441C91.757 36.1723 91.6193 36.1935 91.5034 36.2076C91.402 36.2218 91.2934 36.2288 91.1775 36.2288C90.2938 36.2288 89.852 35.8051 89.852 34.9576V31.1864C89.852 30.3249 90.3083 29.8941 91.221 29.8941H94.5021V27.5H92.1771V28.9831H89.9824V27.0339C89.9824 26.1723 90.4387 25.7415 91.3513 25.7415H95.4582C96.3564 25.7415 96.8054 26.1723 96.8054 27.0339V36.1864H94.4804ZM94.5021 31.5042H92.1771V34.3856L94.5021 34.3008V31.5042Z"
      fill="#95268D"
    />
    <path
      d="M103.269 36.1864V27.7119L100.987 27.7542V36.1864H98.5533V25.7415H100.987V26.1229L103.551 25.7839C103.696 25.7556 103.841 25.7345 103.986 25.7203C104.131 25.7062 104.261 25.6992 104.377 25.6992C105.26 25.6992 105.702 26.1229 105.702 26.9703V36.1864H103.269Z"
      fill="#95268D"
    />
    <path
      d="M115.789 34.7034C115.789 35.6921 115.282 36.1864 114.268 36.1864H109.053C108.067 36.1864 107.575 35.6921 107.575 34.7034V21.4831C107.575 20.4944 108.075 20 109.074 20H114.268C115.282 20 115.789 20.4944 115.789 21.4831V24.9364H113.29V22.0763H110.052V34.1102H113.29V29.5763H111.443V27.5212H115.789V34.7034Z"
      fill="#231F20"
    />
    <path
      d="M122.345 36.1864V35.8263L119.781 36.1653C119.637 36.1935 119.492 36.2147 119.347 36.2288C119.202 36.2429 119.072 36.25 118.956 36.25C118.072 36.25 117.63 35.8192 117.63 34.9576V25.7415H120.042V34.2161L122.345 34.1737V25.7415H124.757V36.1864H122.345Z"
      fill="#231F20"
    />
    <path
      d="M131.106 36.1864V35.8263L128.542 36.1441C128.383 36.1723 128.245 36.1935 128.129 36.2076C128.028 36.2218 127.919 36.2288 127.803 36.2288C126.92 36.2288 126.478 35.8051 126.478 34.9576V31.1864C126.478 30.3249 126.934 29.8941 127.847 29.8941H131.128V27.5H128.803V28.9831H126.608V27.0339C126.608 26.1723 127.065 25.7415 127.977 25.7415H132.084C132.982 25.7415 133.431 26.1723 133.431 27.0339V36.1864H131.106ZM131.128 31.5042H128.803V34.3856L131.128 34.3008V31.5042Z"
      fill="#231F20"
    />
    <path
      d="M139.525 30.1059V27.7119L137.613 27.7542V36.1864H135.179V25.7415H137.613V26.1229L139.829 25.7839C139.96 25.7556 140.09 25.7345 140.22 25.7203C140.365 25.7062 140.496 25.6992 140.612 25.6992C141.495 25.6992 141.937 26.1229 141.937 26.9703V30.1059H139.525Z"
      fill="#231F20"
    />
    <path
      d="M148 36.1864V35.8263L146.153 36.1441C145.834 36.2147 145.53 36.25 145.241 36.25C144.632 36.25 144.154 36.0805 143.806 35.7415C143.459 35.3884 143.285 34.9082 143.285 34.3008V27.839C143.285 27.2034 143.48 26.6949 143.872 26.3136C144.263 25.9322 144.813 25.7415 145.523 25.7415H148V20H150.412V36.1864H148ZM148 27.7119H146.501C145.965 27.7119 145.697 27.9732 145.697 28.4958V33.4958C145.697 34.0042 145.95 34.2585 146.457 34.2585C146.515 34.2585 146.588 34.2585 146.675 34.2585C146.762 34.2585 146.841 34.2514 146.914 34.2373L148 34.1525V27.7119Z"
      fill="#231F20"
    />
  </svg>
);
