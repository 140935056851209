import { Box } from "../Box";
import { Line } from "../Line";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { Content } from "./AccordionSection.styles";

export interface IAccordionSectionProps {
  active: boolean;
  label: string;
  logo: React.ReactNode;
  children: React.ReactNode;
  onClick: () => void;
}

export const AccordionSection = ({
  active,
  label,
  logo,
  children,
  onClick
}: IAccordionSectionProps) => {
  return (
    <Content active={active} mb={30}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={[40]}
        onClick={onClick}>
        <Typography name={TYPOGRAPHY_NAMES.body20}>{label}</Typography>
        <Box display="flex" alignItems="center" gap={10}>
          {logo}
        </Box>
      </Box>
      {active && (
        <Box padding={[0, 40, 0, 40]}>
          <Line />
          <Box padding={[40, 0, 40, 0]}>{children}</Box>
        </Box>
      )}
    </Content>
  );
};
