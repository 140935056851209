import { useQuery } from "react-query";
import { confirmStripePayment } from "../index";

export const useConfirmStripePayment = () => {
  const { refetch, data, isLoading, isSuccess } = useQuery(
    "confirmStripePayment",
    confirmStripePayment
  );
  return {
    confirmStripePayment: data?.data,
    refetchconfirmStripePayment: refetch,
    isLoadingConfirmStripePayment: isLoading,
    isSuccessConfirmStripePayment: isSuccess
  };
};
