import { InputFieldProps } from "./TextInput.types";
import { Input } from "./TextInput.styles";
import { Box } from "../Box";
import { Hint, Label } from "../Common";
import { forwardRef } from "react";

export const TextInput = forwardRef<HTMLInputElement, InputFieldProps>(function TextInput(
  {
    mt,
    mb,
    name,
    type = "text",
    value,
    label,
    hint,
    placeholder,
    width,
    disabled,
    isError,
    trim,
    onClick,
    onChange,
    ...props
  }: InputFieldProps,
  ref
) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (!onChange) {
      return null;
    }

    if (trim) {
      event.target.value = event.target.value.trim();
    }

    onChange(event);
  };

  return (
    <Box mt={ mt } mb={ mb } display="flex" flexDirection="column" gap={ 6 } width={ width } name={ name }>
      { label && <Label>{ label }</Label> }
      <Input
        ref={ ref }
        type={ type }
        defaultValue={ value }
        disabled={ disabled }
        placeholder={ placeholder }
        onClick={ onClick }
        onChange={ handleChange }
        { ...props }
      />
      { hint && <Hint type={ isError ? "error" : hint.type } text={ hint.text } /> }
    </Box>
  );
});
