import { axiosInstance } from "../../axiosInstance";

type UpdateRecord = {
  updated: number;
  description: string;
}

export type ScoreRecord = {
  percentage: number;
  totalDevices: number;
  installedDevices: number;
}

type GetSecurityInfoResponse = {
  lastUpdated: number;
  GlobalSecureScore: number;
  scoreWindowsOS: ScoreRecord,
  scoreIOS: ScoreRecord,
  scoreAOS: ScoreRecord,
  scoreMacOS: ScoreRecord,
  scoreLinuxOS: ScoreRecord,
  updateHistory: UpdateRecord[];
}
export const getSecurityInfo = () => {
  return axiosInstance.get<GetSecurityInfoResponse>("/GetSecurityInfo");
};
