import { Box } from "../Box";
import styled from "styled-components";

export const PriceCalculatorWrapper = styled(Box)`
  width: 356px;
  border-radius: 7px;
  background: ${ ({ theme }) => theme.palette.thirdBasicLight };
  padding: 24px 32px;
  margin-top: 20px;
`;

export const PriceSubscription = styled(Box)`
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid ${ ({ theme }) => theme.palette.primaryNeutral };
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
