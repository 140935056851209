import { axiosInstance } from "../../axiosInstance";

type AddSecurityOptionsRequest = {
  ExistingAV?: string;
  CountAndroid?: number;
  CountEmployees?: number;
  CountIOS?: number;
  CountLinux?: number;
  CountMacOS?: number;
  CountWindows?: number;
  ProtectAllDevices?: boolean;
  OptionVulnerabilities?: boolean;
  OptionUpdates?: boolean;
}

export const addSecurityOptions = (request: AddSecurityOptionsRequest) => {
  return axiosInstance.post<string>("/AddSecurityOptions", request);
};
