import { useMutation } from "react-query";
import { addSecurityOptions } from "../requests/addSecurityOptions";

export const useAddSecurityOptions = () => {
  const { mutate, isSuccess, isLoading } = useMutation(addSecurityOptions);
  return {
    addSecurityOptions: mutate,
    isSuccessAddSecurityOptions: isSuccess,
    isLoadingAddSecurityOptions: isLoading
  };
};
