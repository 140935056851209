import { useTranslation } from "react-i18next";
import { Box, Typography, TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from "../../components";
import { SpartanIcon } from "../../assets";
import { Wrapper } from "./ThankYouUser.styles";

export const ThankYouUser = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Box display="flex" flexDirection="column" gap={32}>
        <Box width={430}>
          <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.Accent}>
            {t("prod_pricing_thank_lbl")}
          </Typography>
          <Typography name={TYPOGRAPHY_NAMES.Accent}>{t("prod_user_thank_1")}</Typography>
        </Box>
        <Typography name={TYPOGRAPHY_NAMES.body16}>{t("prod_user_thank_2")}</Typography>
      </Box>
      <SpartanIcon />
    </Wrapper>
  );
};
