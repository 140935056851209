import styled from "styled-components";

export const Page = styled.div`
  margin: auto;
  max-width: 1000px;
`

export const PageSection = styled.div`
  padding: 60px 120px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 20px;
`;
