import { useMutation } from "react-query";
import { processPayment } from "../requests/processPayment";

export const useProcessPayment = () => {
  const { mutate, isLoading, isSuccess, isError, data, error } = useMutation(processPayment);
  return {
    startPayment: mutate,
    isSuccessProcessPayment: isSuccess,
    isErrorProcessPayment: isError,
    isLoadingProcessPayment: isLoading,
    paymentResult: data,
    paymentError: error
  };
};
