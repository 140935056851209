import { ControllerFieldState } from "react-hook-form";

export interface IFormFieldError {
  fieldState: ControllerFieldState;
  minLength?: number;
  maxLength?: number;
  customError?: {
    display: boolean;
    text: string;
  };
}

export enum ErrorTypes {
  required = "required",
  minLength = "minLength",
  maxLength = "maxLength",
  pattern = "pattern",
}
