import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Button, BUTTON_SIZE, Modal, RadioField, Typography, TYPOGRAPHY_NAMES } from "../../components";
import { AccessLevels } from "../../routes/routes.types";
import { PermissionsContext } from "../../context/PermissionsContext";

const YES = "yes";
const NO = "no";

export const CheckMS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [choice, setChoice] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const { pageId } = useContext(PermissionsContext);

  useEffect(() => {
    if (pageId >= AccessLevels.PaymentNeeded) navigate("/home");
    else if (pageId >= AccessLevels.MsPermissionsNeeded && pageId < AccessLevels.PaymentNeeded) navigate("/");
  }, []);

  const handleReject = useCallback(() => window.location.replace(t("prod_url")), [t]);
  const handleAccept = useCallback(
    () => (choice === YES ? navigate("/grant_ms") : navigate("/check_ms_error")),
    [choice]
  );

  const handleChoice = useCallback((value: string) => {
    return () => {
      setChoice(value);
      setDisabled(false);
    };
  }, []);

  return (
    <>
      <Modal width={620} height={650} centered showCompanyInfo showCloseIcon onClose={handleReject}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
          height="100%"
          textAlign="left">
          <Typography name={TYPOGRAPHY_NAMES.modalHeader}>{t("prod_365_conf_title")}</Typography>
          <Box pt={20} width="100%" textAlign="left">
            <RadioField
              type="button"
              name={YES}
              width="100%"
              checked={choice === YES}
              onChange={handleChoice(YES)}
              label={t("prod_365_conf_opt1")}
            />
          </Box>
          <Box pt={20} width="100%" textAlign="left">
            <RadioField
              type="button"
              name={NO}
              width="100%"
              checked={choice === NO}
              onChange={handleChoice(NO)}
              label={t("prod_365_conf_opt2")}
            />
          </Box>
          <Box pt={40} width="100%">
            <Button size={BUTTON_SIZE.maxWidth} onClick={handleAccept} disabled={disabled}>
              {t("prod_btn_next")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Outlet />
    </>
  );
};
