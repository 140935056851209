export const PausedIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="40" fill="#F5F6FF" />
    <circle cx="40" cy="40" r="12" fill="#1E2BA6" />
    <rect x="36" y="35" width="2" height="10" fill="white" />
    <rect x="42" y="35" width="2" height="10" fill="white" />
  </svg>
);
