export const RightIcon = ({ disabled }: { disabled: boolean }) => (
  <svg
    style={{ opacity: disabled ? 0.4 : 1 }}
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.316406 17.65L7.94974 10L0.316406 2.35L2.66641 0L12.6664 10L2.66641 20L0.316406 17.65Z"
      fill="#77797B"
    />
  </svg>
);
