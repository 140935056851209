import { useQuery } from "react-query";
import { getInstallationProgress } from "../requests/getInstallationProgress";
import { Device } from "../../../types/Platform";

type InstallationProgress = {
  Overall: number;
  Desktop: Device[];
  Mobile: Device[];
};

export const useGetInstallationProgress = () => {
  const { refetch, data, isSuccess, isLoading } = useQuery("getInstallationProgress", getInstallationProgress, {
    refetchOnWindowFocus: true,
    retry: true,
  });
  const installationProgress: InstallationProgress = {
    Overall: data?.data.Overall ?? 0,
    Desktop: [
      {
        name: "Windows",
        all: data?.data.WindowsOS ?? 0,
        success: data?.data.WindowsOSSuccess ?? 0,
        failure: data?.data.WindowsOSFailures ?? 0,
      },
      {
        name: "MacOS",
        all: data?.data.MacOS ?? 0,
        success: data?.data.MacOSSuccess ?? 0,
        failure: data?.data.MacOSFailures ?? 0,
      },
      {
        name: "Linux",
        all: data?.data.LinuxOS ?? 0,
        success: data?.data.LinuxOSSuccess ?? 0,
        failure: data?.data.LinuxOSFailures ?? 0,
      }
    ],
    Mobile: [
      {
        name: "iOS",
        all: data?.data.IOS ?? 0,
        success: data?.data.IOSSuccess ?? 0,
        failure: data?.data.IOSFailures ?? 0,
      },
      {
        name: "Android",
        all: data?.data.AOS ?? 0,
        success: data?.data.AOSSuccess ?? 0,
        failure: data?.data.AOSFailures ?? 0,
      }
    ],
  };

  return {
    installationProgress,
    refetchInstallationProgress: refetch,
    isLoadingInstallationProgress: isLoading,
    isSuccessInstallationProgress: isSuccess
  };
};
