import styled from "styled-components";
import { Box } from "../../components";

export const Page = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 60px 0 40px;
`

export const Motivation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-right: 60px;
  background: ${({ theme }) => theme.palette.thirdWarning2Light};
  border-radius: 8px;
`;

export const Updates = styled(Box)`
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  min-height: 355px;
`;

export const Table = styled.div`
  margin-top: 20px;
  min-width: 392px;
`;

export const TableRow = styled.div`
  box-sizing: border-box;
  width: 392px;
  padding: 8px 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  background: ${({ theme }) => theme.palette.primaryBasic};
  border-top: none;

  > div {
    flex: 1 1 196px;
  }
`;

export const TableHeader = styled(TableRow)`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: ${({ theme }) => theme.palette.secondaryNeutralDark};
  background: #f8f8f8;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  border-top: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
`;
