import { css } from "styled-components";

export const Av = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 24px;
  padding: 38px 17px;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  border-radius: 4px;
  position: relative;

  & input[type="radio"] {
    position: absolute;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  & input[type="radio"]:checked {
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.palette.primaryMain};
  }

  & svg + label {
    display: none;
  }
`;
