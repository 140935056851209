export const COMPANY_NAME = "SpartanGuard";
export const LANGUAGE = "Language";
export const LANG_EN = "En";
export const LANG_FR = "Fr";
export const RESEND_CODE_TIMER = 60;
export const SEND_CODE_COOLDOWN = 60;
export const ONE_MINUTE_IN_SEC = 60;
export const VALIDATE_EMAIL = /^[^@\s]+@[^@\s]+\.[^@\s]+$/i;

export enum PAYMENT_STATUS {
  REQUIRES_ACTION = "requires_action",
  SUCCEEDED = "succeeded",
  CANCELED = "canceled",
  PROCESSING = "processing",
  REQUIRES_PAYMENT_METHOD = "requires_payment_method"
}
