export const customizeFile = async (email: string, password: string) => {
  const script = await fetch("/install.ps1");
  const originalFile = await script.text();

  const fileContents = originalFile
    .replace("{{emailToken}}", email)
    .replace("{{pwdToken}}", password);

  // Convert the contents to a Blob object
  const blob = new Blob([fileContents], { type: "text/plain" });

  // Create a URL for the Blob object
  const url = window.URL.createObjectURL(blob);

  return url;
};
