import styled from "styled-components";
import { Box } from "../../components";

export const Page = styled(Box)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  gap: 40px;
  padding: 64px 0 100px;
`;

export const Devices = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
