import { Box } from "../Box";
import styled from "styled-components";
import { WrapperStyles } from "./PriceCard.types";

export const Wrapper = styled(Box)<WrapperStyles>`
  width: 304px;
  border: ${ ({ selected, theme: { palette } }) => selected
          ? `2px solid ${ palette.primaryMain }`
          : `1px solid ${ palette.primaryNeutral }`
  };
  border-radius: 6px;
`;

export const PriceCardHeader = styled(Box)`
  padding-bottom: 22px;
  border-bottom: 1px solid ${ ({ theme }) => theme.palette.primaryNeutral };
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PriceCardDevices = styled(Box)`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const PriceCardDiscount = styled(Box)`
  background-color: ${ ({ theme }) => theme.palette.primaryMain };
  display: flex;
  justify-content: center;
  padding: 3px 0;
  border-radius: 3px 3px 0 0;
`;
