import { useTheme } from "styled-components";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { CheckedIcon } from "../../assets/icons";
import { StyledCheckbox, StyledLabel } from "./Checkbox.styles";
import { CHECKBOX_TYPES, ICheckboxProps } from "./Checkbox.types";

const getTypographyName = (checkboxType: CHECKBOX_TYPES) => {
  switch (checkboxType) {
    case CHECKBOX_TYPES.emphasised:
      return TYPOGRAPHY_NAMES.body20;
    case CHECKBOX_TYPES.light:
      return TYPOGRAPHY_NAMES.body16Light;
    default:
      return TYPOGRAPHY_NAMES.body16;
  }
};

export const Checkbox = ({
  id,
  name,
  label,
  checked,
  disabled = false,
  onChange,
  checkboxType = CHECKBOX_TYPES.normal
}: ICheckboxProps) => {
  const theme = useTheme();

  const getSize = (checkboxType: CHECKBOX_TYPES) => {
    switch (checkboxType) {
      case CHECKBOX_TYPES.emphasised:
        return theme.sizes.checkbox.emphasised;
      default:
        return theme.sizes.checkbox.normal;
    }
  };

  const handleChange = () => {
    if (!disabled) onChange();
  };

  return (
    <StyledLabel htmlFor={id} disabled={disabled} checked={checked}>
      <StyledCheckbox
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        checkboxType={checkboxType}
      />
      <CheckedIcon size={getSize(checkboxType)} color={checked ? "white" : "none"} />
      {label && (
        <Typography
          name={getTypographyName(checkboxType)}
          isUnderline={checkboxType === CHECKBOX_TYPES.emphasised}>
          {label}
        </Typography>
      )}
    </StyledLabel>
  );
};
