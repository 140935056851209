import { TextInput } from "../TextInput";
import { FormFieldError } from "../FormFieldError";
import { Control, Controller, ValidationRule } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DetailsInputs } from "../../pages/DetailsPage/DetailsPage.types";
import { Box } from "../Box";
import { InputFieldProps } from "../TextInput/TextInput.types";

interface IFormController extends InputFieldProps {
  control: Control<DetailsInputs, any>;
  name: any;
  rules: Record<string, ValidationRule<any>>;
}

export const FormController = ({
     name,
     control,
     rules,
     label,
     placeholder,
     type,
     defaultValue
   }: IFormController) => {
  const { t } = useTranslation();
  const { required } = rules

  return (
    <Controller
      name={ name }
      control={ control }
      rules={ rules }
      defaultValue={ defaultValue }
      render={ ({ field, fieldState }) => (
        <Box display={ "flex" } flexDirection={ "column" } gap={ 8 }>
          <TextInput
            mt={ 20 }
            width="100%"
            type={ type }
            label={ label ? `${t(label)}${required ? "*" : ""}` : "" }
            placeholder={ placeholder ? t(placeholder) : "" }
            { ...field }
          />
          {
            rules.minLength
            ? <FormFieldError fieldState={ fieldState } minLength={ rules.minLength } />
            : <FormFieldError fieldState={ fieldState } />
          }
        </Box>
      ) }
    />
  );
};
