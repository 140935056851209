import { useCallback, useState } from "react";

export const useControlFields = (defaults?: Record<string, any>) => {
  const [fields, setFields] = useState<Record<string, any>>(defaults ?? {});

  const updateFilter = useCallback(
    (name: string) => (value: any) => {
      setFields((prev: any) => ({
        ...prev,
        [name]: value
      }));
    },
    []
  );

  const setCheckbox = useCallback(
    (name: string) => (value: boolean) => {
      updateFilter(name)(value);
    },
    []
  );

  const setInput = useCallback(
    (name: string) => (e: React.FormEvent<HTMLInputElement>) => {
      const value = (e.target as HTMLInputElement).value;
      updateFilter(name)(value);
    },
    []
  );

  const setValue = useCallback((name: string, value: string) => {
    updateFilter(name)(value);
  }, []);

  const setRadio = useCallback(
    (name: string) => (value: string) => {
      updateFilter(name)(value);
    },
    []
  );

  return {
    fields,
    setCheckbox,
    setInput,
    setValue,
    setRadio
  };
};
