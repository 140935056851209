import { useTranslation } from "react-i18next";
import { AdditionalLogoEn, AdditionalLogoFr } from "../../assets/icons";
import { LANG_EN, LANG_FR } from "../../constants";

export const AdditionalLogo = ({ defaultSize = true }: { defaultSize?: boolean }) => {
  const { i18n } = useTranslation();

  const lang = i18n.language === LANG_FR.toLowerCase() ? LANG_FR : LANG_EN;

  switch (lang) {
    case LANG_FR:
      return <AdditionalLogoFr defaultSize={defaultSize} />;
    default:
      return <AdditionalLogoEn defaultSize={defaultSize} />;
  }
};
