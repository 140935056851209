export enum CARD {
  poor = "poor",
  average = "average",
  excellent = "excellent"
}

export enum CARD_MESSAGE {
  poor = "prod_corp_sec_score_status_poor_lbl",
  average = "prod_corp_sec_score_status_avg_lbl",
  excellent = "prod_corp_sec_score_status_exc_lbl",
}
