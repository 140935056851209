import styled, { css } from "styled-components";
import { ICheckboxProps } from "./Checkbox.types";

export const StyledCheckbox = styled.input<Pick<ICheckboxProps, "checkboxType">>`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const DefaultStyles = css<{ checked: boolean }>`
  & > svg {
    background: ${({ theme, checked }) =>
      checked ? theme.palette.primaryMain : theme.palette.primaryBasic};
    border: 2px
      ${({ theme, checked }) =>
        checked ? theme.palette.primaryMain : theme.palette.secondaryNeutralDark}
      solid;
  }
  & > svg:hover {
    border-color: ${({ theme }) => theme.palette.primaryMain};
  }
`;

const DisabledStyles = css<{ checked: boolean }>`
  & > svg {
    background: ${({ theme, checked }) =>
      checked ? theme.palette.primaryNeutral : theme.palette.primaryBasic};
    border: 2px ${({ theme }) => theme.palette.primaryNeutral} solid;
  }
  & > svg:hover {
    border-color: ${({ theme }) => theme.palette.primaryNeutral};
  }
`;

export const StyledLabel = styled.label<Pick<ICheckboxProps, "disabled" | "checked">>`
  display: flex;
  align-items: center;
  & > svg {
    display: inline-block;
    border-radius: 2px;
    margin-right: 12px;
  }
  ${({ disabled }) => (disabled ? DisabledStyles : DefaultStyles)}
`;
