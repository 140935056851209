import styled from "styled-components";
import { Box, Typography } from "../../components";

export const DetailsBox = styled(Box)`
  flex: 1;
`;

export const HorizontalBox = styled(Box)`
  gap: 32px;
  & > * {
    flex-grow: 1;
  }
`;

export const Section = styled(Box)`
  background-color: ${({ theme }) => theme.palette.thirdBasicLight};
  border-radius: 6px;
  span {
    padding-left: 24px;
  }
`;

export const Subscription = styled(Box)`
  background-color: ${({ theme }) => theme.palette.thirdBasicLight};
  border-radius: 7px;
`;

export const Discount = styled(Typography)`
  color: ${({ theme }) => theme.palette.primaryMain};
`;

export const Page = styled.div`
  margin: auto;
  max-width: 1000px;
`
