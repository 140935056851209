import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, BUTTON_SIZE, BUTTON_TYPE, Typography, TYPOGRAPHY_NAMES } from "../../components";
import { useTranslation } from "react-i18next";
import { CheckedIcon } from "../../assets";
import { PriceCardDevices, PriceCardDiscount, PriceCardHeader, Wrapper } from "./PriceCard.styles";
import { useTheme } from "styled-components";
import { PaymentContext } from "../../context/PaymentContext";
import { useMapState } from "../../hooks";
import { PriceCardRecord } from "./PriceCardRecord";
import { IPriceCardProps } from "./PriceCard.types";
import { IPriceOption, PricePeriodName } from "../../api/order/requests/getPricingDetails";

const defaultOptions = (name: string, criteria: boolean, options: IPriceOption[]) => {
  return criteria ? options.find(option => !option.Paid && option.Name === name)?.Value ?? 0 : 0;
};

export const PriceCard = ({
    PricePeriod,
    NumberOfDevices,
    Taxes,
    PerDevicePrice,
    Discount,
    Options
  }: IPriceCardProps) => {
  const { t } = useTranslation();
  const {
    period,
    updateContext,
    updates,
    vulnerabilities,
  } = useContext(PaymentContext);
  const [selected, setSelected] = useState<boolean>(period === PricePeriod);
  const [sum, setSum] = useState<number>(0);
  const [fullPrice, setFullPrice] = useState<number>(0);
  const { entities, updateEntities } = useMapState({
    Base: { state: true, value: defaultOptions("Base", true, Options) },
    Licenses: { state: true, value: defaultOptions("Licenses", true, Options) },
    Updates: { state: updates, value: defaultOptions("Updates", updates, Options) },
    Vulnerabilities: { state: vulnerabilities, value: defaultOptions("Vulnerabilities", vulnerabilities, Options) }
  });

  const theme = useTheme();

  const handleCheckBox = useCallback((name: string, value: number) => {
    updateEntities(name)(value);
  }, [entities]);

  const calcSum = useMemo(() => {
    setSum(Object.values(entities).reduce((acc, { value }) => acc + value, 0));
  }, [entities, Options]);

  const calcFullPrice = useMemo(() => {
    if (PricePeriod === PricePeriodName.year) {
      setFullPrice(sum / (1 - Discount/100))
    }
  }, [sum])

  const handleSelect = useCallback(() => {
    updateContext({
      period: PricePeriod,
      subtotal: sum,
      updates: entities.Updates.state,
      vulnerabilities: entities.Vulnerabilities.state,
      taxes: Taxes,
      discount: Discount
    });
  }, [PricePeriod, sum]);

  useEffect(() => {
    setSelected(period === PricePeriod);
  }, [period]);

  useEffect(() => calcSum, [entities]);

  useEffect(() => {
    if (selected) {
      updateContext({
        subtotal: sum,
        updates: entities.Updates.state,
        vulnerabilities: entities.Vulnerabilities.state
      });
    }
  }, [sum]);

  return (
    <Wrapper selected={ selected }>
      {
        Boolean(Discount) &&
        <PriceCardDiscount>
          <Typography name={ TYPOGRAPHY_NAMES.body14Bold } color={ "primaryBasic" }>
            { t("prod_pricing_discount_lbl") } -{ Discount }%
          </Typography>
        </PriceCardDiscount>

      }
      <Box display={ "flex" } flexDirection={ "column" } gap={ 20 } padding={ [26, 20] }>
        <PriceCardHeader>
          <Typography name={ TYPOGRAPHY_NAMES.H2 }>
            { t(`prod_pricing_${ PricePeriod }_lbl`) }
          </Typography>
          <Box>
            <Typography name={ TYPOGRAPHY_NAMES.body16Bold }>
              { PerDevicePrice.toFixed(2) }
            </Typography>
            <Typography name={ TYPOGRAPHY_NAMES.body14Light }>
              &nbsp;{ t("prod_pricing_perdevice_lbl") }
            </Typography>
          </Box>
        </PriceCardHeader>
        <PriceCardDevices>
          <Typography name={ TYPOGRAPHY_NAMES.body16Bold }>
            { t("prod_pricing_qty_outline_lbl") }
            &nbsp;{ NumberOfDevices }&nbsp;
            { t("prod_pricing_qty_outline_lbl2") }
          </Typography>
          { entities &&
            Options.filter(({ Paid }) => !Paid).map(({ Name, Mandatory, Value }) => (
              <PriceCardRecord
                key={ Name }
                name={ Name }
                period={ PricePeriod }
                disabled={ Mandatory }
                entities={ entities }
                cb={ handleCheckBox }
                value={ Value }
                label={ `prod_pricing_${ Name }_lbl` } />
            ))
          }
        </PriceCardDevices>
        <Box display={"flex"} gap={10} alignItems={"baseline"}>
          <Typography name={ TYPOGRAPHY_NAMES.Accent }>
            ${ sum.toFixed(2) }
          </Typography>
          {
            PricePeriod === PricePeriodName.year && fullPrice > 0 &&
            <Typography name={TYPOGRAPHY_NAMES.body16Bold} color={"secondaryNeutralDark"} isCrossed={true}>
              ${ fullPrice.toFixed(2) }
            </Typography>
          }
        </Box>
        <Button
          disabled={sum === 0}
          buttonType={ BUTTON_TYPE.secondary }
          size={ BUTTON_SIZE.maxWidth }
          onClick={ handleSelect }
          leftIcon={ selected && <CheckedIcon color={ theme.palette.secondaryMainDark } /> }
        >
          { t("prod_pricing_plan_lbl") }
        </Button>
        <Typography name={ TYPOGRAPHY_NAMES.body12Light }>
          { t("prod_pricing_Windows_lbl") }
        </Typography>
      </Box>
    </Wrapper>
  );
};
