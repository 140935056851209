export const WrongIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#F4F4F5" />
    <circle cx="40" cy="40" r="12" fill="#77797B" />
    <g clip-path="url(#clip0_429_3151)">
      <path
        d="M40 36V40"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-Linejoin="round"
      />
      <path
        d="M40 44H40.01"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_429_3151">
        <rect width="24" height="24" fill="white" transform="translate(28 28)" />
      </clipPath>
    </defs>
  </svg>
);
