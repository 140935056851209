import { useTranslation } from "react-i18next";
import { Box, Button, Modal, Typography, TYPOGRAPHY_NAMES } from "../../components";
import { WrongIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export const ErrorPage = ({ message }: { message?: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReject = useCallback(() => navigate(0), [message]);

  return (
    <Modal width={ 480 } centered showCloseIcon onClose={ handleReject }>
      <WrongIcon />
      <Box pt={ 28 } display={ "flex" } flexDirection={ "column" } gap={ 12 }>
        <Typography name={ TYPOGRAPHY_NAMES.modalHeader }>
          { message ? t(message) : t("prod_warning")}
        </Typography>
        <Typography name={ TYPOGRAPHY_NAMES.body16Light }>
          { t("prod_warning_not1") }
        </Typography>
        <Box width="100%" pt={ 12 }>
          <Button onClick={ handleReject }>
            { t("prod_warning_not2") }
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
