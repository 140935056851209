import {
  Box,
  Button,
  BUTTON_ROLE,
  BUTTON_SIZE,
  BUTTON_TYPE,
  InstallationCard,
  Loader,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Devices, Page } from "./InstallationPage.styles";
import { EyeIcon } from "../../assets";
import { useGetInstallationProgress } from "../../api";
import { Device } from "../../types/Platform";
import { useNavigate } from "react-router-dom";

export const InstallationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { installationProgress, isLoadingInstallationProgress } = useGetInstallationProgress();

  const calculateTotalProgress = useMemo(() => {
    if (installationProgress) {
      const { Desktop, Mobile } = installationProgress;
      const failedDesktop = Desktop.reduce((prev, next) => prev + next.success, 0);
      const failedMobile = Mobile.reduce((prev, next) => prev + next.success, 0);
      return Math.floor(((failedDesktop + failedMobile) / installationProgress.Overall) * 100);
    }
  }, [installationProgress]);

  const handleButton = useCallback(() => navigate("/home"), []);

  if (isLoadingInstallationProgress) return <Loader />;

  return (
    <Page>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"column"} gap={16}>
          <Box display={"flex"} alignItems={"center"} gap={8}>
            <Typography name={TYPOGRAPHY_NAMES.H1}>
              {t("prod_installoverview_installation_lbl")}
            </Typography>
            <Typography name={TYPOGRAPHY_NAMES.H1} color={"primaryMain"}>
              {Boolean(calculateTotalProgress) ? `${calculateTotalProgress}%` : 0}
            </Typography>
            <Typography name={TYPOGRAPHY_NAMES.body16} color={"primaryMain"}>
              {t("prod_installoverview_installation2_lbl")}
            </Typography>
          </Box>
          <Typography name={TYPOGRAPHY_NAMES.body16}>
            {t("prod_installoverview_progress_lbl")}
          </Typography>
        </Box>
        <Button
          buttonType={BUTTON_TYPE.secondary}
          role={BUTTON_ROLE.button}
          size={BUTTON_SIZE.fitContent}
          onClick={handleButton}
          leftIcon={<EyeIcon />}>
          {t("prod_btn_watchscore")}
        </Button>
      </Box>
      <Devices>
        <Typography name={TYPOGRAPHY_NAMES.body16}>
          {t("prod_installoverview_laptops_lbl")}
        </Typography>
        {installationProgress.Desktop.map((el: Device) => (
          <InstallationCard
            key={el.name}
            name={el.name}
            width="90%"
            total={el.all}
            complete={el.success}
            failure={el.failure}
          />
        ))}
      </Devices>
      <Devices>
        <Typography name={TYPOGRAPHY_NAMES.body16}>
          {t("prod_installoverview_mobiles_lbl")}
        </Typography>
        {installationProgress.Mobile.map((device: Device) => (
          <InstallationCard
            key={device.name}
            name={device.name}
            width={"90%"}
            total={device.all}
            complete={device.success}
            failure={device.failure}
          />
        ))}
      </Devices>
    </Page>
  );
};
