import styled, { css } from "styled-components";
import { BUTTON_SIZE, BUTTON_TYPE, IButtonProps } from "./Button.types";

const primaryStyles = css`
  background-color: ${({ theme }) => theme.palette.primaryMain};
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.palette.primaryBasic};
  }

  svg > * {
    stroke: ${({ theme }) => theme.palette.primaryBasic};
  }

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.palette.secondaryMainDark};
    border-color: ${({ theme }) => theme.palette.secondaryMainDark};
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.palette.thirdNeutralLight};
    border-color: ${({ theme }) => theme.palette.thirdNeutralLight};

    span {
      color: ${({ theme }) => theme.palette.primaryNeutral};
    }
  }
`;

const secondaryStyles = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primaryBasic};

  span {
    color: ${({ theme }) => theme.palette.primaryMain};
  }

  svg > * {
    stroke: ${({ theme }) => theme.palette.primaryMain};
  }

  &:hover,
  &:active {
    border-color: ${({ theme }) => theme.palette.secondaryMainDark};

    span {
      color: ${({ theme }) => theme.palette.secondaryMainDark};
    }

    svg > * {
      stroke: ${({ theme }) => theme.palette.secondaryMainDark};
    }
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.palette.thirdNeutralLight};
    border-color: ${({ theme }) => theme.palette.primaryNeutral};

    span {
      color: ${({ theme }) => theme.palette.primaryNeutral};
    }

    svg > * {
      stroke: ${({ theme }) => theme.palette.primaryNeutral};
    }
  }
`;

const ghostStyles = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primaryBasic};
  border: 0;

  span {
    color: ${({ theme }) => theme.palette.primaryMain};
  }

  svg > * {
    stroke: ${({ theme }) => theme.palette.primaryMain};
  }

  &:hover,
  &:active {
    span {
      color: ${({ theme }) => theme.palette.secondaryMainDark};
    }

    svg > * {
      stroke: ${({ theme }) => theme.palette.secondaryMainDark};
    }
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.palette.thirdNeutralLight};
    border-color: ${({ theme }) => theme.palette.primaryNeutral};

    span {
      color: ${({ theme }) => theme.palette.primaryNeutral};
    }

    svg > * {
      stroke: ${({ theme }) => theme.palette.primaryNeutral};
    }
  }
`;

const fatStyles = css`
  background: ${({ theme }) => theme.palette.gradientButton};
  cursor: pointer;
  span {
    color: ${({ theme }) => theme.palette.primaryBasic};
  }

  box-shadow: 0 4px 7px rgba(79, 0, 73, 0.2);
`;

const iconStyles = css`
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.thirdNeutralLight};
  border-color: ${({ theme }) => theme.palette.thirdNeutralLight};
  cursor: pointer;

  svg > * {
    stroke: ${({ theme }) => theme.palette.primaryMain};
  }

  &:hover,
  &:active {
    svg > * {
      stroke: ${({ theme }) => theme.palette.secondaryMainDark};
    }
  }

  &:disabled {
    cursor: default;

    svg > * {
      stroke: ${({ theme }) => theme.palette.primaryNeutral};
    }
  }
`;

const centeredStyles = css`
  justify-content: center;
`;

export const StyledButton = styled.button<IButtonProps>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.primaryMain};
  height: 40px;
  margin-left: ${({ ml }) => ml && `${ml}px`};
  width: ${({ size, theme, buttonType }) =>
    buttonType === BUTTON_TYPE.icon ? "40px" : theme.sizes.button[size || BUTTON_SIZE.medium]};
  ${({ buttonType }) => buttonType === BUTTON_TYPE.primary && primaryStyles};
  ${({ buttonType }) => buttonType === BUTTON_TYPE.secondary && secondaryStyles};
  ${({ buttonType }) => buttonType === BUTTON_TYPE.ghost && ghostStyles};
  ${({ buttonType }) => buttonType === BUTTON_TYPE.fat && fatStyles};
  ${({ buttonType }) => buttonType === BUTTON_TYPE.icon && iconStyles};
  ${({ centered }) => centered && centeredStyles};
  & > * {
    margin: 0 8px;
  }
`;
