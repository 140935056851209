import { useTranslation } from "react-i18next";
import { AdditionalLogo } from "../../AdditionalLogo";
import { Box } from "../../Box";
import { Typography, TYPOGRAPHY_NAMES } from "../../Typography";
import { Link, Wrapper } from "./Footer.styles";
import { Line } from "../../Line";

export const Footer = () => {
  const { t } = useTranslation();

  // TO-DO: прекрутить реакт роутер в6 на линки Privacy Policy и Terms of use
  return (
    <Box mt={32}>
      <Line />
      <Wrapper>
        <Box display="flex" alignItems="center" mr={30} gap={5}>
          <AdditionalLogo />
          <Typography name={TYPOGRAPHY_NAMES.body10} color="secondaryNeutralDark">
            {t("corp_rights_lbl")}
          </Typography>
        </Box>
        <Box display="flex" gap={20} width={170}>
          <Link name={TYPOGRAPHY_NAMES.body12} color="secondaryMainDark">
            {t("corp_privacy_lbl")}
          </Link>
          <Link name={TYPOGRAPHY_NAMES.body12} color="secondaryMainDark">
            {t("corp_terms_lbl")}
          </Link>
        </Box>
      </Wrapper>
    </Box>
  );
};
