import { Hint } from "../Common";
import { ErrorTypes, IFormFieldError } from "./FormFieldError.types";
import { useTranslation } from "react-i18next";

export const FormFieldError = ({ fieldState, minLength, customError }: IFormFieldError) => {
  const { t } = useTranslation();
  return (
    <>
      {
        fieldState?.error?.type === ErrorTypes.required && !customError &&
        <Hint type={ "error" } text={ t("prod_required") } />
      }
      {
        fieldState?.error?.type === ErrorTypes.minLength &&
        <Hint type={ "error" } text={ t("prod_No_characters", {minLength: minLength}) } />
      }
      {
        fieldState?.error?.type === ErrorTypes.maxLength &&
        <Hint type={ "error" } text={ `Field can have maximum 160 characters`} />
      }
      {
        fieldState?.error?.type === ErrorTypes.pattern &&
        <Hint type={ "error" } text={ t("prod_ex_email") } />
      }
      {
        customError?.display && fieldState?.isTouched &&
        <Hint type={ "error" } text={ t("prod_worning", {customErrorText: customError.text}) } />
      }
    </>
  );
};
