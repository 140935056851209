import { Code404, Page, Text404 } from "./Page404.styles";

export const Page404 = () => {
  return (
    <Page>
      <Code404>404</Code404>
      <Text404>Page not found</Text404>
    </Page>
  );
};
