import { useQuery } from "react-query";
import { getStatus } from "../requests/getStatus";
import { useContext } from "react";
import { PermissionsContext } from "../../../context/PermissionsContext";
import { AccessLevels } from "../../../routes/routes.types";

export const useGetStatus = () => {
  const { updateContext } = useContext(PermissionsContext);
  const { data, refetch, isSuccess, isLoading, isError } = useQuery("getStatus", getStatus, {
    onSuccess: (data) => {
      const id = data.data.PageID;
      updateContext({
        pageId: id,
        permissionsGranted:  id > AccessLevels.MsPermissionsNeeded,
      });
    },
    onError: error => error,
  });
  return {
    pageId: data?.data.PageID,
    email: data?.data.Email,
    getClientStatus: refetch,
    clientStatusIsSuccess: isSuccess,
    clientStatusIsLoading: isLoading,
    clientStatusIsError: isError,
  };
};
