import { IGuard } from "./routes.types";
import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetStatus } from "../api";
import { Loader } from "../components";

export const RouteGuard = ({ guard, children, redirect }: IGuard) => {
  const [route, setRoute] = useState<JSX.Element>(<></>);
  const { clientStatusIsLoading, getClientStatus } = useGetStatus();
  const location = useLocation();

  useEffect(() => {
    getClientStatus();
  }, [location]);

  useEffect(() => {
    if (!clientStatusIsLoading) {
      setRoute(guard ? children : <Navigate to={ redirect } />)
    }
  }, [clientStatusIsLoading, children]);

  return (
    <>
      { clientStatusIsLoading && <Loader /> }
      { !clientStatusIsLoading && route }
    </>
  );
};
