export const WindowsIcon = ({width}: {width: string}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4875 4875"
    width={ width }
    height="auto">
    <path
      fill="#0078d4"
      d="M0 0h2311v2310H0zm2564 0h2311v2310H2564zM0 2564h2311v2311H0zm2564 0h2311v2311H2564"
    />
  </svg>
)
