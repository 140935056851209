import { createContext } from "react";

export interface IPermissions {
  language: string;
  permissionsGranted: boolean;
  pageId: number;
}

export const permissionsDefault: IPermissions = {
  language: localStorage.getItem("Language") || "",
  permissionsGranted: false,
  pageId: -1,
};

export const PermissionsContext = createContext({
  ...permissionsDefault,
  updateContext: (value: Partial<IPermissions>) => {}
});
