import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { MicrosoftIcon } from "../../assets";
import {
  Box,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Loader,
  Modal,
  TextInput,
  Typography,
  TYPOGRAPHY_COMPONENTS,
  TYPOGRAPHY_NAMES
} from "../../components";
import { useControlFields } from "../../hooks";

export const UserLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fields, setInput } = useControlFields();
  const { state } = useLocation();

  const handleReject = useCallback(() => navigate("/"), []);
  const handleSubmit = useCallback(
    () =>
      navigate("/user/finishSetup", { state: { mail: state?.mail, password: fields.password } }),
    [fields.password, navigate, state?.mail]
  );

  // TO-DO: loader
  if (false) {
    return <Loader text={t("prod_please_wait_text")} />;
  }

  return (
    <Modal width={620} height={650} centered={false} showCompanyInfo>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.modalHeader}>
              {t("prod_dev_setup_ms_title")}
            </Typography>
            <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.body16Light}>
              {t("prod_dev_setup_credentials_title")}
            </Typography>
          </Box>
          <Box width={105} height={45}>
            <MicrosoftIcon />
          </Box>
        </Box>
        <Box pt={40} width="100%">
          <TextInput
            label={t("prod_email_field_lbl")}
            placeholder={t("prod_email_field_hint")}
            value={state?.mail}
            disabled
          />
        </Box>
        <Box pt={20} width="100%">
          <TextInput
            label={t("prod_dev_setup_pwd_lbl")}
            placeholder={t("prod_otp_conf_password_hint")}
            type="password"
            value={fields.password}
            onChange={setInput("password")}
          />
        </Box>
        <Box mt={40} width="100%">
          <Button size={BUTTON_SIZE.maxWidth} onClick={handleSubmit}>
            {t("prod_btn_next")}
          </Button>
        </Box>
        <Box mt={20} width="100%">
          <Button
            size={BUTTON_SIZE.maxWidth}
            buttonType={BUTTON_TYPE.secondary}
            onClick={handleReject}>
            {t("prod_btn_cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
