import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ClockIcon } from "../../assets/icons";
import { Box, Button, Modal, Typography, TYPOGRAPHY_NAMES } from "../../components";

export const RequiredMSAccount = () => {
  const { t } = useTranslation();

  const handleReject = useCallback(() => window.location.replace(t("prod_url")), [t]);

  return (
    <Modal width={480} centered showCloseIcon onClose={handleReject}>
      <ClockIcon />
      <Typography name={TYPOGRAPHY_NAMES.modalHeader}>{t("prod_365_conf_error_title")}</Typography>
      <Box pt={12}>
        <Typography name={TYPOGRAPHY_NAMES.body16Light}>{t("prod_365_conf_error_text")}</Typography>
      </Box>
      <Box width="100%" pt={12}>
        <Button onClick={handleReject}>{t("prod_btn_ok")}</Button>
      </Box>
    </Modal>
  );
};
