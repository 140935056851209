export const MainLogoFr = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="60" fill="none" viewBox="0 0 150 60">
    <path
      fill="#95268D"
      d="M19.608 49.62c10.83 0 19.608-8.785 19.608-19.62 0-10.836-8.779-19.62-19.608-19.62C8.778 10.38 0 19.164 0 30c0 10.835 8.779 19.62 19.608 19.62z"></path>
    <path
      fill="#95268D"
      d="M22.513 47.197c9.493 0 17.188-7.7 17.188-17.198 0-9.498-7.695-17.197-17.188-17.197-9.492 0-17.187 7.7-17.187 17.197 0 9.498 7.695 17.198 17.187 17.198z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5132 13.9664C13.6644 13.9664 6.48987 21.1443 6.48987 30C6.48987 38.8558 13.6644 46.0336 22.5132 46.0336C31.3621 46.0336 38.5366 38.8558 38.5366 30C38.5366 21.1443 31.3621 13.9664 22.5132 13.9664ZM4.16211 30C4.16211 19.86 12.3775 11.6387 22.5132 11.6387C32.6489 11.6387 40.8644 19.86 40.8644 30C40.8644 40.1401 32.6489 48.3614 22.5132 48.3614C12.3775 48.3614 4.16211 40.1401 4.16211 30Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.2354 45.5991C26.3714 44.761 24.6527 44.015 22.9558 43.2205C22.1085 42.8209 21.283 42.3631 20.4769 41.8811C19.6708 41.399 19.6757 41.1205 20.0509 40.2945C20.3533 39.6652 20.5784 39.0015 20.7214 38.318C20.7844 37.9814 20.627 37.4 20.3801 37.2595C20.092 37.0948 19.5256 37.1699 19.2085 37.3589C16.5287 38.926 13.5826 39.0544 10.3945 39.1246C11.2176 38.0685 11.9293 37.153 12.6507 36.2422C13.1348 35.6391 13.5948 35.0311 14.1176 34.4595C14.413 34.1398 14.4105 33.8806 14.2798 33.4906C13.4059 30.9013 13.4326 28.3629 14.8995 25.9819C16.6788 23.0753 19.5691 22.1839 22.6604 22.4867C27.652 22.9711 29.9711 27.6168 30.017 31.1944C30.017 32.1972 30.017 33.2896 29.9565 34.29C29.9202 34.8422 29.4579 35.0166 29.1165 34.6121C28.8156 34.2852 28.4662 34.0066 28.0805 33.7861C27.6189 33.9749 27.175 34.2043 26.7539 34.4716C26.3666 34.7526 25.9381 34.9803 25.3595 35.3339C25.9115 35.5761 27.2138 35.9613 27.2138 35.9613C27.1351 36.0888 27.0702 36.2245 27.0202 36.3658C26.536 38.7686 27.1122 41.0721 27.6399 43.3901C27.7972 44.0804 28.0054 44.761 28.2354 45.6136"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.92725 35.3286C5.63434 33.9818 5.12841 32.8483 4.95895 31.4894C4.69716 29.3087 4.8541 27.098 5.42132 24.9762C5.6634 24.0751 6.06282 23.6658 6.7963 23.1523C7.7646 22.4789 9.83433 21.8709 11.8314 22.0284C11.1052 21.3914 9.63583 21.3526 6.74788 21.7595C9.65277 14.958 19.2389 11.4217 25.1867 12.3833C23.9279 13.8511 22.9862 15.7065 22.9136 17.6757C22.9862 17.5256 23.8311 15.2535 24.4532 14.3186C24.8457 13.7307 25.4165 13.2845 26.0815 13.0455C26.7465 12.8066 27.4706 12.7875 28.1472 12.9912C28.9388 13.2334 29.6553 13.638 30.5873 13.9795C30.2823 14.1006 30.1395 14.1636 29.9918 14.2217C27.2177 15.1155 25.4941 17.0048 24.7703 19.7927C24.6444 20.2771 24.4943 20.3377 24.0441 20.219C21.403 19.5432 18.7814 19.4924 16.3171 20.8415C13.4993 22.3844 11.9041 24.7994 11.5095 27.9918C11.4928 28.1964 11.4871 28.4018 11.4925 28.607C11.4925 28.607 11.127 28.9098 10.9285 29.0479C8.99192 30.4115 7.67019 32.2936 6.63169 34.3912C6.49855 34.6601 6.38962 34.9338 6.24921 35.2026C6.23469 35.2365 6.19595 35.2608 6.1669 35.2874L5.92483 35.3286"
      fill="white"
    />
    <path
      fill="#95268D"
      d="M53.908 34.703c0 .99-.497 1.483-1.49 1.483H47.31c-.964 0-1.447-.494-1.447-1.483v-13.22c0-.989.49-1.483 1.469-1.483h5.086c.993 0 1.49.494 1.49 1.483v3.453H51.46v-2.86h-3.172V34.11h3.172v-4.534h-1.81v-2.055h4.257v7.182zM60.202 36.186v-.36l-2.511.318c-.156.028-.291.05-.405.064a2.26 2.26 0 01-.319.02c-.865 0-1.298-.423-1.298-1.27v-3.772c0-.861.447-1.292 1.34-1.292h3.214V27.5h-2.277v1.483h-2.15v-1.95c0-.86.448-1.292 1.342-1.292h4.022c.88 0 1.32.431 1.32 1.293v9.152h-2.278zm.021-4.682h-2.277v2.882l2.277-.085v-2.797zM68.448 30.106v-2.394l-1.873.042v8.432H64.19V25.741h2.384v.382l2.17-.34a3.642 3.642 0 01.767-.084c.865 0 1.298.424 1.298 1.271v3.136h-2.362zM76.748 36.186v-.36l-1.809.318c-.312.07-.61.106-.894.106-.596 0-1.064-.17-1.404-.508-.34-.354-.511-.834-.511-1.441v-6.462c0-.636.191-1.144.575-1.525.383-.382.922-.573 1.617-.573h2.426V20h2.362v16.186h-2.362zm0-8.474H75.28c-.525 0-.788.261-.788.784v5c0 .508.248.762.745.762h.213c.085 0 .163-.007.234-.02l1.064-.085v-6.441zM87.628 34.894c0 .862-.447 1.292-1.341 1.292h-4.108c-.894 0-1.34-.43-1.34-1.292v-7.86c0-.862.447-1.293 1.34-1.293h4.108c.894 0 1.34.431 1.34 1.293v3.835l-.66.656h-3.851v2.924h2.234v-1.737h2.278v2.182zM85.35 27.48h-2.234v2.5h2.234v-2.5z"></path>
    <path
      fill="#231F20"
      d="M97.277 34.703c0 .48-.128.848-.383 1.102-.256.254-.618.381-1.086.381h-4.895c-.468 0-.83-.127-1.085-.38-.255-.255-.383-.622-.383-1.103v-3.856h2.426v3.306h2.98v-3.178l-4.874-4.598a1.805 1.805 0 01-.426-.55c-.07-.184-.106-.432-.106-.742v-3.602c0-.48.128-.847.383-1.102.255-.254.617-.381 1.085-.381h4.895c.468 0 .83.127 1.086.381.255.255.383.615.383 1.08v3.624H94.85v-3.051h-2.98v2.924l4.874 4.576c.198.198.333.388.404.572.085.183.128.43.128.741v3.856zM106.088 34.089c0 .636-.191 1.144-.574 1.525-.384.382-.923.572-1.618.572h-2.426V40h-2.384V25.741h2.384v.382l2.554-.34a3.64 3.64 0 01.766-.084c.865 0 1.298.424 1.298 1.271v7.119zm-2.383-6.377l-2.235.042v6.462h1.468c.284 0 .483-.063.596-.19.114-.128.171-.325.171-.594v-5.72zM112.264 36.186v-.36l-2.511.318c-.156.028-.291.05-.404.064-.1.014-.206.02-.32.02-.865 0-1.298-.423-1.298-1.27v-3.772c0-.861.447-1.292 1.341-1.292h3.214V27.5h-2.278v1.483h-2.149v-1.95c0-.86.447-1.292 1.341-1.292h4.022c.88 0 1.319.431 1.319 1.293v9.152h-2.277zm.022-4.682h-2.278v2.882l2.278-.085v-2.797zM120.51 30.106v-2.394l-1.873.042v8.432h-2.384V25.741h2.384v.382l2.171-.34a3.598 3.598 0 01.766-.084c.865 0 1.298.424 1.298 1.271v3.136h-2.362zM126.107 36.186c-.879 0-1.319-.43-1.319-1.292v-7.14h-1.128v-2.013h1.128v-2.838h2.362v2.838h1.533v2.013h-1.533v6.42h1.596v2.012h-2.639zM136.863 34.894c0 .862-.447 1.292-1.341 1.292h-4.107c-.894 0-1.341-.43-1.341-1.292v-7.86c0-.862.447-1.293 1.341-1.293h4.107c.894 0 1.341.431 1.341 1.293v3.835l-.659.656h-3.853v2.924h2.235v-1.737h2.277v2.182zm-2.277-7.415h-2.235v2.5h2.235v-2.5z"></path>
  </svg>
);
