import { forwardRef } from "react";

import { IBoxProps } from "./Box.types";
import * as Styled from "./Box.styles";

export const Box = forwardRef<HTMLDivElement, IBoxProps>(({ children, name, ...props }, ref) => (
  <Styled.Box ref={ref} name={name} {...props}>
    {children}
  </Styled.Box>
));
