import styled from "styled-components";
import { Typography } from "../../Typography";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 23px;
`;

export const Link = styled(Typography)`
  cursor: pointer;
`;
