import styled, { css } from "styled-components";
import { Box } from "../Box";
import { renderTextStyles } from "../Typography/Typography.styles";

export const InputWrapper = styled(Box)<{ disable?: boolean; isError?: boolean }>`
  color: ${({ theme }) => theme.palette.primaryNeutral};
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 17px 12px 10px;

  ${({ disable, theme }) =>
    disable &&
    `border-color: ${theme.palette.thirdNeutralLight};
     background: ${theme.palette.thirdBasicLight};
     color: ${theme.palette.secondaryNeutralDark};
  `}

  ${({ isError, theme }) =>
    isError &&
    `border-color: ${theme.palette.accentError};
  `}

  &:hover {
    ${({ disable, theme }) =>
      `border: ${!disable && `1px solid ${theme.palette.primaryMain}`};
    `}
  }

  &:active {
    ${({ disable, theme }) =>
      !disable &&
      `border-color: ${theme.palette.primaryMain};
     background: #FDF6FF;
  `}
  }
`;

export const TextWrapper = styled(Box)`
  text-overflow: ellipsis;
`;

export const StyledLabelContainer = styled(Box)`
  text-overflow: ellipsis;
`;

export const StyledDropdownContent = styled.div<{
  isShowScrollbar: boolean;
  boxHeight: number;
}>`
  border-right: 4px white solid;
  position: absolute;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 200px;
  background-color: ${({ theme }) => theme.palette.primaryBasic};
  ${({ isShowScrollbar }) =>
    isShowScrollbar &&
    css`
      overflow-y: scroll;
    `}
  z-index: 2;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  ::-webkit-scrollbar-thumb {
    color: ${({ theme }) => theme.palette.primaryDefault};
    ${({ theme }) => `background: ${theme.palette.primaryNeutral};`}
    border-radius: 34px;
  }
`;

export const Input = styled.input`
  border: 0;
  width: 100%;
  :focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.primaryNeutral};
  }
  ${({ theme }) => renderTextStyles(theme.textStyles.body16)}
`;
