import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { getErrorMessage } from "./getErrorMessage";

export type BackendErrorMessage = { status: boolean, message: string }

export const catchError = (cb: Dispatch<SetStateAction<BackendErrorMessage>>) => {
  return {
    onError: (error: unknown) => {
      const { response } = error as AxiosError;
      switch (response?.status) {
        case 400:
          const code = response.data as { ErrorCode: string };
          cb({
            status: true,
            message: getErrorMessage(code.ErrorCode)
          });
          break;
        default:
          cb({ status: true, message: "" });
      }
    }
  };
};
