import { css } from "styled-components";
import { IRadioStyleProps } from "./RadioField.types";
import okIcon from "../../assets/icons/ok.svg";

export const Button = css<IRadioStyleProps>`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  padding: 23px;
  border-radius: 4px;
  position: relative;

  & label {
    font-size: ${({ theme }) => theme.textStyles.body12Light};
    color: ${({ theme }) => theme.palette.primaryDefault};
  }

  & input[type="radio"] {
    position: absolute;
    appearance: none;
    background-color: transparent;
    padding-right: 23px;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &::before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.palette.secondaryNeutralDark};
      transition: 0.5s background-color;
    }

    &:checked::before {
      border: none;
      background: ${({ theme }) => theme.palette.primaryMain};
      background-image: url(${okIcon});
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  & input[type="radio"]:checked {
    border: 2px solid ${({ theme }) => theme.palette.primaryMain};
  }
`;
