import { useMutation } from "react-query";
import { updateSecurityOptions } from "../requests/updateSecurityOptions";

export const useUpdateSecurityOptions = () => {
  const { data, mutate, isSuccess, isLoading } = useMutation(updateSecurityOptions);
  return {
    securityOptions: data?.data,
    updateSecurityOptions: mutate,
    isSuccessUpdateSecurityOptions: isSuccess,
    isLoadingUpdateSecurityOptions: isLoading
  };
};
