import { forwardRef, useEffect, useRef, useState } from "react";
import { DropdownIcon } from "../../assets/icons/DropdownIcon";
import { useOnClickOutside } from "../../hooks";
import { Box } from "../Box";
import { Hint, Label } from "../Common";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { DropdownOption } from "./DropdownOption";
import { Input, InputWrapper, StyledDropdownContent, TextWrapper } from "./Select.styles";
import { ISelectProps } from "./Select.types";

export const Select = forwardRef<HTMLInputElement, ISelectProps>(
  (
    {
      label,
      options,
      placeholder,
      selectedOption,
      maxWidth,
      marginBottom,
      onChoice,
      disable,
      hint,
      isError,
      active,
      value,
      ...props
    },
    ref
  ) => {
    const [value1, setValue1] = useState<string>(selectedOption || "");
    const [isActive, setIsActive] = useState<boolean>(false);
    const [boxHeight, setBoxHeight] = useState<number>(0);

    const optionContainerRef = useRef<HTMLDivElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);

    const isShowScrollbar = options.length > 5 || false;

    useOnClickOutside(boxRef, () => setIsActive(false));

    const handleDropdownActiveClick = () => setIsActive(true);

    useEffect(() => {
      setBoxHeight(boxRef.current?.clientHeight || 0);
    });

    useEffect(() => {
      selectedOption && setValue1(selectedOption);
      setIsActive(active);
    }, [options, selectedOption, active]);

    return (
      <>
        <Box maxWidth={maxWidth} position="relative" mb={marginBottom} ref={boxRef}>
          {label && <Label>{label}</Label>}
          <InputWrapper
            onClick={disable ? undefined : handleDropdownActiveClick}
            disable={disable}
            isError={isError}
            ref={boxRef}>
            <TextWrapper width="90%">
              <Typography name={TYPOGRAPHY_NAMES.body16} color="primaryDefault">
                <Input ref={ref} value={value} placeholder={placeholder} {...props} />
              </Typography>
            </TextWrapper>
            <DropdownIcon />
          </InputWrapper>
          {isActive && (
            <StyledDropdownContent
              ref={optionContainerRef}
              boxHeight={boxHeight}
              isShowScrollbar={isShowScrollbar}>
              {options.map(option => (
                <DropdownOption option={option} key={option} clickHandler={onChoice}>
                  <TextWrapper width="90%">
                    <Typography name={TYPOGRAPHY_NAMES.body16}>{option}</Typography>
                  </TextWrapper>
                  {option === value1}
                </DropdownOption>
              ))}
            </StyledDropdownContent>
          )}
        </Box>
        {hint && !isActive && <Hint type={isError ? "error" : hint.type} text={hint.text} />}
      </>
    );
  }
);
