import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChatIcon, DropdownIcon } from "../../assets";
import { LANG_EN, LANG_FR, LANGUAGE } from "../../constants";
import { Box } from "../Box";
import { BUTTON_SIZE } from "../Button";
import { Typography, TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from "../Typography";
import { Line } from "../Line";
import { MainLogo } from "../MainLogo";
import { DropDawnLanguages, HeaderStyle, Language, StyledButton } from "./Header.styles";

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [openLanguages, setOpenLanguages] = useState(false);
  const [lang, setLang] = useState<string>(
    i18n.language === LANG_EN.toLowerCase() ? LANG_EN : LANG_FR
  );

  useEffect(() => {
    document.title = t("prod_name");
  }, [t]);

  const toggleLanguage = useCallback(() => setOpenLanguages(!openLanguages), [openLanguages]);

  const mailTo = useCallback(() => window.open("mailto:support@indominus.ms", "_blank"), [] )

  const setLanguage = useCallback(() => {
    const language = lang === LANG_EN ? LANG_FR : LANG_EN;
    setLang(language);
    i18n.changeLanguage(language.toLowerCase());
    localStorage.setItem(LANGUAGE, language);
    setOpenLanguages(false);
  }, [i18n, lang]);

  return (
    <>
      <HeaderStyle>
        <MainLogo />
        <Box display="flex" alignItems="center" gap={26}>
          <Language onClick={toggleLanguage}>
            <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.body16}>
              {lang.toUpperCase()}
            </Typography>
            <DropdownIcon stroke="black" />
          </Language>
          {openLanguages && (
            <DropDawnLanguages onClick={setLanguage}>
              <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.body16}>
                {lang === LANG_EN ? LANG_FR.toUpperCase() : LANG_EN.toUpperCase()}
              </Typography>
            </DropDawnLanguages>
          )}
          <StyledButton
            size={BUTTON_SIZE.fitContent}
            onClick={mailTo}
          >
            <Box display="flex" alignItems="center" gap={10}>
              {t("prod_btn_support_lbl")} {<ChatIcon />}
            </Box>
          </StyledButton>
        </Box>
      </HeaderStyle>
      <Line />
    </>
  );
};
