import { Box } from "../Box";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { useCallback, useEffect, useState } from "react";
import { ShowMore, UpdatesRow } from "./UpdatesRecord.style";
import { useTranslation } from "react-i18next";

interface IUpdateRecord {
  id: number;
  updated: string;
  description: string;
  current?: number;
  setCurrent: (id: number) => void;
}

export const UpdatesRecord = ({ id, updated, description, current, setCurrent }: IUpdateRecord) => {
  const { t } = useTranslation();
  const minLength = 100;
  const [length, setLength] = useState<number>(minLength);

  const onShowMore = useCallback(() => {
    setCurrent(id);
    setLength(description.length);
  }, [description, id]);

  const onShowLess = useCallback(() => {
    setLength(minLength);
  }, [minLength]);

  useEffect(() => {
    if (current !== id) onShowLess();
  }, [id, current]);

  return (
    <UpdatesRow height={ length === minLength ? "4em" : "12em" } scroll={ length !== minLength }>
      <Box>
        <Typography name={ TYPOGRAPHY_NAMES.body14Light }>{ updated }</Typography>
      </Box>
      <Box>
        <Typography name={ TYPOGRAPHY_NAMES.body14Light }>
          { description.slice(0, length) }
          { description.length > minLength + 15 && length === minLength && "..." }
        </Typography>
        {
          description.length > minLength + 15 && length === minLength &&
          <ShowMore onClick={ onShowMore }>{t("prod_pricing_Updates_more")}</ShowMore>
        }
        {
          description.length > minLength + 15 && length > minLength &&
          <ShowMore onClick={ onShowLess }>{t("prod_pricing_Updates_less")}</ShowMore>
        }
      </Box>
    </UpdatesRow>
  );
};
