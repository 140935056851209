import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PausedIcon } from "../../assets";
import { Box, Modal, Typography, TYPOGRAPHY_NAMES } from "../../components";

export const PausedPage = () => {
  const { t } = useTranslation();

  const handleReject = useCallback(() => window.location.replace(t("prod_url")), [t]);

  const dateBy = useMemo(() => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 2)
    return tomorrow.toISOString().slice(0, 10);
  }, []);

  return (
    <Modal width={ 480 } centered showCloseIcon onClose={ handleReject }>
      <PausedIcon />
      <Typography name={ TYPOGRAPHY_NAMES.modalHeader }>{ t("prod_delegate_paused_title") }</Typography>
      <Box pt={ 12 }>
        <Typography name={ TYPOGRAPHY_NAMES.body16 }>
          { t("prod_delegate_paused_text1") }{ " " }
          <Typography name={ TYPOGRAPHY_NAMES.body16Bold }>{ dateBy }</Typography>.{ " " }
          { t("prod_delegate_paused_text2") }
        </Typography>
      </Box>
    </Modal>
  );
};
