import { axiosInstance } from "../../axiosInstance";

type UpdateSecurityOptionsRequest = {
  PlanType: string;
  OptionVulnerabilities: boolean;
  OptionUpdates: boolean;
}

export const updateSecurityOptions = (request: UpdateSecurityOptionsRequest) => {
  return axiosInstance.post<string>("/UpdateSecurityOptions", request);
};
