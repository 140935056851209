import { ProgressBar, ProgressValue } from "./ProgressLine.styles";
import { IProgressLineProps } from "./ProgressLine.types";

export const ProgressLine = ({ width, height, value, color }: IProgressLineProps) => {
  return (
    <>
      <ProgressBar width={width ?? "100%"} height={height ?? 8}>
        <ProgressValue width={`${value}%`} color={color} />
      </ProgressBar>
    </>
  );
};
