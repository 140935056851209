import { Suspense, useMemo, useState } from "react";
import { PageWrapper } from "./components";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorPage } from "./pages";
import { BackendErrorMessage, catchError } from "./api/catchError";
import { CommonRoutes } from "./routes/CommonRoutes";
import { IPaymentContext, PaymentContext, paymentDefaultState } from "./context/PaymentContext";
import { IPermissions, PermissionsContext, permissionsDefault } from "./context/PermissionsContext";


function App() {
  const [error, setError] = useState<BackendErrorMessage>({ status: false, message: "" });
  const [payment, setPayment] = useState<IPaymentContext>(paymentDefaultState);
  const [permissions, setPermissions] = useState<IPermissions>(permissionsDefault);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            ...catchError(setError),
            refetchOnWindowFocus: false,
            retry: false,
          },
          mutations: {
            ...catchError(setError),
          }
        }
      }),
    []
  );

  const updatePayment = (value: Partial<IPaymentContext>) => {
    setPayment({ ...payment, ...value });
  };

  const updatePermissions = (value: Partial<IPermissions>) => {
    setPermissions({ ...permissions, ...value });
  };

  return (
    <div className="App">
      <QueryClientProvider client={ queryClient }>
        { error.status && <ErrorPage message={ error.message } /> }
        <PageWrapper>
          <Suspense>
            <PaymentContext.Provider value={ { ...payment, updateContext: updatePayment } }>
              <PermissionsContext.Provider value={ { ...permissions, updateContext: updatePermissions } }>
                <CommonRoutes />
              </PermissionsContext.Provider>
            </PaymentContext.Provider>
          </Suspense>
        </PageWrapper>
      </QueryClientProvider>
    </div>
  );
}

export default App;
