import { useState } from "react";

export interface IEntityRecord {
  state: boolean;
  value: number
}

export const useMapState = (defaults: Record<string, IEntityRecord>) => {
  const [entities, setEntity] = useState<Record<string, IEntityRecord>>(defaults);

  const updateEntities = (name: string) => (value: number) => {
    if (entities[name]) {
      setEntity({
        ...entities,
        [name]: {
          state: !entities[name].state,
          value: !entities[name].state ? value : 0,
        }
      })
    } else {
      setEntity({
        ...entities,
        [name]: {
          state: true,
          value,
        }
      })
    }
  }

  return {
    updateEntities,
    entities
  }
}
