import { useMutation } from "react-query";
import { getPricingDetails } from "../requests/getPricingDetails";

export const useGetPricingDetails = () => {
  const { data, isLoading, isSuccess, mutate } = useMutation(getPricingDetails);
  const price = data?.data;
  return {
    price,
    isLoadingPrice: isLoading,
    isSuccessPrice: isSuccess,
    updatePrice: mutate
  };
};
