import { ReactNode } from "react";
import { Box } from "../Box";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";

export const Label = ({ children }: { children: ReactNode }) => (
  <Box mb={5}>
    <Typography name={TYPOGRAPHY_NAMES.body14} color="secondaryNeutralDark">
      {children}
    </Typography>
  </Box>
);
