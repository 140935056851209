import { PriceCalculatorWrapper, PriceSubscription } from "./PriceCalculator.styles";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { Box } from "../Box";
import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import { PaymentContext } from "../../context/PaymentContext";

export const PriceCalculator = () => {
  const { t } = useTranslation();
  const { promo, subtotal, taxes, updateContext } = useContext(PaymentContext);

  const subtotalCalculation = useMemo(() => {
    return subtotal - promo;
  }, [subtotal, promo]);

  const taxCalculation = useMemo(() => {
    if (taxes) return (subtotalCalculation * taxes) / 100;
    return 0;
  }, [subtotalCalculation, taxes]);

  const totalCalculation = useMemo(() => {
    const total = subtotalCalculation + taxCalculation;
    updateContext({ total });
    return total;
  }, [subtotalCalculation, taxCalculation]);

  return (
    <PriceCalculatorWrapper>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography name={TYPOGRAPHY_NAMES.body16Bold}>{t("prod_subscription")}</Typography>
        <Typography name={TYPOGRAPHY_NAMES.body16Bold}>{subtotal.toFixed(2)}</Typography>
      </Box>
      <PriceSubscription>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography name={TYPOGRAPHY_NAMES.body16}>{t("prod_pricing_code_lbl")}</Typography>
          <Typography name={TYPOGRAPHY_NAMES.body16} color={"primaryMain"}>
            ${promo.toFixed(2)}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography name={TYPOGRAPHY_NAMES.body16}>{t("prod_pricing_Subtotal_lbl")}</Typography>
          <Typography name={TYPOGRAPHY_NAMES.body16}>
            ${subtotalCalculation.toFixed(2)}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography name={TYPOGRAPHY_NAMES.body16}>{t("prod_pricing_Taxes_lbl")}</Typography>
          <Typography name={TYPOGRAPHY_NAMES.body16}>${taxCalculation.toFixed(2)}</Typography>
        </Box>
      </PriceSubscription>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography name={TYPOGRAPHY_NAMES.H2}>{t("prod_pricing_Total_lbl")}</Typography>
        <Typography name={TYPOGRAPHY_NAMES.H2}>
          {subtotal && totalCalculation.toFixed(2)}
        </Typography>
      </Box>
    </PriceCalculatorWrapper>
  );
};
