import { axiosInstance } from "../../axiosInstance";

type GetPricingDetailsRequest = {
  PromoCode: string
}

type GetPricingDetailsResponse = {
  Taxes: number;
  NumberOfDevices: number;
  Variant: IPriceVariant[]
}

export interface IPriceOption {
  Name: string;
  Value: number;
  Mandatory: boolean;
  Paid: boolean;
}

export interface IPriceVariant {
  PricePeriod: PricePeriodName;
  PerDevicePrice: number;
  Discount: number;
  Options: IPriceOption[];
}
export enum PricePeriodName {
  "year" = "year",
  "month" = "month",
}

export const getPricingDetails = (request: GetPricingDetailsRequest) => {
  return axiosInstance.post<GetPricingDetailsResponse>("/GetPricingDetails", request);
};
