import {
  CheckMS,
  CheckOTP,
  EmailPage,
  FinishSetup,
  LanguageSelect,
  LicensePage,
  LinkToMS,
  PausedPage,
  RequiredMSAccount,
  ThankYouUser,
  UserLogin
} from "../pages";

export const beforeAuthRoutes = [
  { name: "email", path: "/email", element: <EmailPage /> },
  { name: "check_otp", path: "/check_otp", element: <CheckOTP /> },
  { name: "check_ms", path: "/check_ms", element: <CheckMS /> },
  { name: "check_ms_error", path: "/check_ms_error", element: <RequiredMSAccount /> },
  { name: "grant_ms", path: "/grant_ms", element: <LinkToMS /> },
  { name: "userLang", path: "/user/lang", element: <LanguageSelect path="user" /> },
  { name: "userLicense", path: "/user/license", element: <LicensePage path="user" /> },
  { name: "userLogin", path: "/user/login", element: <UserLogin /> },
  { name: "paused", path: "/paused", element: <PausedPage /> },
  { name: "finishSetup", path: "/user/finishSetup", element: <FinishSetup /> },
  { name: "thankYouUser", path: "/user/thankYouUser", element: <ThankYouUser /> },
]
