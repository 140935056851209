import styled from "styled-components";
import { Box } from "../Box";

export const ColorBlock = styled.div<{ color: string }>`
  width: 4px;
  min-height: 60px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ color }) => color};
`;

export const MainBlock = styled(Box)<{ color: string }>`
  min-height: 60px;
  background-color: ${({ color }) => color};
  border-radius: 0 4px 4px 0;
  & > * {
    margin-left: 12px;
  }
`;
