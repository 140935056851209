import styled from "styled-components";
import { Box } from "../../Box";
import { Typography } from "../../Typography";

export const StyledComplexOption = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDropdownOption = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  color: ${({ theme }) => theme.palette.primaryDefault};
  cursor: pointer;

  &:hover {
    background: #fdf6ff;
  }
`;
