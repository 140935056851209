import styled from "styled-components";

export const FooterStyle = styled.div`
  margin: auto;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;
