export interface ICounterCardProps {
  data: {
    stamp: string;
    value: number;
  };
}

export enum CounterStamps {
  days = "prod_completed_days_lbl",
  hours = "prod_completed_hours_lbl",
  minutes = "prod_completed_minutes_lbl",
  seconds = "prod_completed_seconds_lbl",
}
