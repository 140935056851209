import { AndroidIcon, AppleIcon, LinuxIcon, WindowsIcon } from "../../assets";
import { DevicePlatform } from "../../types/Platform";

export const PlatformLogo = ({ name, width }: { name: DevicePlatform, width: string }) => {
  switch (name) {
    case "Android":
      return <AndroidIcon width={ width } />;
    case "Windows":
      return <WindowsIcon width={ width } />;
    case "Linux":
      return <LinuxIcon width={ width } />;
    case "iOS":
    case "MacOS":
      return <AppleIcon width={ width } />;
    default:
      return null;
  }
};
