import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, BUTTON_SIZE, Modal, Typography, TYPOGRAPHY_COMPONENTS, TYPOGRAPHY_NAMES } from "../../components";
import { downloadFile } from "../../utils";
import { customizeFile } from "../../utils/customizeFile";
import { ActionsWrapper, WhiteBox } from "./FinishSetup.styles";

export const FinishSetup = () => {
  const [disabled, setDisabled] = useState(true);
  const [next, setNext] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleSubmit = useCallback(() => navigate("/user/thankYouUser"), []);
  const downloadExeFile = useCallback(async () => {
    const url = await customizeFile(state?.mail, state?.password);
    downloadFile(url, "install.ps1");
    setDisabled(true);
    setNext(false)
  }, []);

  useEffect(() => {
    if (state?.mail && state?.password) {
      setDisabled(false);
    }
  }, [state]);

  return (
    <Modal width={620} centered={false} showCompanyInfo>
      <Box>
        <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.modalHeader}>
          {t("prod_finish_setup_user_title")}
        </Typography>
        <Typography name={TYPOGRAPHY_NAMES.body16Light}>{t("prod_download_btn_expl")}</Typography>
      </Box>
      <Box mt={20} mb={32} width="100%">
        <Button size={BUTTON_SIZE.maxWidth} disabled={disabled} onClick={downloadExeFile}>
          {t("prod_download_btn")}
        </Button>
      </Box>
      <ActionsWrapper>
        <Typography name={TYPOGRAPHY_NAMES.body14Bold}>
          {t("prod_finish_setup_istructions")}
        </Typography>
        <Typography name={TYPOGRAPHY_NAMES.body12Light}>
          {t("prod_finish_setup_istructions_step1")}
        </Typography>
        <Typography name={TYPOGRAPHY_NAMES.body12Light}>
          {t("prod_finish_setup_istructions_step2")}
        </Typography>
        <Typography name={TYPOGRAPHY_NAMES.body12Light}>
          {t("prod_finish_setup_istructions_step3")}
        </Typography>
        <Typography name={TYPOGRAPHY_NAMES.body12Light}>
          {t("prod_finish_setup_istructions_step4")}
        </Typography>
        <WhiteBox>
          <Typography name={TYPOGRAPHY_NAMES.body12}>
            {t("prod_finish_setup_istructions_step5")}
          </Typography>
        </WhiteBox>
      </ActionsWrapper>
      <Box mt={20} width="100%">
        <Button size={BUTTON_SIZE.maxWidth} disabled={next} onClick={handleSubmit}>
          {t("prod_btn_next")}
        </Button>
      </Box>
    </Modal>
  );
};
