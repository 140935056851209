export interface IRoute {
  name: string;
  path: string;
  element: JSX.Element;
}

export interface IGuard {
  guard: boolean;
  children: JSX.Element;
  redirect: string;
}

export enum AccessLevels {
  "TokenNeeded" = 0,
  "MsPermissionsNeeded" = 1,
  "PaymentNeeded" = 6,
}
