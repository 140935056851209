import styled from "styled-components";
import { Box } from "../../components";

export const Wrapper = styled(Box)`
  padding-top: 116px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 114px;
`;
