import styled from "styled-components";

export const StyledWrapper = styled.div<{ isAbove: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ isAbove }) => (isAbove ? 106 : 105)};
`;

export const StyledOverlay = styled.div<{ isAbove: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({ theme }) => `background: ${theme.palette.gray};`}
  opacity: ${({ isAbove }) => (isAbove ? 0.8 : 1)}; ;
`;

export const StyledModalWrapper = styled.div<{
  needIndent: boolean;
  width: number;
  height?: number;
}>`
  padding: ${({ needIndent }) =>
    `${needIndent ? "40px" : "5px"} 40px ${needIndent ? "40px" : "23px"} 40px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => height && `${height}px`};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  min-width: 400px;
  min-height: 200px;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${({ theme }) => `background: ${theme.palette.primaryBasic};`}
`;

export const StyledHeader = styled.div`
  position: relative;
`;

export const StyledClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 26px;
  top: 26px;
`;

export const StyledContent = styled.div<{ centered?: boolean }>`
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  align-items: ${({ centered }) => (centered ? "center" : "flex-start")};
  flex-grow: 1;
`;
