import styled from "styled-components";
import { renderTextStyles } from "../Typography/Typography.styles";

export const Input = styled.input<{ isInvalid?: boolean }>`
  background: ${({ theme }) => theme.palette.primaryBasic};
  border: 1px solid
    ${({ isInvalid, theme }) =>
      isInvalid ? theme.palette.accentError : theme.palette.primaryNeutral};
  border-radius: 4px;
  padding: 10px 12px;
  color: ${({ theme }) => theme.palette.primaryDefault};
  ${({ theme }) => renderTextStyles(theme.textStyles.body16)}

  &::placeholder {
    color: ${({ theme }) => theme.palette.primaryNeutral};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primaryMain};
  }

  &:focus {
    background: ${({ theme }) => theme.palette.thirdMainLight};
    outline: none;
  }

  &:disabled {
    border-color: ${({ theme }) => theme.palette.thirdNeutralLight};
    background: ${({ theme }) => theme.palette.thirdBasicLight};
    color: ${({ theme }) => theme.palette.secondaryNeutralDark};
  }
`;
