import { useQuery } from "react-query";
import { getInstallationDate } from "../index";

export const useGetInstallationDate = () => {
  const { refetch, data, isLoading, isSuccess } = useQuery("getInstallationDate", getInstallationDate);
  return {
    installationDate: data?.data,
    refetchInstallationDate: refetch,
    isLoadingInstallationDate: isLoading,
    isSuccessInstallationDate: isSuccess
  };
};
