import { useTranslation } from "react-i18next";
import { AdditionalLogo } from "../AdditionalLogo";
import { Box } from "../Box";
import { Line } from "../Line";
import { Link } from "../Modal/Footer/Footer.styles";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { FooterStyle } from "./Footer.styles";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box mt={40}>
      <Line />
      <FooterStyle>
        <Box display="flex" alignItems="center" mr={40} gap={10}>
          <AdditionalLogo defaultSize={false} />
          <Typography name={TYPOGRAPHY_NAMES.body12} color="secondaryNeutralDark">
            {t("corp_rights_lbl")}
          </Typography>
        </Box>
        <Box display="flex" gap={20}>
          <Link name={TYPOGRAPHY_NAMES.body14} color="secondaryMainDark">
            {t("corp_privacy_lbl")}
          </Link>
          <Link name={TYPOGRAPHY_NAMES.body14} color="secondaryMainDark">
            {t("corp_terms_lbl")}
          </Link>
        </Box>
      </FooterStyle>
    </Box>
  );
};
