export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ml?: number;
  buttonType?: BUTTON_TYPE;
  role?: BUTTON_ROLE;
  size?: BUTTON_SIZE;
  children?: React.ReactNode | string;
  centered?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export enum BUTTON_TYPE {
  primary = "primary",
  secondary = "secondary",
  ghost = "ghost",
  fat = "fat",
  icon = "icon"
}

export enum BUTTON_ROLE {
  submit = "submit",
  reset = "reset",
  button = "button"
}

export enum BUTTON_SIZE {
  small = "small",
  medium = "medium",
  large = "large",
  fitContent = "fitContent",
  maxWidth = "maxWidth"
}
