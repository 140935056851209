import { axiosInstance } from "../../axiosInstance";

type CheckOtpRequest = { Email: string; OTP: number };

type CheckOtpResponse = {
  AccountToken: string;
  PageID: number;
};

export const checkOTP = (request: CheckOtpRequest) => {
  return axiosInstance.post<CheckOtpResponse>("/CheckOTP", request);
};
