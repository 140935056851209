import { IRoute } from "./routes.types";
import { ErrorPage, PaymentCompletePage, StartPage } from "../pages";
import { lazy } from "react";

const PaymentPage = lazy(() =>
  import("../pages/PaymentPage").then(({ PaymentPage }) => ({ default: PaymentPage }))
);

const DetailsPage = lazy(() =>
  import("../pages/DetailsPage").then(({ DetailsPage }) => ({ default: DetailsPage }))
);

const PricePage = lazy(() =>
  import("../pages/PricePage").then(({ PricePage }) => ({ default: PricePage }))
);

export const authRoutes: IRoute[] = [
  { name: "root", path: "/", element: <StartPage /> },
  { name: "details", path: "/details", element: <DetailsPage /> },
  { name: "price", path: "/price", element: <PricePage /> },
  { name: "payment", path: "/payment", element: <PaymentPage /> },
  { name: "payment_complete", path: "/payment/complete", element: <PaymentCompletePage /> },
  { name: "payment_error", path: "/payment/error", element: <ErrorPage /> },
];
