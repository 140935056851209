import { axiosInstance } from "../../axiosInstance";

type GetDevicesResponse = {
  CountAndroid: number;
  CountIOS: number;
  CountLinux: number;
  CountMacOS: number;
  CountWindows: number;
}

export const getDevices = () => {
  return axiosInstance.get<GetDevicesResponse>("/GetDevices");
};
