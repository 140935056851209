import { createContext } from "react";

export interface IPaymentContext {
  updates: boolean;
  vulnerabilities: boolean;
  discount: number;
  promo: number;
  subtotal: number;
  total?: number;
  taxes: number;
  period?: string;
}

export const paymentDefaultState: IPaymentContext = {
  updates: false,
  vulnerabilities: false,
  promo: 0,
  discount: 0,
  subtotal: 0,
  taxes: 0,
  period: "",
}

export const PaymentContext = createContext({
  ...paymentDefaultState,
  updateContext: (value: Partial<IPaymentContext>) => {},
})

PaymentContext.displayName = 'Global payment context'
