import { ReactNode } from "react";
import { DefaultTheme, ThemedStyledProps } from "styled-components";

export enum TYPOGRAPHY_NAMES {
  Accent = "Accent",
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
  body10 = "body10",
  body12 = "body12",
  body14 = "body14",
  body14Light = "body14Light",
  body14Bold = "body14Bold",
  body16 = "body16",
  body12Light = "body12Light",
  body16Light = "body16Light",
  body16Bold = "body16Bold",
  body20 = "body20",
  body24 = "body24",
  badge = "badge",
  modalHeader = "modalHeader"
}

export enum TYPOGRAPHY_COMPONENTS {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  p = "p",
  span = "span",
  div = "div"
}

export interface TypographyProps {
  name: TYPOGRAPHY_NAMES;
  component?: TYPOGRAPHY_COMPONENTS;
  isCapitalize?: boolean;
  isUnderline?: boolean;
  isCrossed?: boolean;
  color?: keyof DefaultTheme["palette"];
  children: ReactNode;
}

export type IStyledTextProps = ThemedStyledProps<
  {
    typographyName: TYPOGRAPHY_NAMES;
    isCapitalize: boolean;
    isUnderline: boolean;
    isCrossed?: boolean;
    color?: keyof DefaultTheme["palette"];
  },
  DefaultTheme
>;
