export const GladiatorIcon = () => (
  <svg
    width="403"
    height="140"
    viewBox="0 0 403 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_241_4778" maskUnits="userSpaceOnUse" x="0" y="0" width="403" height="140">
      <rect width="403" height="140" rx="8" fill="#F5F6FF" />
    </mask>
    <g mask="url(#mask0_241_4778)">
      <path
        d="M16.8301 99.7287C1.4703 114.689 1.4183 132.54 36.2666 156.15C75.8181 182.946 166.238 126.05 201.061 140.266C235.883 154.482 319.816 140.608 266.993 61.0844C225.552 -1.30408 251.061 -42.849 184.593 -51.0558C118.125 -59.2626 51.6393 2.43858 54.1123 45.4817C56.5852 88.5248 31.1015 85.8288 16.8301 99.7287Z"
        fill="#EAECFF"
      />
      <path
        d="M220.144 105.392C224.707 107.787 225.836 108.691 225.882 109.323C225.927 109.956 225.249 110.679 225.882 111.582C226.514 112.486 226.424 113.706 225.656 114.158C224.888 114.609 221.77 113.39 220.55 114.158C219.331 114.926 219.466 115.83 218.698 116.824C217.93 117.817 216.393 117.23 216.032 116.824C215.67 116.417 213.683 110.046 213.592 108.826C213.502 107.606 220.144 105.392 220.144 105.392Z"
        fill="#E3916F"
      />
      <path
        d="M196.532 84.0372C199.517 82.7786 200.974 86.3028 201.245 87.387C201.516 88.4713 215.037 100.919 216.845 101.642C218.652 102.365 219.646 101.687 221.137 102.862C222.628 104.037 222.176 106.16 220.685 106.928C219.194 107.696 216.077 107.064 214.766 108.329C213.456 109.594 214.088 111.492 213.863 111.853C213.637 112.215 209.57 108.51 206.543 106.431C203.516 104.353 195.292 102.048 193.395 101.326C191.497 100.603 191.576 100.332 191.808 99.3378C192.039 98.3438 196.532 84.0372 196.532 84.0372Z"
        fill="#9DB1C3"
      />
      <path
        d="M186.119 77.5598C190.005 81.5357 190.05 81.8972 192.896 82.7107C195.743 83.524 197.641 84.2018 198.815 86.1898C199.99 88.1778 199.945 92.6057 198.815 95.3166C197.686 98.0276 192.388 100.377 191.806 100.106C191.225 99.835 184.02 92.3227 183.499 91.4309C182.977 90.5389 186.119 77.5598 186.119 77.5598Z"
        fill="#E3916F"
      />
      <path
        d="M166.992 57.1389C182.053 58.8461 190.758 62.514 191.376 64.1405C191.995 65.7669 192.085 73.9903 190.73 75.888C189.375 77.7857 187.387 75.617 187.025 78.6892C186.664 81.7617 189.284 83.9304 187.567 87.545C185.85 91.1597 183.881 93.6899 179.444 93.4187C175.007 93.1474 163.656 81.6712 163.954 77.6952C164.253 73.7193 166.992 57.1389 166.992 57.1389Z"
        fill="#EEE3D9"
      />
      <path
        d="M154.415 42.7726C155.695 43.459 161.308 41.6454 163.423 40.5846C165.538 39.5238 168.714 34.5451 168.322 33.4689C167.931 32.3927 160.526 33.2123 158.75 34.4392C156.975 35.6659 152.364 40.7288 152.371 40.7822C152.378 40.8356 151.803 41.8076 152.527 41.9447C153.252 42.0815 154.415 42.7726 154.415 42.7726Z"
        fill="#1E1D19"
      />
      <path
        d="M166.265 56.703C174.793 62.0476 182.975 59.0652 183.958 57.7097C184.941 56.3542 185.492 39.3765 185.492 39.3765C185.492 39.3765 173.465 38.893 172.166 39.7265C170.867 40.5598 166.265 56.703 166.265 56.703Z"
        fill="#D68D1F"
      />
      <path
        d="M145.516 51.4268C154.702 46.8037 162.702 38.6006 162.702 38.6006L175.16 49.9083C175.16 49.9083 170.202 53.9626 168.929 56.0391C167.656 58.1156 166.592 64.7356 166.479 64.7508C166.367 64.7659 165.08 63.3158 160.338 62.6838C155.596 62.0515 152.73 59.4179 149.353 56.3442C145.976 53.2701 145.516 51.4268 145.516 51.4268Z"
        fill="#E3916F"
      />
      <path
        d="M181.814 34.1476C181.966 41.657 182.841 42.4771 182.124 43.091C181.406 43.705 179.128 43.7762 178.791 44.4802C178.454 45.1841 161.855 49.6616 160.028 47.569C158.201 45.4763 161.432 41.0833 161.914 40.4713C162.396 39.8593 160.96 38.7643 161.721 37.6033C162.482 36.4424 163.796 35.066 165.844 36.9781C167.892 38.89 170.428 29.9402 172.036 29.3004C173.643 28.6608 181.814 34.1476 181.814 34.1476Z"
        fill="#E3916F"
      />
      <path
        d="M159.221 48.7175C162.327 54.9931 164.656 57.5087 168.005 58.3424C168.345 57.465 168.454 56.8152 168.93 56.039C169.986 54.3167 173.575 51.2357 174.77 50.2342C167.836 47.8284 159.221 48.7175 159.221 48.7175Z"
        fill="#1E1D19"
      />
      <path
        d="M151.599 40.7057C151.875 38.9534 153.458 39.1385 153.559 36.3964C153.661 33.6542 151.671 28.6533 153.514 24.1715C155.358 19.6896 158.768 17.067 164.27 16.703C169.772 16.3391 176.765 20.7612 177.277 21.0688C177.788 21.3764 183.563 16.7418 184.21 16.655C184.857 16.5679 185.727 44.0177 185.562 44.8868C185.396 45.7559 182.418 43.8984 182.121 43.0915C181.825 42.2846 181.99 36.5232 181.342 35.1992C180.693 33.8752 175.41 31.6694 174.159 31.4613C172.908 31.2533 172.506 35.2589 172.5 35.9183C172.495 36.5776 178.469 39.7254 179.006 40.2176C179.543 40.7099 179.744 54.7957 179.611 56.6012C179.477 58.4067 163.352 53.2375 160.175 49.9016C156.997 46.5657 158.066 43.3172 160.412 41.8724C162.758 40.4277 165.536 34.503 165.536 34.503C165.536 34.503 155.866 38.156 153.662 39.9579C151.458 41.7597 154.417 42.773 154.417 42.773C154.417 42.773 151.345 42.3159 151.599 40.7057Z"
        fill="#FAC746"
      />
      <path
        d="M174.039 -8.79088C172.203 -8.72708 170.371 -8.55948 168.554 -8.28888C167.938 -3.48044 167.2 2.41076 166.985 4.70193C166.973 5.38125 166.954 5.75128 166.929 5.67274C166.91 5.61298 166.932 5.26197 166.985 4.70193C167.029 2.29368 167.003 -4.00244 166.98 -8.02862C162.115 -7.13538 157.625 -5.50915 153.794 -3.26482C155.686 1.98683 157.894 8.22221 157.657 8.09025C157.419 7.95707 154.159 2.61883 151.496 -1.78421C149.098 -0.0955372 147.027 1.86145 145.374 4.05529L150.111 7.94658L144.884 4.73412C144.466 5.32982 144.077 5.9453 143.717 6.57842C141.202 11.0351 140.078 14.7101 139.686 18.0091C143.61 19.567 147.706 21.1896 147.706 21.1896L139.55 19.5983C139.403 22.3885 139.736 24.9416 140.114 27.527C140.995 33.5474 141.053 36.2213 141.326 36.6077C141.6 36.9943 132.077 50.13 132.077 50.13C132.077 50.13 140.144 52.9258 140.306 52.5512C140.467 52.1765 154.996 31.6639 154.996 31.6639L178.969 13.91L191.379 -4.20855C191.379 -4.20855 187.454 -9.25506 174.039 -8.79088Z"
        fill="#95268D"
      />
      <path
        d="M186.246 -7.27026C186.24 -7.25709 176.58 14.2942 177.065 14.229C177.157 14.2166 177.561 14.3312 178.165 14.5052L178.969 13.9097L191.379 -4.2088C191.379 -4.20855 190.035 -5.9304 186.246 -7.27026Z"
        fill="#670360"
      />
      <path
        d="M155.175 35.8028C152.292 36.7551 148.848 37.0303 148.848 37.0303C148.848 37.0303 145.659 23.817 151.302 17.5067C156.945 11.1965 163.186 10.7332 167.734 10.9681C172.282 11.203 179.445 14.0969 179.445 14.0969L172.796 24.1177L155.175 35.8028Z"
        fill="#FAC746"
      />
      <path
        d="M153.286 36.7202C153.325 36.7203 153.363 36.7116 153.397 36.695C153.432 36.6783 153.463 36.654 153.487 36.6238C153.511 36.5937 153.528 36.5585 153.537 36.5209C153.545 36.4832 153.545 36.4441 153.537 36.4065C151.499 27.3784 152.966 21.769 158.153 18.7534C162.957 15.9617 170.667 16.6293 175.344 20.243C175.37 20.2636 175.401 20.2788 175.433 20.2877C175.466 20.2965 175.5 20.2989 175.533 20.2946C175.567 20.2903 175.599 20.2795 175.628 20.2627C175.658 20.246 175.683 20.2236 175.704 20.1969C175.725 20.1702 175.74 20.1397 175.749 20.1071C175.758 20.0746 175.76 20.0406 175.756 20.0071C175.751 19.9736 175.741 19.9413 175.724 19.912C175.707 19.8827 175.685 19.857 175.658 19.8364C170.831 16.1061 162.862 15.4209 157.896 18.3089C152.56 21.4111 150.97 27.3677 153.036 36.5197C153.049 36.5765 153.08 36.6273 153.126 36.6638C153.171 36.7002 153.228 36.7201 153.286 36.7202Z"
        fill="#D68D1F"
      />
      <path
        d="M150.818 37.0479C150.858 37.0479 150.897 37.0387 150.932 37.021C150.968 37.0033 150.999 36.9775 151.023 36.9458C151.047 36.914 151.063 36.8771 151.071 36.838C151.078 36.7988 151.076 36.7585 151.065 36.7203C147.875 25.5162 152.236 19.2484 155.12 16.5094C158.229 13.5557 166.591 10.1288 178.205 16.0218C178.235 16.037 178.267 16.0463 178.301 16.0489C178.335 16.0515 178.369 16.0475 178.401 16.037C178.433 16.0265 178.463 16.0098 178.488 15.9879C178.514 15.9659 178.535 15.9392 178.55 15.9091C178.566 15.879 178.575 15.8462 178.577 15.8125C178.58 15.7788 178.576 15.745 178.565 15.7129C178.555 15.6808 178.538 15.6511 178.516 15.6254C178.494 15.5998 178.468 15.5787 178.438 15.5634C166.568 9.54122 157.973 13.0901 154.766 16.1369C152.376 18.4063 147.184 24.9688 150.571 36.8613C150.586 36.915 150.618 36.9623 150.663 36.996C150.707 37.0297 150.762 37.0479 150.818 37.0479Z"
        fill="#D68D1F"
      />
      <path
        d="M177.45 37.4177C177.637 37.4179 177.822 37.3699 177.986 37.2783C178.149 37.1867 178.287 37.0545 178.385 36.8944L178.581 36.5734C178.726 36.3259 178.767 36.0318 178.697 35.7539C178.627 35.4761 178.451 35.2367 178.207 35.087C177.963 34.9373 177.67 34.8893 177.39 34.9531C177.111 35.0169 176.868 35.1875 176.713 35.4285L176.516 35.7492C176.415 35.9152 176.359 36.1054 176.355 36.3C176.351 36.4947 176.399 36.6868 176.494 36.8567C176.589 37.0266 176.728 37.1682 176.896 37.2668C177.064 37.3653 177.255 37.4174 177.45 37.4177Z"
        fill="#1E1D19"
      />
      <path
        d="M127.792 168.101C95.8754 168.101 70.0019 141.34 70.0019 108.329C70.0019 75.3178 95.8754 48.5569 127.792 48.5569C159.709 48.5569 185.582 75.3178 185.582 108.329C185.582 141.34 159.709 168.101 127.792 168.101Z"
        fill="#B6CCDB"
      />
      <path
        d="M127.794 163.436C157.19 163.436 181.105 138.715 181.105 108.329C181.105 77.9432 157.19 53.2222 127.794 53.2222C98.3985 53.2222 74.4831 77.9432 74.4831 108.329C74.4831 138.715 98.3987 163.436 127.794 163.436ZM127.794 55.1333C156.136 55.1333 179.194 78.9967 179.194 108.329C179.194 137.661 156.136 161.524 127.794 161.524C99.4522 161.524 76.3945 137.661 76.3945 108.329C76.3945 78.9967 99.4522 55.1333 127.794 55.1333Z"
        fill="#8098A9"
      />
      <path
        d="M126.731 155.579C101.501 155.579 81.0486 134.424 81.0486 108.329C81.0486 82.2338 101.501 61.0793 126.731 61.0793C151.961 61.0793 172.414 82.2338 172.414 108.329C172.414 134.424 151.961 155.579 126.731 155.579Z"
        fill="#9DB1C3"
      />
      <path
        d="M124.507 136.36C139.469 136.36 151.641 123.785 151.641 108.329C151.641 92.872 139.469 80.2974 124.507 80.2974C109.546 80.2974 97.3738 92.872 97.3738 108.329C97.3738 123.785 109.546 136.36 124.507 136.36ZM124.507 82.209C138.415 82.209 149.729 93.9263 149.729 108.329C149.729 122.731 138.415 134.449 124.507 134.449C110.6 134.449 99.2851 122.732 99.2851 108.329C99.2854 93.9263 110.6 82.209 124.507 82.209Z"
        fill="#8098A9"
      />
      <path
        d="M123.441 125.743C132.742 125.743 140.309 117.931 140.309 108.329C140.309 98.7271 132.742 90.9155 123.441 90.9155C114.14 90.9155 106.573 98.7271 106.573 108.329C106.573 117.931 114.14 125.743 123.441 125.743ZM123.441 92.8271C131.688 92.8271 138.397 99.7814 138.397 108.329C138.397 116.877 131.688 123.831 123.441 123.831C115.194 123.831 108.485 116.877 108.485 108.329C108.485 99.7814 115.194 92.8271 123.441 92.8271Z"
        fill="#8098A9"
      />
      <path
        d="M194.07 102.435C195.456 102.435 197.483 101.462 199.123 99.9486C201.062 98.1589 204.091 94.1322 202.587 87.2736C202.554 87.1397 202.469 87.0242 202.352 86.9516C202.234 86.8789 202.093 86.8548 201.958 86.8844C201.823 86.9139 201.705 86.9948 201.629 87.1099C201.553 87.225 201.524 87.3653 201.55 87.501C202.94 93.8422 200.175 97.5328 198.403 99.168C196.522 100.904 194.447 101.55 193.729 101.331C193.662 101.31 193.591 101.302 193.521 101.307C193.451 101.313 193.382 101.333 193.32 101.366C193.257 101.398 193.202 101.443 193.157 101.497C193.112 101.552 193.078 101.614 193.057 101.682C193.037 101.749 193.03 101.82 193.037 101.89C193.044 101.96 193.065 102.028 193.099 102.09C193.132 102.152 193.178 102.207 193.233 102.251C193.288 102.295 193.351 102.328 193.419 102.347C193.614 102.406 193.833 102.435 194.07 102.435Z"
        fill="#8098A9"
      />
      <path
        d="M225.909 113.955C230.081 116.507 228.635 117.456 226.918 117.139C225.201 116.823 223.62 115.811 222.31 117.198C220.999 118.585 221.553 119.173 219.943 120.077C218.334 120.98 217.792 117.863 218.108 116.507C218.424 115.152 219.373 111.266 221.858 111.582C224.343 111.898 225.909 113.955 225.909 113.955Z"
        fill="#9DB1C3"
      />
      <path
        d="M282 168.919C276.036 165.395 268.174 160.515 265.87 158.888C263.566 157.262 221.094 117.37 221.094 117.37C221.094 117.37 221.184 114.203 222.901 115.016C224.618 115.83 226.123 116.957 226.302 116.999C226.481 117.041 269.078 153.489 271.518 155.579C273.958 157.669 282 168.919 282 168.919Z"
        fill="#9DB1C3"
      />
    </g>
  </svg>
);
