import styled from "styled-components";

interface UpdatesRowProps {
  height: string;
  scroll: boolean;
}
export const UpdatesRow = styled.div<UpdatesRowProps>`
  width: 390px;
  height: ${(props) => props.height};
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  background: ${({ theme }) => theme.palette.primaryBasic};
  border-top: none;
  transition: height .5s;
  
  div:nth-child(odd) {
    width: 80px;
    text-align: center;
    flex: none;
  }
  
  div:nth-child(even) {
    width: 260px;
    flex: none;
    transition: max-height .5s ease-in-out;
    max-height: ${(props) => props.height};
    overflow-x: hidden;
    overflow-y: ${({ scroll }) => scroll ? "auto" : "hidden"};

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      margin: 4px 0 4px;
    }

    ::-webkit-scrollbar-thumb {
      color: ${({ theme }) => theme.palette.primaryDefault};
      ${({ theme }) => `background: ${theme.palette.primaryNeutral};`}
      border-radius: 34px;
    }
  }
`;
export const ShowMore = styled.span`
  font-size: 14px;
  color: ${({theme}) => theme.palette.primaryMain};
  padding-left: 1ch;
  &:hover {
    color: ${({theme}) => theme.palette.secondaryMainDark};
    cursor: pointer;
  }
`
