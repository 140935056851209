import { Spinner, Wrapper } from "./Loader.styles";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import { useTranslation } from "react-i18next";

interface ILoaderProps {
  text?: string;
}

export const Loader = ({ text }: ILoaderProps) => {
  const {t} = useTranslation()

  return(
    <Wrapper>
      <Spinner />
      <Typography name={ TYPOGRAPHY_NAMES.H1 } color="primaryBasic">
        { text ? text : t("prod_please_wait_text")}
      </Typography>
    </Wrapper>
  )
};
