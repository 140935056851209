import React, { useContext, useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, Button, BUTTON_ROLE, Loader } from "../../components";
import { useTranslation } from "react-i18next";
import { useProcessPayment } from "../../api";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { PAYMENT_STATUS } from "../../constants";
import { PermissionsContext } from "../../context/PermissionsContext";
import { AccessLevels } from "../../routes/routes.types";

export function CheckoutForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { updateContext } = useContext(PermissionsContext);
  const [isRedirected, setRedirected] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const { startPayment, isSuccessProcessPayment, isLoadingProcessPayment, paymentResult } =
    useProcessPayment();

  useEffect(() => {
    if (!isSuccessProcessPayment || !paymentResult || !paymentResult.data) return;

    const { PaymentStatus, Redirect_Url } = paymentResult.data;

    switch (PaymentStatus) {
      case PAYMENT_STATUS.REQUIRES_ACTION:
        console.log("Open window", isSuccessProcessPayment, paymentResult);
        if (!isRedirected) {
          window.open(Redirect_Url);
          setRedirected(true);
        }

        navigate("/payment/complete");

        break;
      case PAYMENT_STATUS.SUCCEEDED:
        updateContext({ pageId: AccessLevels.PaymentNeeded });
        navigate("/home");
        break;
      case PAYMENT_STATUS.CANCELED:
        navigate("error");
        break;
      default:
        navigate("error");
    }
  }, [isSuccessProcessPayment, navigate, paymentResult, isRedirected]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement("card");
    if (!card) {
      return;
    }

    setIsLoading(true);

    const paymentMethod = await stripe.createPaymentMethod({
      type: "card",
      card
    });

    if (!paymentMethod.paymentMethod) {
      setIsLoading(false);
      return;
    }

    startPayment({ PmId: paymentMethod.paymentMethod?.id });

    setIsLoading(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        "color": theme.palette.secondaryNeutralDark,
        "fontFamily": "SofiaPro Regular",
        "fontSmoothing": "antialiased",
        "fontSize": "16px",
        "::placeholder": {
          color: theme.palette.primaryNeutral
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  if (isLoadingProcessPayment) return <Loader />;

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="payment-element" options={CARD_ELEMENT_OPTIONS} />
      <Box mt={40}>
        <Button disabled={isLoading || !stripe || !elements} id="submit" role={BUTTON_ROLE.submit}>
          <span id="button-text">{isLoading ? <Loader /> : t("prod_pricing_payment_lbl")}</span>
        </Button>
      </Box>
    </form>
  );
}
