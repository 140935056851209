import { useMutation } from "react-query";
import { checkOTP } from "../requests/checkOTP";

export const useCheckOTP = () => {
  const { mutate, isLoading, isSuccess } = useMutation(checkOTP);
  return {
    checkOTP: mutate,
    isLoadingCheckOTP: isLoading,
    isSuccessCheckOTP: isSuccess
  };
};

