import styled from "styled-components";
import { Box } from "../../components";

export const LicenseBox = styled(Box)`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  border-radius: 4px;
  ol {
    padding-left: 2rem;
  }
`;
