import styled from "styled-components";
import { Box } from "../Box";

export const CounterBox = styled(Box)`
  width: 100px;
  height: 71px;
  padding: 26px 0 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${ ({ theme }) => theme.palette.primaryNeutral };
  border-radius: 4px;
`;
