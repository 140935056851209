import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useTheme } from "styled-components";
import { Box, PriceCalculator, Typography, TYPOGRAPHY_NAMES } from "../../components";
import { PaymentBox } from "./PaymentPage.styles";
import { CheckoutForm } from "./CheckoutForm";
import { LANG_FR, LANGUAGE } from "../../constants";
import { AccordionSection } from "../../components/AccordionSection";
import { AmericanExpress, Mastercard, Visa } from "../../assets";
// import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
// import { useGetPaypalInfo } from "../../api";
// import { AccessLevels } from "../../routes/routes.types";
// import { PermissionsContext } from "../../context/PermissionsContext";

const stripeKey = process.env.REACT_APP_STRIPE_KEY || "";
// const paypalClientId = process.env.REACT_APP_PAYPAL_KEY || "";

enum paymentSystem {
  PAYPAL = "paypal",
  STRIPE = "stripe"
}

const savedLanguage = () => {
  const lang = localStorage.getItem(LANGUAGE);
  switch (lang) {
    case LANG_FR:
      return "fr" as const;
    default:
      return "en" as const;
  }
};

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(stripeKey, { locale: savedLanguage() });

export const PaymentPage = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [extended, setExtended] = useState<string>(paymentSystem.STRIPE);
  // const [planId, setPlanId] = useState<string>("");

  // const { paypalInfo, isSuccessPaypalInfo } = useGetPaypalInfo();

  const theme = useTheme();
  // const { updateContext } = useContext(PermissionsContext);

  // useEffect(() => {
  //   if (paypalInfo?.data?.PlanId) setPlanId(paypalInfo.data.PlanId);
  // }, [isSuccessPaypalInfo, paypalInfo]);

  const handleClick = useCallback(
    (payment: paymentSystem) => {
      return () => {
        if (payment === extended) {
          setExtended("");
        } else {
          setExtended(payment);
        }
      };
    },
    [extended]
  );

  const options = useMemo(() => {
    const appearance = {
      theme: "stripe" as const,
      fonts: [
        {
          family: "SofiaPro Regular",
          src: "url(/fonts/SofiaPro-Regular.woff2)",
          weight: "400"
        }
      ],
      variables: {
        colorPrimary: theme.palette.primaryMain,
        colorText: theme.palette.secondaryNeutralDark
      }
    };

    return {
      // clientSecret,
      appearance
    };
  }, [theme]);

  // const paypalOptions = useMemo(
  //   () => ({
  //     "client-id": paypalClientId,
  //     "vault": true,
  //     "intent": "subscription"
  //   }),
  //   []
  // );

  return (
    <>
      <Box>
        <Box pt={60} pb={30}>
          <Typography name={TYPOGRAPHY_NAMES.H1}>{t("prod_pricing_details_lbl")}</Typography>
        </Box>
        <Box display="flex" pb={80} alignItems={"flex-start"}>
          <PaymentBox maxWidth={700} mr={40}>
            {/* <PayPalScriptProvider options={paypalOptions}> */}
            <Elements options={options} stripe={stripePromise}>
              {/* <AccordionSection
                  key={paymentSystem.PAYPAL}
                  label={t("prod_pricing_PayPal_lbl")}
                  logo={<Paypal />}
                  active={extended === paymentSystem.PAYPAL}
                  onClick={handleClick(paymentSystem.PAYPAL)}>
                  {planId ? (
                    <PayPalButtons
                      createSubscription={(data, actions) => {
                        return actions.subscription.create({
                          plan_id: planId
                        });
                      }}
                      onApprove={async (data, actions) => {
                        //data.subscriptionID
                        updateContext({ pageId: AccessLevels.PaymentNeeded });
                        navigate("/home");
                      }}
                      onError={err => {
                        navigate("error");
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                </AccordionSection> */}
              <AccordionSection
                key={paymentSystem.STRIPE}
                label={t("prod_pricing_Card_lbl")}
                logo={[<Visa />, <Mastercard />, <AmericanExpress />]}
                active={extended === paymentSystem.STRIPE}
                onClick={handleClick(paymentSystem.STRIPE)}>
                <CheckoutForm />
              </AccordionSection>
              <Outlet />
            </Elements>
            {/* </PayPalScriptProvider> */}
          </PaymentBox>
          <PriceCalculator />
        </Box>
      </Box>
    </>
  );
};
