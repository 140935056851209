import { axiosInstance } from "../../axiosInstance";

type ConfirmStripePaymentResponse = {
  PaymentStatus: string;
  Redirect_Url?: string;
};

export const confirmStripePayment = () => {
  return axiosInstance.get<ConfirmStripePaymentResponse>("/ConfirmStripePayment");
};
