import styled from "styled-components";
import { Box } from "../Box";
import { InstallationCardStylesProps } from "./InstallationCard.types";

export const Card = styled(Box)<InstallationCardStylesProps>`
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: ${({ failure, theme: { palette } }) =>
    failure ? palette.accentError : palette.primaryNeutral};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 22px;
  padding: 32px;
`;

export const Icon = styled(Box)`
  background: ${({ theme }) => theme.palette.thirdNeutralLight};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
`;
export const FailureMessage = styled(Box)<InstallationCardStylesProps>`
  gap: 1ch;
  align-items: center;
  margin-top: 26px;
  display: ${({ failure }) => (failure ? "flex" : "none")};
`;
export const Counter = styled(Box)`
  width: max-content;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
