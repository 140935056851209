import * as Style from "./RadioField.styles";
import { IRadioFieldProps } from "./RadioField.types";
import { AntivirusIcon } from "../../assets/icons/AntivirusIcon";

export const RadioField = ({
  type,
  name,
  label,
  checked,
  disabled,
  width,
  onChange,
  ref,
  id
}: IRadioFieldProps) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (!onChange) {
      return null;
    }

    onChange(event);
  };

  return (
    <Style.Group display={"inline-block"} gap={6} type={type} width={width}>
      {type === "antivirus" && AntivirusIcon({ name: label, height: 50 })}
      {label && <label>{label}</label>}
      <input
        name={name}
        type={"radio"}
        disabled={disabled}
        checked={checked}
        ref={ref}
        id={id}
        onChange={handleChange}
      />
    </Style.Group>
  );
};
