import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Box, Typography, TYPOGRAPHY_NAMES } from "..";
import { getPercentagesColor, getProgressBarStyles, Wrapper } from "./ProgressCard.styles";
import { CARD } from "./ProgressCard.types";
import { useTranslation } from "react-i18next";

interface ICardProps {
  type?: CARD;
  isSecurity?: boolean;
  title: string;
  text: string;
  percentage: number;
  globalCounter?: string;
}

export const ProgressCard = ({
  type,
  isSecurity,
  title,
  text,
  percentage,
  globalCounter
}: ICardProps) => {
  const { t } = useTranslation();

  const textColor = type === CARD.average ? "black" : "primaryBasic";

  return (
    <Box>
      <Wrapper type={type}>
        {isSecurity ? (
          <Box display="flex" flexDirection="column" gap={14}>
            <Box mt={15}>
              <Typography name={TYPOGRAPHY_NAMES.body12Light} color={textColor}>
                {title}
              </Typography>
              <br />
              <Typography name={TYPOGRAPHY_NAMES.H4} color={textColor}>
                {text}
              </Typography>
            </Box>
            <Box>
              <Typography name={TYPOGRAPHY_NAMES.body12} color={textColor}>
                {globalCounter}
                {t("prod_corp_sec_score_devices_lbl")}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <Typography name={TYPOGRAPHY_NAMES.H2}>{title}</Typography>
            <Typography name={TYPOGRAPHY_NAMES.body12Light}>{text}</Typography>
          </Box>
        )}
        <Box mt={10}>
          <CircularProgressbarWithChildren
            styles={getProgressBarStyles(isSecurity) as any}
            value={percentage}>
            <Typography name={TYPOGRAPHY_NAMES.body16} color={getPercentagesColor(type)}>
              {percentage}%
            </Typography>
          </CircularProgressbarWithChildren>
        </Box>
      </Wrapper>
    </Box>
  );
};
