import { useState, useEffect } from "react";
import { Typography, TYPOGRAPHY_NAMES, TYPOGRAPHY_COMPONENTS } from "../Typography";
import { Box } from "../Box";
import { useTranslation } from "react-i18next";
import { RESEND_CODE_TIMER, ONE_MINUTE_IN_SEC } from "../../constants";
import styled from "styled-components";
import { Button, BUTTON_SIZE, BUTTON_TYPE } from "../Button";

type ResendTimerTypes = {
  attemptsLeft: number;
  handleResend: () => void;
};

const ResendTime = styled(Typography)`
  color: ${({ theme }) => theme.palette.primaryMain};
`;

export function ResendTimer({ handleResend }: ResendTimerTypes) {
  const { t } = useTranslation();
  const [timeLeft, setTimeleft] = useState(RESEND_CODE_TIMER);

  const minutes = Math.floor(timeLeft / ONE_MINUTE_IN_SEC);
  const seconds = Number(timeLeft) % ONE_MINUTE_IN_SEC;

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeleft(timeleft => (timeleft >= 1 ? timeleft - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const onResendClick = () => {
    setTimeleft(RESEND_CODE_TIMER);
    handleResend();
  };

  return (
    <>
      {timeLeft === 0 ? (
        <Button
          buttonType={BUTTON_TYPE.secondary}
          size={BUTTON_SIZE.maxWidth}
          onClick={onResendClick}>
          {t("prod_btn_res_code")}
        </Button>
      ) : (
        <Box>
          <Typography name={TYPOGRAPHY_NAMES.body12Light} component={TYPOGRAPHY_COMPONENTS.p}>
            {`${t("prod_otp_conf_code_rule")} `}
            <ResendTime name={TYPOGRAPHY_NAMES.body12}>{`${minutes}:${
              seconds >= 10 ? seconds : `0${seconds}`
            }`}</ResendTime>
          </Typography>
        </Box>
      )}
    </>
  );
}
