import { Route, Routes } from "react-router-dom";
import { clientRoutes } from "./clientRoutes";
import { RouteGuard } from "./RouteGuard";
import { authRoutes } from "./authRoutes";
import { beforeAuthRoutes } from "./beforeAuthRoutes";
import { LanguageSelect, LicensePage, Page404 } from "../pages";
import { AccessLevels } from "./routes.types";
import { useContext } from "react";
import { PermissionsContext } from "../context/PermissionsContext";


export const CommonRoutes = () => {
  const { language, pageId } = useContext(PermissionsContext);

  return (
    <Routes>

      <Route path="/lang" element={ <LanguageSelect path="default" /> } />

      <Route path="/license" element={ <RouteGuard
        guard={ Boolean(language) }
        children={ <LicensePage path="defalult" /> }
        redirect="/lang"
      /> } />

      { beforeAuthRoutes.map(({ name, path, element }) => (
        <Route
          key={ name }
          path={ path }
          element={ <RouteGuard
            guard={ Boolean(language) }
            children={ element }
            redirect="/license"
          /> }
        />
      )) }

      { authRoutes.map(({ name, path, element }) => (
        <Route
          key={ name }
          path={ path }
          element={ <RouteGuard
            guard={ pageId >= AccessLevels.TokenNeeded }
            children={ element }
            redirect="/license"
          /> }
        />
      )) }

      { clientRoutes.map(({ name, path, element }) => (
        <Route
          key={ name }
          path={ path }
          element={ <RouteGuard
            guard={ pageId >= AccessLevels.PaymentNeeded }
            children={ element }
            redirect="/"
          /> }
        />
      )) }

      <Route path="*" element={ <Page404 /> } />

    </Routes>
  );
};
