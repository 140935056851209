import styled from "styled-components";
import { Button } from "../Button";

export const Language = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

export const DropDawnLanguages = styled.div`
  position: absolute;
  top: 65px;
  width: 40px;
  height: 20px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.primaryBasic};

  :hover {
    background: ${({ theme }) => theme.palette.thirdNeutralLight};
  }
`;

export const StyledButton = styled(Button)`
  background: linear-gradient(180deg, #be30b4 0%, #85217e 100%);
`;

export const HeaderStyle = styled.div`
  margin: auto;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0 26px;
`;
