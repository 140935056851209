import { IRoute } from "./routes.types";
import { lazy } from "react";

const HomePage = lazy(() =>
  import("../pages/HomePage").then(({ HomePage }) => ({ default: HomePage }))
);

const InstallationPage = lazy(() =>
  import("../pages/InstallationPage").then(({ InstallationPage }) => ({ default: InstallationPage }))
);

const ThankYouPage = lazy(() =>
  import("../pages/ThankYouPage").then(({ ThankYouPage }) => ({ default: ThankYouPage }))
);

export const clientRoutes: IRoute[] = [
  { name: "countdown", path: "/countdown", element: <ThankYouPage /> },
  { name: "home", path: "/home", element: <HomePage /> },
  { name: "installation", path: "/installation", element: <InstallationPage /> },
];
