import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  BUTTON_SIZE,
  Loader,
  Modal,
  ResendTimer,
  TextInput,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import { useCheckOTP, useSendOTP } from "../../api";

const maskEmail = (email: string | undefined) => {
  if (!email) return "";
  const emailParts = /([^@\s]+)(@[^@\s]+\.[^@\s]+)/i.exec(email);
  return emailParts ? `${ emailParts[1].slice(0, 3) }***${ emailParts[2] }` : "";
};

export const CheckOTP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState<number>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const { checkOTP, isSuccessCheckOTP, isLoadingCheckOTP } = useCheckOTP();
  const { sendOTP } = useSendOTP();
  const location = useLocation();

  const handleAccept = useCallback(
    () => {
      if (otp) checkOTP({
        Email: location.state?.email,
        OTP: otp
      });
    }, [otp, checkOTP]
  );

  useEffect(() => {
    if (isSuccessCheckOTP) navigate("/check_ms");
  }, [isSuccessCheckOTP]);

  const handleReject = useCallback(() => navigate("/"), []);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setOtp(Number(target.value.trim()));
    const valid = /\d{6}/.test(target.value);
    setDisabled(!valid);
  };

  const handleResend = useCallback(
    () => {
      sendOTP({
        Email: location.state?.email,
        Language: localStorage.getItem("Language") ?? "En"
      });
    }, [sendOTP]
  );

  const hiddenEmail = maskEmail(location.state?.email);

  return (
    <Modal width={ 620 } height={ 650 } centered showCompanyInfo showCloseIcon onClose={ handleReject }>
      { isLoadingCheckOTP && <Loader /> }
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        textAlign="left">
        <Typography name={ TYPOGRAPHY_NAMES.modalHeader }>{ t("prod_otp_conf_title") }</Typography>
        <Typography name={ TYPOGRAPHY_NAMES.body16Light }>
          { t("prod_otp_conf_description") } { hiddenEmail }
        </Typography>
        <Typography name={ TYPOGRAPHY_NAMES.body16Light }>
          { t("prod_otp_conf_description2") }
        </Typography>

        <Box pt={ 40 } width="100%">
          <TextInput
            label={ t("prod_otp_conf_code_lbl") }
            placeholder={ t("prod_otp_conf_code_hint") }
            type="text"
            width="100%"
            value={ otp }
            onChange={ handleChange }
          />
        </Box>

        <Box pt={ 40 } width="100%">
          <Button size={ BUTTON_SIZE.maxWidth } onClick={ handleAccept } disabled={ disabled }>
            { t("prod_btn_next") }
          </Button>
          <Box mt={ 20 } width="100%">
            <ResendTimer attemptsLeft={ 0 } handleResend={ handleResend } />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
