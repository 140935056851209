import { IPriceRecord } from "./PriceCard.types";
import { useTranslation } from "react-i18next";
import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import { CHECKBOX_TYPES } from "../Checkbox/Checkbox.types";
import { Typography, TYPOGRAPHY_NAMES } from "../Typography";
import React from "react";

export const PriceCardRecord = ({name, period, entities, cb, value, label, disabled}: IPriceRecord) => {
  const {t} = useTranslation()

  return (
    <Box display={ "flex" } justifyContent={ "space-between" }>
      <Checkbox
        checkboxType={ CHECKBOX_TYPES.light }
        id={ `${ period }-${ name }` }
        name={ name }
        checked={ entities[name] ? entities[name].state : false }
        onChange={ () => cb(name, value) }
        label={ t(label) }
        disabled={ disabled }
      />
      <Typography name={ TYPOGRAPHY_NAMES.body16Light }>
        ${ value.toFixed(2) }
      </Typography>
    </Box>
  );
};
