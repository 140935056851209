import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, BUTTON_SIZE, Modal, Typography, TYPOGRAPHY_NAMES } from "../../components";
import { LicenseBox } from "./LicensePage.styles";
import ReactMarkdown from "react-markdown";

interface ILicensePageProps {
  path: "defalult" | "user";
}

export const LicensePage = ({ path }: ILicensePageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleAccept = useCallback(() => {
    if (path === "defalult") {
      navigate("/email");
    } else if (state?.mail) {
      navigate("/user/login", { state: { mail: state.mail } });
    } else {
      navigate("/error");
    }
  }, [navigate, path, state]);
  const handleReject = useCallback(() => window.location.replace(t("prod_url")), [t]);

  const [license, setlicense] = useState<string>("");

  useEffect(() => {
    fetch(t("prod_license_client_text"))
      .then(response => response.text())
      .then(text => setlicense(text));
  });

  return (
    <Modal width={620} centered showCompanyInfo>
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
        <Typography name={TYPOGRAPHY_NAMES.modalHeader}>{t("prod_license_title")}</Typography>

        <LicenseBox
          pt={20}
          width="100%"
          textAlign="left"
          height={"334px"}
          flexWrap="wrap"
          display="flex"
          overflow="auto">
          <ReactMarkdown children={license} />
        </LicenseBox>

        <Box pt={20} width="100%" display="flex" justifyContent="space-between">
          <Button size={BUTTON_SIZE.small} onClick={handleReject}>
            {t("prod_btn_reject")}
          </Button>
          <Button size={BUTTON_SIZE.small} onClick={handleAccept}>
            {t("prod_btn_accept")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
