import styled from "styled-components";
import { Box } from "../Box/Box.styles";
import { IRadioStyleProps, RadioTypes } from "./RadioField.types";
import { Radio } from "./RadioField.radio.styles";
import { Av } from "./RadioField.antivirus.styles";
import { Button } from "./RadioField.button.styles";

const RadioStyle = (type: RadioTypes) => {
  switch (type) {
    case "radio":
      return Radio;
    case "antivirus":
      return Av;
    case "button":
      return Button;
  }
};
const Group = styled(Box)<IRadioStyleProps>`
  ${({ type }) => RadioStyle(type)}
`;

export { Group };
