import styled from "styled-components";

import { IBoxProps } from "./Box.types";

const isNumber = (value: any) => typeof value === "number" && Number.isFinite(value);

export const Box = styled.div<IBoxProps>`
  margin-top: ${({ mt }) => mt && `${mt}px`};
  margin-right: ${({ mr }) => mr && `${mr}px`};
  margin-bottom: ${({ mb }) => mb && `${mb}px`};
  margin-left: ${({ ml }) => ml && `${ml}px`};
  margin: ${({ margin }) => margin?.map(item => `${item}px `)};

  padding-top: ${({ pt }) => pt && `${pt}px`};
  padding-right: ${({ pr }) => pr && `${pr}px`};
  padding-bottom: ${({ pb }) => pb && `${pb}px`};
  padding-left: ${({ pl }) => pl && `${pl}px`};
  padding: ${({ padding }) => padding?.map(item => `${item}px `)};

  width: ${({ width }) => (isNumber(width) ? `${width}px` : width)};
  max-width: ${({ maxWidth }) => (isNumber(maxWidth) ? `${maxWidth}px` : maxWidth)};
  min-width: ${({ minWidth }) => (isNumber(minWidth) ? `${minWidth}px` : minWidth)};
  height: ${({ height }) => (isNumber(height) ? `${height}px` : height)};
  text-align: ${({ textAlign }) => textAlign};
  position: ${({ position }) => position};

  display: ${({ display }) => display};
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : "")}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : "")}
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : "")}
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: ${flexWrap};` : "")}
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : "")}
`;
