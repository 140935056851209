import { axiosInstance } from "../../axiosInstance";

type GetInstallationProgressResponse = {
  Overall: number;
  WindowsOS: number;
  WindowsOSSuccess: number;
  WindowsOSFailures: number;
  MacOS: number;
  MacOSSuccess: number;
  MacOSFailures: number;
  IOS: number;
  IOSSuccess: number;
  IOSFailures: number;
  AOS: number;
  AOSSuccess: number;
  AOSFailures: number;
  LinuxOS: number;
  LinuxOSSuccess: number;
  LinuxOSFailures: number;
}

export const getInstallationProgress = () => {
  return axiosInstance.get<GetInstallationProgressResponse>("/GetInstallationProgress");
};
