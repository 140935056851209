import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  BUTTON_TYPE,
  Checkbox,
  Line,
  Loader,
  Notification,
  NOTIFICATION_TYPE,
  RadioField,
  TextInput,
  Typography,
  TYPOGRAPHY_COMPONENTS,
  TYPOGRAPHY_NAMES
} from "../../components";
import { CHECKBOX_TYPES } from "../../components/Checkbox/Checkbox.types";
import { InputsWrapper, Page, PageSection } from "./StartPage.styles";
import { useAddSecurityOptions, useGetDevices } from "../../api";
import { PaymentContext } from "../../context/PaymentContext";
import { PermissionsContext } from "../../context/PermissionsContext";
import { useControlFields } from "../../hooks";
import { AccessLevels } from "../../routes/routes.types";

export const StartPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { devices, refetchDevices } = useGetDevices();
  const { addSecurityOptions, isLoadingAddSecurityOptions, isSuccessAddSecurityOptions } =
    useAddSecurityOptions();
  const [otherAntivirus, setOtherAntivirus] = useState<boolean>(false);
  const { updateContext } = useContext(PaymentContext);
  const { permissionsGranted, pageId } = useContext(PermissionsContext);
  const { fields, setInput, setCheckbox, setRadio } = useControlFields({
    employees: 0,
    protectAllDevices: true,
    vulnerabilities: true,
    updates: true
  });

  useEffect(() => {
    if (pageId >= AccessLevels.PaymentNeeded) navigate("/home");
  }, []);

  useEffect(() => {
    if (permissionsGranted) refetchDevices();
  }, [permissionsGranted]);

  const avList = [
    "McAfee",
    "Norton",
    "TOTALAV",
    "AVG",
    "Eset",
    "PCPROTECT",
    "prod_setup_q1_choice_other",
    "prod_setup_q1_choice_none"
  ];

  const onAvSelect = useCallback((event: React.ChangeEvent) => {
    const { id } = event.currentTarget;
    switch (id) {
      case "prod_setup_q1_choice_other":
        setRadio("antivirus")("");
        setOtherAntivirus(true);
        break;
      case "prod_setup_q1_choice_none":
        setRadio("antivirus")("none");
        setOtherAntivirus(false);
        break;
      default:
        setRadio("antivirus")(id);
        setOtherAntivirus(false);
    }
  }, []);

  const onShowPrice = useCallback(() => {
    addSecurityOptions({
      ...devices,
      ExistingAV: fields.antivirus,
      CountEmployees: fields.employees,
      ProtectAllDevices: fields.protectAllDevices,
      OptionVulnerabilities: fields.vulnerabilities,
      OptionUpdates: fields.updates
    });
    updateContext({
      updates: fields.updates,
      vulnerabilities: fields.vulnerabilities
    });
  }, [devices, fields, isSuccessAddSecurityOptions, updateContext, addSecurityOptions]);

  const onSave = useCallback(() => navigate("/paused"), []);

  useEffect(() => {
    if (isSuccessAddSecurityOptions) {
      navigate("/price");
    }
  }, [isSuccessAddSecurityOptions]);

  if (isLoadingAddSecurityOptions) return <Loader />;

  return (
    <Page>
      <Outlet />
      <PageSection>
        <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.H1}>
          {t("prod_setup_title_1")}
        </Typography>
        <Typography component={TYPOGRAPHY_COMPONENTS.p} name={TYPOGRAPHY_NAMES.H1}>
          {t("prod_setup_title_2")}
        </Typography>
        <Box mt={40} mb={25}>
          <Badge text={1}>{t("prod_setup_q1_text")}</Badge>
        </Box>
        <Box display="flex" width={890} flexWrap="wrap" gap={20}>
          {avList.map(av => (
            <RadioField
              key={av}
              name="antivirus"
              label={t(av)}
              type="antivirus"
              onChange={onAvSelect}
              id={av}
            />
          ))}
        </Box>

        <Box mt={32} mb={32}>
          {otherAntivirus && (
            <TextInput
              label={t("prod_setup_q1_warning")}
              name={"otherCompany"}
              type={"text"}
              minLength={2}
              onChange={value => setInput("antivirus")(value)}
              defaultValue={fields.antivirus}
            />
          )}
        </Box>
        {fields.antivirus && fields.antivirus  !== "none" && (
          <Notification type={NOTIFICATION_TYPE.warning} maxWidth={904}>
            {t("prod_setup_q1_warning")}
          </Notification>
        )}
      </PageSection>
      <Line />
      <PageSection>
        <Badge text={2}>{t("prod_setup_q2_text")}</Badge>
        <Box mt={32}>
          <TextInput
            type={"number"}
            min={0}
            label={t("prod_setup_q2_field1_lbl")}
            defaultValue={fields.employees}
            onChange={value => setInput("employees")(value)}
          />
        </Box>
        <Box mt={32} mb={32}>
          <Typography name={TYPOGRAPHY_NAMES.body16}>{t("prod_setup_q2_section1_text")}</Typography>
          <InputsWrapper>
            <TextInput disabled label="Windows" value={devices?.CountWindows} />
            <TextInput disabled label="MacOS" value={devices?.CountMacOS} />
            <TextInput disabled label="Linux" value={devices?.CountLinux} />
          </InputsWrapper>
        </Box>
        <Box mb={32}>
          <Typography name={TYPOGRAPHY_NAMES.body16}>{t("prod_setup_q2_section2_text")}</Typography>
          <InputsWrapper>
            <TextInput disabled label="Android" value={devices?.CountAndroid} />
            <TextInput disabled label="IOS" value={devices?.CountIOS} />
          </InputsWrapper>
        </Box>
        <Checkbox
          id="1"
          name="protectDevices"
          checked={fields.protectAllDevices}
          onChange={() => null}
          checkboxType={CHECKBOX_TYPES.emphasised}
          disabled={true}
          label={t("prod_setup_q2_option")}
        />
        {/*<Box mt={ 20 }>*/}
        {/*  <Notification type={ NOTIFICATION_TYPE.warning } maxWidth={ 904 }>*/}
        {/*    { t("prod_setup_q2_warning") }*/}
        {/*  </Notification>*/}
        {/*</Box>*/}
        {/*<Box mt={ 20 }>*/}
        {/*  <Notification type={ NOTIFICATION_TYPE.error } maxWidth={ 904 }>*/}
        {/*    { t("prod_setup_q2_error") }*/}
        {/*  </Notification>*/}
        {/*</Box>*/}
      </PageSection>
      <Line />
      <PageSection>
        <Badge text={3}>{t("prod_setup_q3_title")}</Badge>
        <Box display="flex" flexDirection="column" gap={22} mt={32}>
          <Checkbox
            id="updates"
            name="updates"
            checked={fields.updates}
            onChange={() => setCheckbox("updates")(!fields.updates)}
            label={t("prod_setup_q3_option1")}
          />
          <Checkbox
            id="security"
            name="security"
            checked={fields.vulnerabilities}
            onChange={() => setCheckbox("vulnerabilities")(!fields.vulnerabilities)}
            label={t("prod_setup_q3_option2")}
          />
        </Box>
      </PageSection>
      <Line />
      <Box pt={32} pb={32} pl={120}>
        <Box display="flex" gap={32}>
          <Button onClick={onShowPrice} disabled={!fields.employees}>
            {t("prod_btn_price")}
          </Button>
          <Button onClick={onSave} buttonType={BUTTON_TYPE.secondary}>
            {t("prod_btn_save_exit")}
          </Button>
        </Box>
      </Box>
    </Page>
  );
};
