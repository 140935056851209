const errorCodes: Record<string, string> = {
  "1000": "error_1000",
  "1001": "error_1001",
  "1002": "error_1002",
  "1003": "error_1003",
  "1004": "error_1004",
  "1005": "error_1005",
  "1010": "error_1010",
  "1011": "error_1011",
  "1012": "error_1012",
  "1013": "error_1013",
  "1014": "error_1014",
  "1015": "error_1015",
  "1016": "error_1016",
  "1017": "error_1017",
  "1018": "error_1018",
  "1019": "error_1019",
  "1020": "error_1020",
  "1021": "error_1021",
  "1022": "error_1022",
  "1023": "error_1023",
  "1030": "error_1030",
  "1031": "error_1031",
  "1032": "error_1032",
  "1033": "error_1033",
  "1034": "error_1034",
  "1035": "error_1035",
  "1036": "error_1036",
  "1037": "error_1037",
  "1040": "error_1040",
  "1041": "error_1041",
  "1042": "error_1042",
  "1043": "error_1043",
  "1044": "error_1044",
  "1045": "error_1045",
  "1046": "error_1046",
  "1047": "error_1047",
  "1050": "error_1050",
  "1051": "error_1051",
  "1052": "error_1052",
  "1053": "error_1053",
  "1054": "error_1054",
  "1055": "error_1055",
  "1056": "error_1056",
  "1060": "error_1060",
  "1061": "error_1061",
  "1062": "error_1062",
  "1063": "error_1063",
  "1064": "error_1064",
  "1065": "error_1065",
  "1066": "error_1066",
  "1070": "error_1070",
  "1071": "error_1071",
  "1072": "error_1072",
  "1073": "error_1073",
  "1074": "error_1074",
  "1075": "error_1075",
  "1076": "error_1076",
  "1077": "error_1077",
  "1078": "error_1078",
  "1079": "error_1079",
  "1080": "error_1080",
  "1081": "error_1081",
  "1082": "error_1082",
  "1083": "error_1083",
  "1084": "error_1084",
  "1085": "error_1085",
  "1086": "error_1086",
  "1087": "error_1087",
  "1088": "error_1088",
  "1090": "error_1090",
  "1100": "error_1100",
  "1101": "error_1101",
  "1110": "error_1110",
  "1111": "error_1111"
};

export const getErrorMessage = (code: string) => {
  if (code in errorCodes) return errorCodes[code];
  return "";
};
