import styled from "styled-components";
import lastSoldier from "../../assets/images/lastSoldier.svg";

export const Page = styled.div`
  min-height: calc(100vh - 240px);
  background-image: url(${lastSoldier});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Code404 = styled.span`
  font-size: 20vh;
  color: ${({theme}) => theme.palette.primaryMain};
`

export const Text404 = styled.span`
  font-size: 5vh;
  color: ${({theme}) => theme.palette.primaryDefault};
`
