import { ReactNode } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Page } from "./PageWrapper.style";

interface IPageWrapperProps {
  children: ReactNode;
}

export const PageWrapper = ({ children }: IPageWrapperProps) => {
  return (
    <>
      <Header />
      <Page>
        {children}
      </Page>
      <Footer />
    </>
  );
};
