import { StyledDropdownOption } from "./DropdownOption.styles";
import { OptionProps } from "./DropdownOption.types";

export function DropdownOption({ children, option, clickHandler }: OptionProps) {
  const clickOptionHandler = () => clickHandler?.(option);

  return (
    <StyledDropdownOption
      onClick={clickOptionHandler}
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      {children}
    </StyledDropdownOption>
  );
}
