import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  BUTTON_ROLE,
  BUTTON_SIZE,
  BUTTON_TYPE,
  FormController,
  FormFieldError,
  Line,
  Loader,
  PriceCalculator,
  Select,
  Typography,
  TYPOGRAPHY_NAMES
} from "../../components";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { useGetCountries } from "../../hooks";
import { DetailsBox, HorizontalBox, Page, Section } from "./DetailsPage.styles";
import { VALIDATE_EMAIL } from "../../constants";
import { DetailsInputs } from "./DetailsPage.types";
import { useAddClientDetails, useGetStatus } from "../../api";
import { useNavigate } from "react-router-dom";
import { AccessLevels } from "../../routes/routes.types";
import { PermissionsContext } from "../../context/PermissionsContext";

export const DetailsPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
    clearErrors
  } = useForm<DetailsInputs>({ mode: "all" });
  const [country, setCountry] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [provinces, setProvinces] = useState<string[]>([]);
  const { getCountries, getStates } = useGetCountries(i18n.language);
  const { addClientDetails, isLoadingAddClientDetails, isSuccessAddClientDetails } =
    useAddClientDetails();
  const { email } = useGetStatus();
  const { pageId } = useContext(PermissionsContext);

  useEffect(() => {
    if (pageId >= AccessLevels.PaymentNeeded) navigate("/home");
  }, []);

  const handleCountry = useCallback(
    (option: string) => {
      setCountry(option);
      setValue("Country", option);
      setProvince("");
      setProvinces(getStates(option));
      clearErrors("Country");
    },
    [setCountry, setValue, getStates, clearErrors]
  );

  const handleState = useCallback(
    (option: string) => {
      setProvince(option);
      setValue("State", option);
      clearErrors("State");
    },
    [setProvince, setValue, clearErrors]
  );

  const onSubmit: SubmitHandler<DetailsInputs> = useCallback(
    data => {
      addClientDetails(data);
    },
    [addClientDetails]
  );

  useEffect(() => {
    if (isSuccessAddClientDetails) navigate("/payment");
  }, [isSuccessAddClientDetails]);

  const onCancel = useCallback(() => navigate(-1), []);

  if (isLoadingAddClientDetails) return <Loader />;

  return (
    <Page>
      <Box pt={60}>
        <Typography name={TYPOGRAPHY_NAMES.H1}>{t("prod_custdetails_title")}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          pb={80}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={32}>
          <DetailsBox maxWidth={700}>
            <Section mt={40}>
              <Typography name={TYPOGRAPHY_NAMES.H2}>
                {t("prod_custdetails_information_section1")}
              </Typography>
            </Section>
            <FormController
              control={control}
              name="CompanyName"
              rules={{ required: true, minLength: 2, maxLength: 160 }}
              label="prod_custdetails_corpname_lbl"
              placeholder="prod_custdetails_corpname_hint"
            />
            <FormController
              control={control}
              name="CompanyWebAddress"
              rules={{ required: true, minLength: 2, maxLength: 160 }}
              label="prod_custdetails_url_lbl"
              placeholder="prod_custdetails_url_hint"
            />

            <Box mt={32}>
              <Typography name={TYPOGRAPHY_NAMES.body16Bold}>
                {t("prod_custdetails_sub1_title")}
              </Typography>
            </Box>
            <HorizontalBox mt={20} display="flex" width="100%">
              <Controller
                name="Country"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Box display={"flex"} flexDirection={"column"}>
                    <Select
                      marginBottom={8}
                      label={t("prod_custdetails_country_lbl") + "*"}
                      placeholder={t("prod_custdetails_country_hint")}
                      options={getCountries}
                      active={false}
                      {...field}
                      onChoice={handleCountry}
                      value={country}
                    />
                    <FormFieldError
                      fieldState={fieldState}
                      customError={{ display: !country, text: t("prod_custdetails_country_lbl") }}
                    />
                  </Box>
                )}
              />
              <Controller
                name="State"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Box display={"flex"} flexDirection={"column"}>
                    <Select
                      marginBottom={8}
                      label={t("prod_custdetails_province_lbl") + "*"}
                      placeholder={t("prod_custdetails_province_hint")}
                      options={provinces}
                      active={false}
                      {...field}
                      onChoice={handleState}
                      value={province}
                    />
                    <FormFieldError
                      fieldState={fieldState}
                      customError={{ display: !province, text: t("prod_custdetails_province_lbl") }}
                    />
                  </Box>
                )}
              />
            </HorizontalBox>

            <FormController
              control={control}
              name="Address"
              rules={{ required: true, minLength: 3, maxLength: 160 }}
              label="prod_custdetails_address_lbl"
              placeholder="prod_custdetails_address_hint"
            />
            <FormController
              control={control}
              name="Address2"
              rules={{ required: false, maxLength: 160 }}
              label="prod_custdetails_address 2_lbl"
            />

            <HorizontalBox mt={20} display="flex" width="100%">
              <FormController
                control={control}
                name="Zip"
                type="text"
                rules={{ required: true, maxLength: 160 }}
                label="prod_custdetails_postal_lbl"
                placeholder="prod_custdetails_postal_hint"
              />
              <FormController
                control={control}
                name="CompanyPhoneNumber"
                rules={{ required: true, minLength: 4, maxLength: 160}}
                label="prod_custdetails_phone_lbl"
                placeholder="prod_custdetails_phone_hint"
              />
            </HorizontalBox>

            <Section mt={40}>
              <Typography name={TYPOGRAPHY_NAMES.H2}>
                {t("prod_custdetails_information_section2")}
              </Typography>
            </Section>

            <HorizontalBox mt={20} display="flex" width="100%">
              <FormController
                control={control}
                name="FirstName"
                rules={{ required: true, minLength: 2, maxLength: 160 }}
                label="prod_custdetails_firstname_lbl"
                placeholder="prod_custdetails_firstname_hint"
              />
              <FormController
                control={control}
                name="LastName"
                rules={{ required: true, minLength: 2, maxLength: 160 }}
                label="prod_custdetails_lastname_lbl"
                placeholder="prod_custdetails_lastname_hint"
              />
            </HorizontalBox>

            <FormController
              control={control}
              name="Role"
              rules={{ required: true, minLength: 2, maxLength: 160 }}
              label="prod_custdetails_role_lbl"
              placeholder="prod_custdetails_role_hint"
            />

            <HorizontalBox mt={20} display="flex" width="100%">
              <FormController
                control={control}
                name="AccountEmail"
                defaultValue={email}
                rules={{ required: true, pattern: VALIDATE_EMAIL, maxLength: 160 }}
                label="prod_custdetails_email_lbl"
                placeholder={t("prod_email_title")}
              />
              <FormController
                control={control}
                name="AccountPhoneNumber"
                rules={{ required: true, maxLength: 160 }}
                label="prod_custdetails_number_lbl"
                placeholder="prod_custdetails_number_hint"
              />
            </HorizontalBox>
          </DetailsBox>
          <PriceCalculator />
        </Box>

        <Line />
        <Box mt={32} mb={38}>
          <Button
            type={"submit"}
            disabled={!isValid}
            role={BUTTON_ROLE.submit}
            size={BUTTON_SIZE.small}>
            Proceed to checkout
          </Button>
          <Button
            ml={32}
            buttonType={BUTTON_TYPE.secondary}
            size={BUTTON_SIZE.small}
            onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </form>
    </Page>
  );
};
