import { Typography, TYPOGRAPHY_NAMES } from "..";
import { StyledButton } from "./Button.styles";
import { BUTTON_ROLE, BUTTON_SIZE, BUTTON_TYPE, IButtonProps } from "./Button.types";

export const Button = ({
  role = BUTTON_ROLE.button,
  buttonType = BUTTON_TYPE.primary,
  size = BUTTON_SIZE.medium,
  centered = true,
  children,
  leftIcon,
  rightIcon,
  ml,
  ...props
}: IButtonProps) => {
  return (
    <StyledButton
      type={role}
      buttonType={buttonType}
      size={size}
      centered={centered}
      ml={ml}
      {...props}>
      {leftIcon}
      {buttonType !== BUTTON_TYPE.icon && (
        <Typography name={TYPOGRAPHY_NAMES.body16Bold}>{children}</Typography>
      )}
      {rightIcon}
    </StyledButton>
  );
};
