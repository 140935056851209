import { axiosInstance } from "../../axiosInstance";

type ProcessPaymentRequest = {
  PmId: string;
};

type ProcessPaymentResponse = {
  data?: {
    PaymentStatus: string;
    Redirect_Url: string;
  };
};

export const processPayment = (request: ProcessPaymentRequest) => {
  return axiosInstance.post<ProcessPaymentRequest, ProcessPaymentResponse>(
    "/ProcessPayment",
    request
  );
};
