import { useMutation } from "react-query";
import { sendOTP } from "../requests/sendOTP";

export const useSendOTP = () => {
  const { mutate, isSuccess, isLoading } = useMutation(sendOTP);
  return {
    sendOTP: mutate,
    isSuccessSendOTP: isSuccess,
    isLoadingSendOTP: isLoading
  };
};
