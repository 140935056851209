import { useQuery } from "react-query";
import { checkPrerequisites } from "../requests/checkPrerequisites";
import { useContext } from "react";
import { PermissionsContext } from "../../../context/PermissionsContext";
import { AccessLevels } from "../../../routes/routes.types";

export const useCheckPrerequisites = () => {
  const { updateContext } = useContext(PermissionsContext);
  const { data, isLoading, isSuccess, refetch } = useQuery("checkPrerequisites", checkPrerequisites, {
    retry: true,
    onSuccess: () => updateContext({ pageId: AccessLevels.TokenNeeded }),
    onError: (error) => error
  });
  return {
    permissionsStatus: data?.data.AppPermissionsStatus,
    isLoadingPrerequisites: isLoading,
    isSuccessPrerequisites: isSuccess,
    refetchPrerequisites: refetch
  };
};
