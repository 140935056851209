import { useSnapCarousel } from "react-snap-carousel";
import { ICarouselProps } from "./Carousel.types";
import { Wrapper } from "./Carousel.styles";
import { LeftIcon, RightIcon } from "../../assets/icons";
import { Box } from "../Box";

export const Carousel = <T extends any>({ items, renderItem }: ICarouselProps<T>) => {
  const { scrollRef, pages, activePageIndex, prev, next, snapPointIndexes } = useSnapCarousel();

  return (
    <Box display="flex" alignItems="center" gap={15}>
      <Box onClick={prev}>
        <LeftIcon disabled={activePageIndex <= 0} />
      </Box>
      <Wrapper ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i)
          })
        )}
      </Wrapper>
      <Box onClick={next}>
        <RightIcon disabled={activePageIndex === pages.length - 1} />
      </Box>
    </Box>
  );
};
