import styled from "styled-components";
import { CARD } from "./ProgressCard.types";

const getBgc = (type?: CARD) => {
  switch (type) {
    case CARD.poor:
      return "linear-gradient(302.01deg, #E52D27 19.3%, #C21333 73.05%);";
    case CARD.average:
      return "linear-gradient(322.92deg, #ffd759 1.23%, #ff8f6f 85.98%);";
    case CARD.excellent:
      return "linear-gradient(124.91deg, #0BA360 13.62%, #3CBA92 97.21%);";
    default:
      return "#FFFFFF";
  }
};

export const getPercentagesColor = (type?: CARD) => {
  switch (type) {
    case CARD.excellent:
    case CARD.poor:
      return "primaryBasic";
    case CARD.average:
      return "primaryDefault";
    default:
      return "primaryMain";
  }
};

export const getProgressBarStyles = (isSecurity = false) => {
  return {
    root: {
      marginTop: "-12px",
      width: "80px",
      overflow: "visible"
    },
    trail: {
      strokeWidth: isSecurity ? 1 : 5,
      stroke: isSecurity ? "#FFFFFF" : "#D9D9D9"
    },
    path: {
      strokeWidth: 14,
      stroke: isSecurity ? "#FFFFFF" : "#85217E",
      strokeLinecap: "butt"
    }
  };
};

export const Wrapper = styled.div<{ type?: CARD }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 23px 34px 20px;
  box-sizing: border-box;
  width: 274px;
  height: 140px;
  border: 1px solid ${({ theme }) => theme.palette.primaryNeutral};
  border-radius: 8px;
  text-align: left;
  background: ${({ type }) => getBgc(type)};
`;
