import { CloseIcon } from "../../assets/icons";
import { MainLogo } from "../MainLogo";
import { Footer } from "./Footer";

import {
  StyledWrapper,
  StyledOverlay,
  StyledModalWrapper,
  StyledClose,
  StyledContent
} from "./Modal.styles";
import { IModalProps } from "./Modal.types";

export const Modal = ({
  children,
  onClose,
  showCompanyInfo = false,
  showCloseIcon = false,
  width,
  height,
  centered
}: IModalProps) => {
  return (
    <StyledWrapper isAbove={!showCompanyInfo}>
      <StyledOverlay onClick={onClose} isAbove={!showCompanyInfo} />
      <StyledModalWrapper needIndent={!showCompanyInfo} width={width} height={height}>
        {showCompanyInfo && <MainLogo />}
        {showCloseIcon && (
          <StyledClose onClick={onClose}>
            <CloseIcon />
          </StyledClose>
        )}
        <StyledContent centered={centered}>{children}</StyledContent>
        {showCompanyInfo && <Footer />}
      </StyledModalWrapper>
    </StyledWrapper>
  );
};
