import { useCallback, useMemo } from "react";
import countriesEn from "../../assets/countries/en/countries.json";
import countriesFr from "../../assets/countries/fr/countries.json";
import subdivisions from "../../assets/countries/fr/subdivisions.json";
import { LANG_FR } from "../../constants";

const subdivisionsByCountryId = (id?: string) => {
  return id
    ? subdivisions.filter(value => value.country.toLowerCase() === id).map(value => value.name)
    : [];
};

export const useGetCountries = (language: string) => {
  const getCountries = useMemo(() => {
    switch (language) {
      case LANG_FR.toLowerCase():
        return countriesFr.map(value => value.name);
      default:
        return countriesEn.map(value => value.name);
    }
  }, [language]);

  const getStates = useCallback(
    (country: string) => {
      switch (language) {
        case LANG_FR.toLowerCase():
          return subdivisionsByCountryId(countriesFr.find(value => value.name === country)?.alpha2);
        default:
          return subdivisionsByCountryId(countriesEn.find(value => value.name === country)?.alpha2);
      }
    },
    [language]
  );

  return { getCountries, getStates };
};
