import { useTheme } from "styled-components";
import { EventColor, EventType } from "../../types/EventColor";

interface IExclamatoryIconProps {
  type: EventType;
}

export const ExclamatoryIcon = ({ type }: IExclamatoryIconProps) => {
  const theme = useTheme();
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_23_734)">
          <path
            d="M7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6819 1.33337 7.99998 1.33337C4.31808 1.33337 1.33331 4.31814 1.33331 8.00004C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667Z"
            fill={theme.palette[EventColor[type]]}
            stroke={theme.palette[EventColor[type]]}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 5.33337V8.00004"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 10.6666H8.00667"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_23_734">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
