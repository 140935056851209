export interface ICheckboxProps {
  id: string;
  name: string;
  label?: string | null;
  checkboxType?: CHECKBOX_TYPES;
  disabled?: boolean;
  checked: boolean;
  onChange: () => void;
}

export enum CHECKBOX_TYPES {
  normal = "Normal",
  light = "Light",
  emphasised = "Emphasised"
}
